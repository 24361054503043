import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import {
	Box,
	Card,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Container,
	CardContent,
	CircularProgress,
	IconButton,
	Tooltip,
	Button,
	SvgIcon,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector, useDispatch } from 'react-redux';
import { deleteDocument, createDocument } from '../redux/actions/admin/docs';

const TextFieldContact = styled(TextField)({
	'&.MuiTextField-root': {
		margin: '5px',
	},
});

export const Documentacion = () => {
	const dispatch = useDispatch();
	const [loadingBtn, setLoadingBtn] = useState(false);

	const isLoading = useSelector(state => state.documentation.loading);
	const docs = useSelector(state => state.documentation.documents);

	// useEffect(() => {
	// 	getAllDocuments().then(e => setDocs(e));
	// 	setIsLoading(false);
	// }, []);

	const formik = useFormik({
		initialValues: {
			description: '',
		},
		validationSchema: Yup.object({
			description: Yup.string().required(true),
		}),
		validateOnChange: false,
		onSubmit: async (formValue, { resetForm }) => {
			try {
				if (formik.isSubmitting) setLoadingBtn(true);
				await dispatch(createDocument(formValue));
				setLoadingBtn(false);
				toast.success('Documentación creada exitosamente.', { theme: 'colored' });
				resetForm();
			} catch (error) {
				console.error(error);
				toast.error('¡Ha habido un error, intentelo de nuevo!', { theme: 'colored' });
				setLoadingBtn(false);
				resetForm();
			}
		},
	});

	const handleDeleteDoc = async id => {
		await dispatch(deleteDocument(id));
	};

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='lg'>
					<Button
						onClick={() => window.history.back()}
						variant='contained'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '15px',
							mb: '20px',
						}}
					>
						<SvgIcon>
							<ArrowBackIcon />
						</SvgIcon>
						Volver atrás
					</Button>
					<Stack direction='column' spacing={3}>
						<Card
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
							}}
						>
							<CardContent>
								<form
									onSubmit={formik.handleSubmit}
									style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
								>
									<TextFieldContact
										required
										id='description'
										label='Documentacion'
										variant='filled'
										name='description'
										sx={{ width: '100%' }}
										value={formik.values.description}
										onChange={formik.handleChange}
										error={formik.errors.description}
									/>
									<LoadingButton variant='contained' type='submit' loading={loadingBtn}>
										<Typography align='center' variant='caption'>
											CREAR
										</Typography>
									</LoadingButton>
								</form>
							</CardContent>
						</Card>
						<Card>
							<Box minWidth={700}>
								{isLoading ? (
									<div
										style={{
											width: '100%',
											textAlign: 'center',
											marginTop: '40px',
											height: '120px',
										}}
									>
										<CircularProgress />
									</div>
								) : (
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Documentación</TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											{docs.map(e => {
												return (
													<TableRow hover key={e.id}>
														<TableCell>
															<Stack alignItems='center' direction='row' spacing={2}>
																<Typography variant='subtitle2'>{e.description}</Typography>
															</Stack>
														</TableCell>
														<TableCell>
															<Stack alignItems='end' spacing={2}>
																<Tooltip title='Eliminar'>
																	<IconButton onClick={() => handleDeleteDoc(e.id)}>
																		<DeleteIcon color='error' />
																	</IconButton>
																</Tooltip>
															</Stack>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								)}
							</Box>
						</Card>
					</Stack>
				</Container>
			</Box>
			<ToastContainer />
		</>
	);
};
