import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoadingButton from '@mui/lab/LoadingButton';
import { WarningDialog } from '../Dialogs';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import { createUser } from '../../redux/actions/admin/users';

const TextFieldContact = styled(TextField)({
	'&.MuiTextField-root': {
		margin: '5px',
	},
});

const system = [
	{
		value: ' ',
		label: ' ',
	},
	{
		value: 'Desarrollo',
		label: 'Desarrollos',
	},
	{
		value: 'Elemental',
		label: 'Elemental',
	},
	{
		value: 'Grupo Delsud',
		label: 'Grupo Delsud',
	},
];
const roles = [
	{
		value: ' ',
		label: ' ',
	},
	{
		value: 'usuario',
		label: 'Usuario',
	},
	{
		value: 'administrador',
		label: 'Administrador',
	},
	{
		value: 'moderador',
		label: 'Moderador',
	},
];

const areas = [
	{
		value: ' ',
		label: ' ',
	},
	{
		value: 'Comercial',
		label: 'Comercial',
	},
	{
		value: 'Administracion',
		label: 'Administración',
	},
	{
		value: 'Contable',
		label: 'Contable',
	},
	{
		value: 'Community Manager',
		label: 'Community Manager',
	},
	{
		value: 'Marketing',
		label: 'Marketing',
	},
];

export const ModalCreateUser = ({ open, setOpen }) => {
	const dispatch = useDispatch();
	const [isDirty, setIsDirty] = useState(false);
	const [warning, setWarning] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleClose = () => {
		if (isDirty) return setWarning(true);
		else {
			setOpen(false);
			formik.resetForm();
		}
	};

	const handleDirty = () => {
		setWarning(false);
		setIsDirty(false);
		setOpen(false);
		formik.resetForm();
	};

	const formik = useFormik({
		initialValues: initialValues(),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async (formValue, { resetForm }) => {
			try {
				if (formValue.password !== formValue.repeatedPassword)
					return toast.error('Las contraseñas no coinciden!', { theme: 'colored' });
				if (formValue.nameAreaRole.length < 3)
					return toast.error('Especifique el area de trabajo!', { theme: 'colored' });
				if (formValue.roleName.length < 3) return toast.error('Especifique el rol del usuario!');
				if (formValue.systemName.length < 3)
					return toast.error('Especifique el sistema al cual pertenecerá el usuario!', {
						theme: 'colored',
					});
				if (formik.isSubmitting) setIsLoading(true);
				dispatch(createUser(formValue));
				setIsLoading(false);
				setIsDirty(false);
				toast.success('Usuario creado exitosamente.', { theme: 'colored' });
				setOpen(false);
				resetForm();
			} catch (error) {
				console.error(error);
				return toast.error('¡Ha habido un error, intentelo de nuevo!', { theme: 'colored' });
			}
		},
	});

	return (
		<div>
			<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
				<DialogTitle>Añadir nuevo usuario</DialogTitle>
				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						width: '100% !important',
						pt: '15px !important',
					}}
				>
					<form onSubmit={formik.handleSubmit}>
						<div style={{ display: 'flex' }}>
							<TextFieldContact
								required
								id='fullName'
								label='Nombre y apellido'
								variant='filled'
								name='fullName'
								sx={{ width: '100%' }}
								value={formik.values.fullName}
								onChange={e => {
									formik.handleChange(e);
									setIsDirty(true);
								}}
								error={!!formik.errors.fullName}
							/>
						</div>
						<div style={{ display: 'flex' }}>
							<TextFieldContact
								type='password'
								required
								id='password'
								label='Contraseña'
								variant='filled'
								name='password'
								sx={{ width: '50%' }}
								value={formik.values.password}
								onChange={e => {
									formik.handleChange(e);
									setIsDirty(true);
								}}
								error={!!formik.errors.password}
							/>
							<TextFieldContact
								type='password'
								required
								id='repeatedPassword'
								label='Repetir contraseña'
								variant='filled'
								name='repeatedPassword'
								sx={{ width: '50%' }}
								value={formik.values.repeatedPassword}
								onChange={e => {
									formik.handleChange(e);
									setIsDirty(true);
								}}
								error={!!formik.errors.repeatedPassword}
							/>
						</div>

						<TextFieldContact
							required
							id='email'
							label='Email'
							variant='filled'
							name='email'
							sx={{ width: '99%' }}
							value={formik.values.email}
							onChange={e => {
								formik.handleChange(e);
								setIsDirty(true);
							}}
							error={!!formik.errors.email}
						/>
						<div style={{ display: 'flex' }}>
							<TextFieldContact
								sx={{ width: '50%' }}
								label='Rol'
								name='roleName'
								select
								required
								SelectProps={{ native: true }}
								value={formik.values.roleName}
								onChange={e => {
									formik.handleChange(e);
									setIsDirty(true);
								}}
								error={!!formik.errors.roleName}
							>
								{roles.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextFieldContact>
							<TextFieldContact
								required
								sx={{ width: '50%' }}
								label='Area'
								name='nameAreaRole'
								select
								SelectProps={{ native: true }}
								value={formik.values.nameAreaRole}
								onChange={e => {
									formik.handleChange(e);
									setIsDirty(true);
								}}
								error={!!formik.errors.nameAreaRole}
							>
								{areas.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextFieldContact>
							<TextFieldContact
								required
								sx={{ width: '50%' }}
								label='Sistema'
								name='systemName'
								select
								SelectProps={{ native: true }}
								value={formik.values.systemName}
								onChange={e => {
									formik.handleChange(e);
									setIsDirty(true);
								}}
								error={!!formik.errors.systemName}
							>
								{system.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextFieldContact>
						</div>
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancelar</Button>
					<LoadingButton
						variant='contained'
						type='submit'
						loading={isLoading}
						onClick={formik.handleSubmit}
					>
						Añadir
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<WarningDialog
				open={warning}
				setOpen={setWarning}
				goBack={handleDirty}
				resetForm={() => formik.resetForm()}
			/>
			<ToastContainer />
		</div>
	);
};

function validationSchema() {
	return Yup.object({
		fullName: Yup.string().required(true),
		password: Yup.string().required(true),
		repeatedPassword: Yup.string().required(true),
		email: Yup.string().email(true).required(true),
		roleName: Yup.string().required(true),
		nameAreaRole: Yup.string().required(true),
		systemName: Yup.string().required(true),
	});
}

function initialValues() {
	return {
		fullName: '',
		password: '',
		repeatedPassword: '',
		email: '',
		roleName: '',
		nameAreaRole: '',
		systemName: '',
	};
}
