import { CHANGE_LOADER_DOCS, GET_ALL_DOCUMENTS } from '../../actions/admin/docs';

const initialState = {
	documents: [],
	loading: false,
};

export const documentation = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_DOCUMENTS:
			return {
				...state,
				documents: payload,
				loading: false,
			};
		case CHANGE_LOADER_DOCS:
			return {
				...state,
				loading: true,
			};

		default:
			return state;
	}
};
