import {
	CHANGE_LOADER_ORIGINS,
	GET_DATA_ORIGINS,
	GET_CLIENTS_REACHED,
	GET_MONTH_TARGET,
	GET_NEW_CLIENTS,
	GET_CHART_MONTHS,
	GET_RESERVAS_VENCIDAS,
} from '../../actions/user/home';

const initialState = {
	data: [],
	clientsReached: 0,
	loadingOrigins: false,
	loadingReached: false,
	monthTarget: 0,
	newClients: 0,
	chartMonths: [],
	reservasVencidas: [],
};

export const homeData = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_DATA_ORIGINS:
			return {
				...state,
				data: payload,
				loadingOrigins: false,
			};
		case CHANGE_LOADER_ORIGINS:
			return {
				...state,
				loadingOrigins: true,
			};
		case GET_CLIENTS_REACHED:
			return {
				...state,
				clientsReached: payload,
			};
		case GET_MONTH_TARGET:
			return {
				...state,
				monthTarget: payload,
			};
		case GET_NEW_CLIENTS:
			return {
				...state,
				newClients: payload,
			};
		case GET_CHART_MONTHS:
			return {
				...state,
				chartMonths: payload,
			};
		case GET_RESERVAS_VENCIDAS:
			return {
				...state,
				reservasVencidas: payload,
			};

		default:
			return state;
	}
};
