import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_DATA_CLIENT = 'GET_DATA_CLIENT';
export const GET_HISTORY_CLIENT = 'GET_HISTORY_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const GET_ALL_ME_CLIENTS = 'GET_ALL_ME_CLIENTS';
export const GET_TOTAL_ME_CLIENTS = 'GET_TOTAL_ME_CLIENTS';
export const GET_TOTAL_STATES_CLIENTS = 'GET_TOTAL_STATES_CLIENTS';
export const GET_TOTAL_LOTS_CLIENT = 'GET_TOTAL_LOTS_CLIENT';
export const ASSIGN_LOT_CLIENT = 'ASSIGN_LOT_CLIENT';
export const EXPORT_FICHA_RESERVA_CLIENT = 'EXPORT_FICHA_RESERVA_CLIENT';
export const CHANGE_ORDER_CLIENTS_UPDATED = 'CHANGE_ORDER_CLIENTS_UPDATED';
export const CHANGE_ORDER_CLIENTS_CREATED = 'CHANGE_ORDER_CLIENTS_CREATED';
export const CHANGE_ROWS_PER_PAGE = 'CHANGE_ROWS_PER_PAGE';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_STATE = 'CHANGE_STATE';
export const RESET_FILTERS = 'RESET_FILTERS';
export const ADD_SEARCH_QUERY = 'ADD_SEARCH_QUERY';
export const CHANGE_LOADER = 'CHANGE_LOADER';

export const getAllAdvisorClients = () => {
	return async function (dispatch, getState) {
		dispatch({ type: CHANGE_LOADER });
		const stateBefore = getState().clientsAdvisor;
		const response = await authAxios(
			`${URL_API}/client/clients/${stateBefore.page}/${stateBefore.rowsPerPage}/${stateBefore.status}/${stateBefore.typeOrder}/${stateBefore.order}?${stateBefore.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_ME_CLIENTS,
			payload: response,
		});
	};
};

export const getTotalClientsAdvisor = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/states/total`, 'get');
		return dispatch({
			type: GET_TOTAL_ME_CLIENTS,
			payload: response,
		});
	};
};

// export const changeOrder = order => {
// 	return async (dispatch, getState) => {
// 		dispatch({ type: CHANGE_LOADER });
// 		await dispatch({ type: CHANGE_ORDER_CLIENTS, payload: order });
// 		const newStates = getState().clientsAdvisor;
// 		const response = await authAxios(
// 			`${URL_API}/client/clients/${newStates.page}/${newStates.rowsPerPage}/${newStates.status}/${order}?${newStates.querys}`,
// 			'get'
// 		);
// 		return dispatch({
// 			type: GET_ALL_ME_CLIENTS,
// 			payload: response,
// 		});
// 	};
// };
export const changeOrderUpdated = order => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_ORDER_CLIENTS_UPDATED, payload: order });
		const newStates = getState().clientsAdvisor;
		const response = await authAxios(
			`${URL_API}/client/clients/${newStates.page}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_ME_CLIENTS,
			payload: response,
		});
	};
};

export const changeOrderCreatedAt = order => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_ORDER_CLIENTS_CREATED, payload: order });
		const newStates = getState().clientsAdvisor;
		const response = await authAxios(
			`${URL_API}/client/clients/${newStates.page}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_ME_CLIENTS,
			payload: response,
		});
	};
};

export const changeRowsPerPage = rowsPerPage => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_ROWS_PER_PAGE, payload: rowsPerPage });
		const newStates = getState().clientsAdvisor;
		const response = await authAxios(
			`${URL_API}/client/clients/${newStates.page}/${rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_ME_CLIENTS,
			payload: response,
		});
	};
};

export const changePage = newPage => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_PAGE, payload: newPage });
		const newStates = getState().clientsAdvisor;
		const response = await authAxios(
			`${URL_API}/client/clients/${newPage}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_ME_CLIENTS,
			payload: response,
		});
	};
};

export const changeState = newState => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_STATE, payload: newState });
		const newStates = getState().clientsAdvisor;
		const response = await authAxios(
			`${URL_API}/client/clients/${newStates.page}/${newStates.rowsPerPage}/${newState}/${newStates.typeOrder}/${newStates.order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_ME_CLIENTS,
			payload: response,
		});
	};
};

export const resetFilters = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER });
		const totals = await authAxios(`${URL_API}/client/states/total`, 'get');
		dispatch({
			type: GET_TOTAL_ME_CLIENTS,
			payload: totals,
		});
		const response = await authAxios(`${URL_API}/client/clients/0/25/1/updatedAt/desc?`, 'get');
		dispatch({ type: RESET_FILTERS });
		return dispatch({
			type: GET_ALL_ME_CLIENTS,
			payload: response,
		});
	};
};

export const search = query => {
	return async (dispatch, getState) => {
		const totals = await authAxios(`${URL_API}/client/states/total?&${query}`, 'get');
		dispatch({
			type: GET_TOTAL_ME_CLIENTS,
			payload: totals,
		});

		await dispatch({ type: ADD_SEARCH_QUERY, payload: query });
		const newStates = getState().clientsAdvisor;
		const response = await authAxios(
			`${URL_API}/client/clients/${newStates.page}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?&${query}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_ME_CLIENTS,
			payload: response,
		});
	};
};

export const addClient = data => {
	return async function (dispatch, getState) {
		const response = await authAxios(`${URL_API}/client/create/contact`, 'post', null, data);
		if (response === 409) return response;
		else {
			const newStates = getState().clientsAdvisor;
			const clients = await authAxios(
				`${URL_API}/client/clients/${newStates.page}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?`,
				'get'
			);
			dispatch({ type: GET_ALL_ME_CLIENTS, payload: clients });
		}
	};
};

export const updateLastNote = async data => {
	await authAxios(`${URL_API}/client/update/note`, 'put', null, data);
};

export const changeColorContact = async data => {
	await authAxios(`${URL_API}/client/color/contact`, 'put', null, data);
};
