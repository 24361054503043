import {
	GET_AVAILABLE_LOTS,
	CHANGE_LOADER_LOTS,
	GET_RESUME_DEVELOPMENTS_LOTS,
} from '../../actions/user/lots';

const initialState = {
	availableLots: [],
	loading: true,
	developments: [],
};

export const assignLot = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_AVAILABLE_LOTS:
			return {
				...state,
				availableLots: payload,
				loading: false,
			};
		case CHANGE_LOADER_LOTS:
			return {
				...state,
				loading: true,
			};
		case GET_RESUME_DEVELOPMENTS_LOTS:
			return {
				...state,
				developments: payload,
				loading: false,
			};

		default:
			return state;
	}
};
