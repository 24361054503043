import { Button } from '@mui/material';

export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
	const handlePageChange = page => {
		onPageChange(page);
	};

	const renderPageNumbers = () => {
		const pageNumbers = [];

		for (let i = 1; i <= totalPages; i++) {
			pageNumbers.push(
				<Button
					variant={currentPage === i ? 'contained' : 'outlined'}
					key={i}
					onClick={() => handlePageChange(i)}
				>
					{i}
				</Button>
			);
		}

		return pageNumbers;
	};

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				padding: '15px',
				gap: '5px',
			}}
		>
			{renderPageNumbers()}
		</div>
	);
};
