import React from 'react';
import {
	Box,
	Container,
	// Pagination,
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DevelopmentCard } from '../components/Desarrollos';

export const Desarrollos = () => {
	const items = useSelector(state => state.developmentsUser.developments);
	const loading = useSelector(state => state.developmentsUser.loading);
	const navigate = useNavigate();

	const developmentDetail = id => {
		navigate(`/desarrollo/${id}`);
	};

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='xl'>
					<Stack spacing={3}>
						<Stack direction='row' justifyContent='space-between' spacing={4}>
							<Stack spacing={1}>
								<Typography variant='h4'>Desarrollos</Typography>
							</Stack>
						</Stack>
						{loading ? (
							<div
								style={{ width: '100%', textAlign: 'center', marginTop: '100px', height: '120px' }}
							>
								<CircularProgress />
							</div>
						) : (
							<>
								<Grid container spacing={3}>
									{items.map((e, i) => {
										if (e.availableLot.length === 0) return null;
										return (
											<Grid xs={12} md={6} lg={4} key={e.id}>
												<span style={{ cursor: 'pointer' }} onClick={() => developmentDetail(e.id)}>
													<DevelopmentCard development={e} image={i} />
												</span>
											</Grid>
										);
									})}
								</Grid>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									{/* <Pagination count={3} size='small' /> */}
								</Box>
							</>
						)}
					</Stack>
				</Container>
				<Container maxWidth='xl'>
					<Stack spacing={3}>
						<Stack direction='row' justifyContent='space-between' spacing={4}>
							<Stack spacing={1}>
								<Typography variant='h4' color='grey'>
									Terminados
								</Typography>
							</Stack>
						</Stack>
						{loading ? (
							<div
								style={{ width: '100%', textAlign: 'center', marginTop: '100px', height: '120px' }}
							>
								<CircularProgress />
							</div>
						) : (
							<>
								<Grid container spacing={3}>
									{items.map((e, i) => {
										if (e.availableLot.length !== 0) return null;
										return (
											<Grid xs={12} md={6} lg={4} key={e.id}>
												<span style={{ cursor: 'pointer' }} onClick={() => developmentDetail(e.id)}>
													<DevelopmentCard development={e} image={i} />
												</span>
											</Grid>
										);
									})}
								</Grid>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									{/* <Pagination count={3} size='small' /> */}
								</Box>
							</>
						)}
					</Stack>
				</Container>
			</Box>
		</>
	);
};
