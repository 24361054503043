import { TableCell, TableRow, Typography, useMediaQuery } from '@mui/material';
import { changeColorContact } from '../../redux/actions/user/oportunidades';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { formatDate } from '../../utils/formatDate';
import { ElementLastNote } from './ElementLastNote';
import { styled } from '@mui/material/styles';
import { CirclePicker } from 'react-color';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ColorTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '10px',
	},
}));

export const ClientItem = ({ e }) => {
	const [bgColor, setBgColor] = useState(e.color);
	const smallScreen = useMediaQuery('(max-width:1230px)');

	function handleTextColor(color) {
		if (['#f38181', '#3c486b', '#5cb80b', '#f45050'].includes(color)) return 'white';
		else if (['#ffffff', '#90e0ef', '#f7d060'].includes(color)) return 'black';
	}

	function handleChangeBackground(color) {
		if (color === '#ffff') {
			setBgColor(null);
			return changeColorContact({ color: null, id: e.id });
		}
		setBgColor(color);
		changeColorContact({ color: color, id: e.id });
	}

	return (
		<TableRow hover={!bgColor || bgColor === '#ffffff'} sx={{ bgcolor: bgColor }}>
			<TableCell>
				<ColorTooltip
					title={
						<CirclePicker
							colors={['#ffff', '#90e0ef', '#F38181', '#F7D060', '#3C486B', '#5CB80B', '#F45050']}
							width='150'
							circleSize={20}
							onChange={e => handleChangeBackground(e.hex)}
						/>
					}
					arrow
				>
					<div
						style={{
							width: '15px',
							height: '15px',
							backgroundColor: bgColor ? (bgColor === '#ffffff' ? '#F2F4F7' : bgColor) : '#F2F4F7',
							borderRadius: '50%',
							cursor: 'pointer',
							boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
						}}
					></div>
				</ColorTooltip>
			</TableCell>
			<TableCell align='left'>
				<Link to={`/contacto/${e.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
					<Typography
						variant='subtitle2'
						sx={{ cursor: 'pointer', color: handleTextColor(bgColor) || 'inherit' }}
					>
						{e.name} {e.lastName ? e.lastName : null}
					</Typography>
				</Link>
			</TableCell>
			<TableCell align='left'>
				<Tooltip title='Ir a whatsapp' arrow>
					<a
						style={{ textDecoration: 'none' }}
						target='_blank'
						href={`https://wa.me/${e.cellphone}`}
						rel='noopener noreferrer'
					>
						{e.cellphone}
					</a>
				</Tooltip>
			</TableCell>
			<TableCell
				align='left'
				sx={{ maxWidth: '500px !important', color: handleTextColor(bgColor) || 'inherit' }}
			>
				<ElementLastNote lastNote={e.lastNote} data={e} />
			</TableCell>
			<TableCell align='right' sx={{ color: handleTextColor(bgColor) || 'inherit' }}>
				{e.updatedAt ? formatDate(e.updatedAt) : 'ERROR'}
			</TableCell>
			{!smallScreen ? (
				<TableCell align='right' sx={{ color: handleTextColor(bgColor) || 'inherit' }}>
					{e.createdAt ? formatDate(e.createdAt) : 'ERROR'}
				</TableCell>
			) : (
				<></>
			)}
		</TableRow>
	);
};
