import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_ALL_DOCUMENTS = 'GET_ALL_DOCUMENTS';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const CHANGE_LOADER_DOCS = 'CHANGE_LOADER_DOCS';

export const getAllDocuments = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_DOCS });
		const response = await authAxios(`${URL_API}/admin/docs`, 'get');
		return dispatch({
			type: GET_ALL_DOCUMENTS,
			payload: response,
		});
	};
};

export const deleteDocument = id => {
	return async function (dispatch) {
		await authAxios(`${URL_API}/admin/docs/${id}`, 'delete');
		dispatch({ type: CHANGE_LOADER_DOCS });
		const response = await authAxios(`${URL_API}/admin/docs`, 'get');
		return dispatch({
			type: GET_ALL_DOCUMENTS,
			payload: response,
		});
	};
};

export const createDocument = data => {
	return async function (dispatch) {
		await authAxios(`${URL_API}/admin/docs`, 'post', null, data);
		dispatch({ type: CHANGE_LOADER_DOCS });
		const response = await authAxios(`${URL_API}/admin/docs`, 'get');
		return dispatch({
			type: GET_ALL_DOCUMENTS,
			payload: response,
		});
	};
};
