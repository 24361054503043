import React, { useState, useRef, useEffect } from 'react';
import { OutlinedInput } from '@mui/material';
import { updateLastNote } from '../../redux/actions/user/oportunidades';

const textStyle = {
	display: 'flex',
	minHeight: '21px',
	width: 'auto',
};

export const ElementLastNote = ({ lastNote, data }) => {
	const [toggle, setToggle] = useState(false);
	const [value, setValue] = useState(lastNote);
	const [errorEdit, setErrorEdit] = useState(false);
	const inputRef = useRef(null);

	const handleChange = e => {
		setValue(e.target.value);
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			handleBlur();
			setToggle(false);
		}
	};

	const handleBlur = () => {
		const lastNoteUpdate = {
			...data,
			lastNote: value,
		};
		if (value === lastNote) return setErrorEdit(true);
		if (value.length === 0) {
			setValue(lastNote);
			return setErrorEdit(true);
		}
		updateLastNote(lastNoteUpdate);
		setToggle(false);
	};

	useEffect(() => {
		const handleClickOutside = e => {
			if (inputRef.current && !inputRef.current.contains(e.target)) {
				setToggle(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<>
			{toggle ? (
				<OutlinedInput
					type='text'
					value={value ? value : ' '}
					onChange={handleChange}
					onBlur={handleBlur}
					ref={inputRef}
					error={errorEdit}
					onKeyPress={handleKeyPress}
					fullWidth
				/>
			) : (
				<p style={textStyle} onDoubleClick={() => setToggle(true)}>
					{value}
				</p>
			)}
		</>
	);
};
