import { createContext, useEffect, useState } from "react";
import { getMe } from "../redux/actions/login";
import { getToken, hasExpiredToken, removeToken, setToken as saveToken } from "../redux/actions/token";
// import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export function AuthProvider(props) {
    const { children } = props;
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    // const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const token = getToken();
            if (!token) {
                logout();
                setLoading(false);
                return;
            }

            if (hasExpiredToken(token)) logout();
            else await login(token);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line no-unused-vars
    const login = async (token) => {
        try {
            saveToken(token);
            setToken(token);
            setLoading(false);
            setUser(true);
            let result = await getMe();
            setUser(result);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const logout = () => {
        if (window.location.pathname === "/login") return;
        window.location.replace("/login");
        removeToken();
        setToken(null);
        setUser(null);
    };

    const data = {
        accessToken: token,
        user,
        login,
        logout: logout,
        updateUser: null,
    };

    if (loading) return null;

    return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
}
