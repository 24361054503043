import React, { useState, useRef } from 'react';
import {
	Card,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	CardHeader,
	CardContent,
	MenuItem,
	Typography,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { EditorState, convertToRaw } from 'draft-js';

import { useDispatch, useSelector } from 'react-redux';
import { createDevelopment } from '../../redux/actions/admin/developments';

import { DescriptionDraft } from './DescriptionDraft';
import { CreateInteractiveMap, LocationDesarrolloMap } from '../InteractiveMaps';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const TextFieldContact = styled(TextField)({
	'&.MuiTextField-root': {
		margin: '5px',
		'& input[type=number]': {
			MozAppearance: 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
	},
});

const styleFile = {
	display: 'flex',
	width: 'auto',
	flexDirection: 'column',
};

export const CreateDesarrollo = ({ goBack }) => {
	const dispatch = useDispatch();
	const docs = useSelector(state => state.documentation.documents);

	const [logo, setLogo] = useState(null);
	const [planes, setPlanes] = useState(null);
	const [desarrollo, setDesarrollo] = useState(null);
	const [docsSelected, setDocsSelected] = useState([]);

	const [planoError, setPlanoError] = useState(false);
	const [logoError, setLogoError] = useState(false);
	const [desarrolloError, setDesarrolloError] = useState(false);

	const [fileNameLogo, setFileNameLogo] = useState('');
	const [fileNamePlanes, setFileNamePlanes] = useState('');
	const [fileNameDesarrollo, setFileNameDesarrollo] = useState('');

	const [coordinates, setCoordinates] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const [lotes, setLotes] = useState([]);

	// Estados para el input: Descripcion
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

	const handleEditorChange = newEditorState => {
		setEditorState(newEditorState);
	};
	// ========================================

	const logoFile = useRef(null);
	const planesFile = useRef(null);
	const desarrolloFile = useRef(null);

	const handleLogoFile = event => {
		if (!event.target.files[0]) return;
		const file = event.target.files[0];
		const { name, size } = file;
		const fileExtension = name.split('.').pop().toLowerCase();
		const fileSize = size / (1024 * 1024);

		if (!['svg'].includes(fileExtension)) {
			setLogoError(true);
			return;
		}

		if (fileSize > 5) {
			setLogoError(true);
			return;
		}

		setFileNameLogo(name);
		setLogo(file);
	};

	const handlePlanesFile = event => {
		setPlanoError(false);
		if (!event.target.files[0]) return;
		const file = event.target.files[0];
		const { name, size } = file;
		const fileExtension = name.split('.').pop().toLowerCase();
		const fileSize = size / (1024 * 1024);

		if (!['jpg', 'jpeg', 'png', 'svg'].includes(fileExtension)) {
			setPlanoError(true);
			return;
		}

		if (fileSize > 5) {
			setPlanoError(true);
			return;
		}

		setFileNamePlanes(name);
		setPlanes(file);
	};

	const handleDesarrolloFile = event => {
		setDesarrolloError(false);
		if (!event.target.files[0]) return;
		const file = event.target.files[0];
		const { name, size } = file;
		const fileExtension = name.split('.').pop().toLowerCase();
		const fileSize = size / (1024 * 1024);

		if (!['jpg', 'jpeg', 'png', 'svg'].includes(fileExtension)) {
			setLogoError(true);
			return;
		}

		if (fileSize > 5) {
			setPlanoError(true);
			return;
		}

		setFileNameDesarrollo(name);
		setDesarrollo(file);
	};

	const handleCancel = () => {
		setLogo(null);
		setPlanes(null);
		setDesarrollo(null);
		setFileNamePlanes('');
		setFileNameLogo('');
		setFileNameDesarrollo('');
		formik.resetForm();
		goBack(true);
	};

	const formik = useFormik({
		initialValues: initialValues(),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async (formValue, { resetForm }) => {
			try {
				let formateLotes = lotes.map(e => {
					return { ...e, layerPoint: JSON.stringify(e.layerPoint) };
				});
				const contentState = editorState.getCurrentContent();
				const contentStateJson = JSON.stringify(convertToRaw(contentState));
				formValue = { ...formValue, description: contentStateJson };
				let docsJson = JSON.stringify(docsSelected);

				formateLotes = JSON.stringify(formateLotes);
				formValue = { ...formValue, lotes: formateLotes, docs: docsJson };

				if (formik.isSubmitting) setIsLoading(true);
				let formData = new FormData();

				if (logo === null || planes === null) {
					setIsLoading(false);
					return toast.error('Ha habido un error en la carga de imagenes!', { theme: 'colored' });
				}

				formData.append('desarrollo', desarrollo);
				formData.append('planes', planes);
				formData.append('logo', logo);
				formData.append('coordinates', coordinates);
				for (const key in formValue) {
					if (formValue.hasOwnProperty(key)) {
						formData.append(key, formValue[key]);
					}
				}

				dispatch(createDevelopment({ 'Content-Type': 'multipart/form-data' }, formData));
				setIsLoading(false);
				resetForm();
				setLogo(null);
				setPlanes(null);
				setFileNameLogo('');
				setFileNamePlanes('');
				setDesarrollo(null);
				setFileNameDesarrollo('');
				toast.success('Desarrollo creado con éxito', { theme: 'colored' });
				return goBack(true);
			} catch (error) {
				console.error(error);
				return toast.error('Ha habido un error, intentelo de nuevo!', { theme: 'colored' });
			}
		},
	});

	return (
		<>
			<DialogTitle>Nuevo desarrollo</DialogTitle>
			<DialogContent
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					width: '100% !important',
					pt: '15px !important',
				}}
			>
				<form onSubmit={formik.handleSubmit}>
					<Card
						sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px !important', borderRadius: '15px' }}
					>
						<CardHeader
							sx={{ backgroundColor: '#14213d', color: 'white', opacity: 0.9 }}
							title='Descripción'
						/>

						<CardContent>
							<TextFieldContact
								id='name'
								label='Nombre'
								variant='filled'
								name='name'
								sx={{ width: '40%' }}
								value={formik.values.name}
								onChange={formik.handleChange}
								error={formik.errors.name}
							/>
							<DescriptionDraft editorState={editorState} handleEditorChange={handleEditorChange} />
							<TextFieldContact
								id='measuresLot'
								label='Medidas de cada lote'
								variant='filled'
								name='measuresLot'
								value={formik.values.measuresLot}
								onChange={formik.handleChange}
								error={formik.errors.measuresLot}
							/>
							<TextFieldContact
								id='lots'
								label='Cantidad de lotes'
								variant='filled'
								name='lots'
								value={formik.values.lots}
								onChange={formik.handleChange}
								error={formik.errors.lots}
							/>
						</CardContent>
					</Card>

					<br />
					<br />
					<Card
						sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px !important', borderRadius: '15px' }}
					>
						<CardHeader
							sx={{ backgroundColor: '#14213d', color: 'white', opacity: 0.9 }}
							title='Localización'
						/>
						<CardContent>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ display: 'flex' }}>
									<TextFieldContact
										id='localidad'
										label='Localidad'
										variant='filled'
										name='locality'
										sx={{ width: '50%' }}
										value={formik.values.locality}
										onChange={formik.handleChange}
										error={formik.errors.locality}
									/>
									<TextFieldContact
										id='address'
										label='Dirección'
										variant='filled'
										name='address'
										sx={{ width: '50%' }}
										value={formik.values.address}
										onChange={formik.handleChange}
										error={formik.errors.address}
									/>
								</div>
								<div style={{ height: '450px' }}>
									<LocationDesarrolloMap setCoordinates={setCoordinates} />
								</div>
							</div>
						</CardContent>
					</Card>
					<br />
					<br />

					<Card
						sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px !important', borderRadius: '15px' }}
					>
						<CardHeader
							sx={{ backgroundColor: '#14213d', color: 'white', opacity: 0.9 }}
							title='Requerimientos'
						/>
						<CardContent>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignContent: 'center',
									margin: 5,
								}}
							>
								<Autocomplete
									multiple
									id='docs'
									onChange={(event, values) => {
										setDocsSelected(values);
									}}
									options={docs}
									disableCloseOnSelect
									getOptionLabel={option => option.description}
									renderOption={(props, option, { selected }) => (
										<li {...props}>
											<Checkbox
												icon={icon}
												checkedIcon={checkedIcon}
												style={{ marginRight: 8 }}
												checked={selected}
											/>
											{option.description}
										</li>
									)}
									style={{ width: '100%' }}
									renderInput={params => (
										<TextField {...params} label='Documentacion' placeholder='' />
									)}
								/>
							</div>
							<TextFieldContact
								id='legal'
								label='Escribanía'
								variant='filled'
								name='legal'
								fullWidth
								multiline
								rows={5}
								value={formik.values.legal}
								onChange={formik.handleChange}
								error={formik.errors.legal}
							/>
						</CardContent>
					</Card>

					<br />
					<br />

					<Card
						sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px !important', borderRadius: '15px' }}
					>
						<CardHeader
							sx={{ backgroundColor: '#14213d', color: 'white', opacity: 0.9 }}
							title='Valor y financiación'
						/>
						<CardContent>
							<TextFieldContact
								sx={{ width: 250 }}
								label='Moneda'
								name='currency'
								select
								value={formik.values.currency}
								onChange={formik.handleChange}
								error={formik.errors.currency}
							>
								<MenuItem value={1}>Dólar</MenuItem>
								<MenuItem value={2}>Peso Argentino</MenuItem>
								<MenuItem value={3}>Euro</MenuItem>
							</TextFieldContact>
							<TextFieldContact
								label='Valor'
								name='value'
								type='number'
								value={formik.values.value}
								onChange={formik.handleChange}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											{formik.values.currency === 1
												? 'USD'
												: formik.values.currency === 2
												? '$'
												: '€'}
										</InputAdornment>
									),
								}}
								error={formik.errors.value}
							></TextFieldContact>
							<TextFieldContact
								label='Descuento'
								name='discount'
								type='number'
								value={formik.values.discount}
								onChange={formik.handleChange}
								error={formik.errors.discount}
								InputProps={{ endAdornment: '%' }}
							></TextFieldContact>
							<TextFieldContact
								label='Seña'
								name='downPayment'
								value={formik.values.downPayment}
								onChange={formik.handleChange}
								error={formik.errors.downPayment}
							></TextFieldContact>
							<TextFieldContact
								fullWidth
								label='Costros extras'
								name='extraCosts'
								multiline
								rows={3}
								value={formik.values.extraCosts}
								onChange={formik.handleChange}
								error={formik.errors.extraCosts}
							></TextFieldContact>
							<TextFieldContact
								fullWidth
								label='Financiación en pesos'
								name='financingPesos'
								multiline
								rows={3}
								value={formik.values.financingPesos}
								onChange={formik.handleChange}
								error={formik.errors.financingPesos}
							></TextFieldContact>
							<TextFieldContact
								fullWidth
								label='Financiación en dolares'
								name='financingDollars'
								multiline
								rows={3}
								value={formik.values.financingDollars}
								onChange={formik.handleChange}
								error={formik.errors.financingDollars}
							></TextFieldContact>
						</CardContent>
					</Card>

					<br />
					<br />

					<Card
						sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px !important', borderRadius: '15px' }}
					>
						<CardHeader
							sx={{ backgroundColor: '#14213d', color: 'white', opacity: 0.9 }}
							title='Datos adicionales'
						/>
						<CardContent>
							<TextFieldContact
								disabled
								fullWidth
								label='Servicios'
								name='services'
								value={formik.values.services}
								onChange={formik.handleChange}
								error={formik.errors.services}
							></TextFieldContact>
							<TextFieldContact
								fullWidth
								label='Etiquetas'
								name='etiquettes'
								disabled
								value={formik.values.etiquettes}
								onChange={formik.handleChange}
								error={formik.errors.etiquettes}
							></TextFieldContact>

							<div
								style={{ display: 'flex', gap: '15px', marginTop: '15px', flexDirection: 'column' }}
							>
								<span style={styleFile}>
									<Button variant='contained' component='label'>
										<UploadFileIcon />
										<input
											hidden
											accept='.svg'
											type='file'
											ref={logoFile}
											onChange={handleLogoFile}
										/>
										<Typography variant='caption'>
											{fileNameLogo.length < 1 ? 'Logo' : fileNameLogo}
										</Typography>
									</Button>
									{!logoError ? null : (
										<>
											<Typography variant='caption' color='red'>
												Formato: .svg
											</Typography>
											<Typography variant='caption' color='red'>
												Peso máximo: 5mb
											</Typography>
										</>
									)}
								</span>
								<span style={styleFile}>
									<Button variant='contained' component='label'>
										<UploadFileIcon />
										<input
											hidden
											accept='.jpg, .jpeg, .png, .svg'
											type='file'
											ref={desarrolloFile}
											onChange={handleDesarrolloFile}
										/>
										<Typography variant='caption'>
											{fileNameDesarrollo.length < 1 ? 'Desarrollo' : fileNameDesarrollo}
										</Typography>
									</Button>
									{!desarrolloError ? null : (
										<>
											<Typography variant='caption' color='red'>
												Formatos: .jpg .jpeg .png .svg
											</Typography>
											<Typography variant='caption' color='red'>
												Peso máximo: 5mb
											</Typography>
										</>
									)}
								</span>
								<span style={styleFile}>
									<Button variant='contained' component='label'>
										<UploadFileIcon />
										<input
											hidden
											accept='.jpg, .jpeg, .png, .svg'
											type='file'
											ref={planesFile}
											onChange={handlePlanesFile}
										/>
										<Typography variant='caption'>
											{fileNamePlanes.length < 1 ? 'Plano' : fileNamePlanes}
										</Typography>
									</Button>
									{!planoError ? null : (
										<>
											<Typography variant='caption' color='red'>
												Formatos: .jpg .jpeg .png .svg
											</Typography>
											<Typography variant='caption' color='red'>
												Peso máximo: 5mb
											</Typography>
										</>
									)}
								</span>
							</div>
							{fileNamePlanes.length < 1 ? null : (
								<CreateInteractiveMap imageFile={planes} setLotes={setLotes} lotes={lotes} />
							)}
						</CardContent>
					</Card>
					<DialogActions>
						<Button onClick={() => handleCancel()}>Cancelar</Button>
						<LoadingButton variant='contained' type='submit' loading={isLoading}>
							Añadir
						</LoadingButton>
					</DialogActions>
				</form>
			</DialogContent>
			<ToastContainer />
		</>
	);
};

function initialValues() {
	return {
		name: '',
		measuresLot: '',
		lots: '',
		locality: '',
		address: '',
		legal: '',
		currency: 1,
		value: 0,
		discount: 0,
		downPayment: 0,
		extraCosts: '',
		financingPesos: '',
		financingDollars: '',
		services: '',
		etiquettes: '',
	};
}

function validationSchema() {
	return Yup.object({
		name: Yup.string().required(true),
		measuresLot: Yup.string().required(true),
		lots: Yup.number().required(true),
		locality: Yup.string().required(true),
		address: Yup.string(),
		legal: Yup.string(),
		currency: Yup.number(),
		value: Yup.number(),
		discount: Yup.number(),
		downPayment: Yup.number(),
		extraCosts: Yup.string(),
		financingPesos: Yup.string(),
		financingDollars: Yup.string(),
		services: Yup.array(),
		etiquettes: Yup.array(),
	});
}
