import React, { useState } from 'react';
import {
	Box,
	Card,
	Stack,
	Divider,
	SvgIcon,
	Typography,
	CardContent,
	CardActionArea,
} from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import laPlata from '../../assets/laplata_vista_aerea.jpg';

export const DevelopmentCard = ({ development }) => {
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleImageLoad = () => {
		setImageLoaded(true);
	};

	return (
		<Card
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				visibility: imageLoaded ? 'visible' : 'hidden',
			}}
		>
			<CardActionArea>
				<div
					style={{
						background: `url(${development.desarrollo}) center center no-repeat`,
						backgroundSize: 'cover',
					}}
				>
					<img
						src={!development.desarrollo ? development.desarrollo : laPlata}
						alt='Fondo'
						onLoad={handleImageLoad}
						style={{ display: 'none' }}
					/>
					<CardContent
						sx={{
							height: '200px',
							backgroundImage: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0, 0, 0, 0.4) 0%)',
						}}
					>
						<Typography
							align='center'
							sx={{ color: 'white', textShadow: '0px 1px 2px rgba(0, 0, 0, 1)' }}
							gutterBottom
							variant='h5'
						>
							{development.name}
						</Typography>
						<Typography
							align='center'
							sx={{ color: 'white', textShadow: '0px 1px 2px rgba(0, 0, 0, 1)' }}
							variant='body1'
						>
							{development.locality}
						</Typography>
						<Typography
							align='center'
							sx={{ color: 'white', textShadow: '0px 1px 2px rgba(0, 0, 0, 1)' }}
							variant='body1'
						>
							{development.address}
						</Typography>
					</CardContent>
				</div>

				<Box sx={{ flexGrow: 1 }} />
				<Divider />
				<Stack
					alignItems='center'
					direction='row'
					justifyContent='space-between'
					spacing={2}
					sx={{ p: 2 }}
				>
					<Stack alignItems='center' direction='row' spacing={1}>
						<SvgIcon color='action' fontSize='small'>
							<ViewModuleIcon />
						</SvgIcon>
						<Typography color='text.secondary' display='inline' variant='body2'>
							{development.totLot} Lotes
						</Typography>
					</Stack>
					<Stack alignItems='center' direction='row' spacing={1}>
						<Typography color='text.secondary' display='inline' variant='body2'>
							{development.availableLot.length} Disponibles
						</Typography>
					</Stack>
				</Stack>
			</CardActionArea>
		</Card>
	);
};
