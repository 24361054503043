import { CHANGE_LOADER_FICHA, GET_DATA_LOT } from '../../actions/user/ficha';

const initialState = {
	data: [],
	loading: false,
};

export const fichaData = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_DATA_LOT:
			return {
				...state,
				data: payload,
				loading: false,
			};
		case CHANGE_LOADER_FICHA:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
};
