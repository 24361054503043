import { Box, Unstable_Grid2 as Grid } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";

// assets
import { wallpaperGrupo } from "../../assets/logos";

export const LayoutAuth = ({ children }) => {
    const { user } = useAuth();

    if (user) {
        window.location.replace("/");
        return null;
    }

    return (
        <Box
            component='main'
            sx={{
                display: "flex",
                flex: "1 1 auto",
                height: "100vh",
            }}
        >
            <Grid container sx={{ flex: "1 1 auto" }}>
                <Grid
                    xs={12}
                    lg={6}
                    sx={{
                        backgroundColor: "background.paper",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                    }}
                >
                    <Box
                        component='header'
                        sx={{
                            left: 0,
                            p: 3,
                            position: "fixed",
                            top: 0,
                            width: "100%",
                        }}
                    >
                        <Box
                            component='a'
                            href='/'
                            sx={{
                                display: "inline-flex",
                                height: 32,
                                width: 32,
                            }}
                        ></Box>
                    </Box>
                    {children}
                </Grid>
                <Grid
                    xs={12}
                    lg={6}
                    sx={{
                        alignItems: "center",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        backgroundImage: `url(${wallpaperGrupo})`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                ></Grid>
            </Grid>
        </Box>
    );
};
