import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import {
	Box,
	Container,
	Stack,
	Card,
	Typography,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress,
	IconButton,
	Tooltip,
	SvgIcon,
	Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../hooks/useAuth';
import { formatDate } from '../utils/formatDate';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { createZone, deleteZone } from '../redux/actions/admin/zones';

const TextFieldContact = styled(TextField)({
	'&.MuiTextField-root': {
		margin: '5px',
	},
});

export const Zonas = () => {
	const dispatch = useDispatch();
	const { user } = useAuth();

	const zones = useSelector(state => state.zonesAdmin.zones);
	const isLoading = useSelector(state => state.zonesAdmin.loading);

	const [loadingBtn, setLoadingBtn] = useState(false);

	const formik = useFormik({
		initialValues: {
			description: '',
		},
		validationSchema: Yup.object({
			description: Yup.string().required(true),
		}),
		validateOnChange: false,
		onSubmit: async (formValue, { resetForm }) => {
			try {
				if (formik.isSubmitting) setLoadingBtn(true);
				await dispatch(createZone(formValue));
				setLoadingBtn(false);
				toast.success('Zona creada exitosamente.', { theme: 'colored' });
				resetForm();
			} catch (error) {
				console.error(error);
				toast.error('¡Ha habido un error, intentelo de nuevo!', { theme: 'colored' });
				setLoadingBtn(false);
				resetForm();
			}
		},
	});

	const handleDeleteZone = id => {
		dispatch(deleteZone(id));
	};

	if (user.roleId === 3) return;
	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='lg'>
					<Button
						onClick={() => window.history.back()}
						variant='contained'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '15px',
							mb: '20px',
						}}
					>
						<SvgIcon>
							<ArrowBackIcon />
						</SvgIcon>
						Volver atrás
					</Button>
					<Stack direction='row' spacing={3}>
						<Card
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
							}}
						>
							<CardContent>
								<form
									onSubmit={formik.handleSubmit}
									style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
								>
									<TextFieldContact
										required
										id='description'
										label='Zona'
										variant='filled'
										name='description'
										sx={{ width: '100%' }}
										value={formik.values.description}
										onChange={formik.handleChange}
										error={formik.errors.description}
									/>
									<LoadingButton variant='contained' type='submit' loading={loadingBtn}>
										<Typography align='center' variant='caption'>
											CREAR ZONA
										</Typography>
									</LoadingButton>
								</form>
							</CardContent>
							<Box sx={{ flexGrow: 1 }} />
							<Divider />
							<Stack
								alignItems='center'
								direction='row'
								justifyContent='space-between'
								spacing={2}
								sx={{ p: 2 }}
							>
								<Typography color='text.secondary' display='inline' variant='body2'>
									Zonas creadas:
								</Typography>
								<Typography color='text.secondary' display='inline' variant='body2'>
									{zones ? zones.length : 'ERROR'}
								</Typography>
							</Stack>
						</Card>
						<Card>
							<Box minWidth={700}>
								{isLoading ? (
									<div style={{ width: '100%', textAlign: 'center', marginTop: '40px' }}>
										<CircularProgress />
									</div>
								) : (
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Zona</TableCell>
												<TableCell>Interesados</TableCell>
												<TableCell>Fecha de creación</TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											{zones.map((e, i) => {
												return (
													<TableRow hover key={i}>
														<TableCell>
															<Stack alignItems='center' direction='row' spacing={2}>
																<Typography variant='subtitle2'>{e.description}</Typography>
															</Stack>
														</TableCell>
														<TableCell>
															<Stack alignItems='center' direction='row' spacing={2}>
																<Typography variant='subtitle2'>
																	{e.interestedParties ? e.interestedParties : 0}
																</Typography>
															</Stack>
														</TableCell>
														<TableCell>
															<Stack alignItems='end' direction='row' spacing={2}>
																<Typography variant='subtitle2'>
																	{formatDate(e.createdAt)}
																</Typography>
															</Stack>
														</TableCell>
														<TableCell>
															<Stack alignItems='end' spacing={2}>
																<Tooltip title='Eliminar'>
																	<IconButton onClick={() => handleDeleteZone(e.id)}>
																		<DeleteIcon color='error' />
																	</IconButton>
																</Tooltip>
															</Stack>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								)}
							</Box>
						</Card>
					</Stack>
				</Container>
			</Box>
			<ToastContainer />
		</>
	);
};
