import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_ALL_ADVISORS_ADMIN = 'GET_ALL_ADVISORS_ADMIN';
export const CHANGE_ORDER_ADVISORS = 'CHANGE_ORDER_ADVISORS';
export const CHANGE_LOADER_ADVISORS = 'CHANGE_LOADER_ADVISORS';

export const getAllAdvisors = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_ADVISORS });
		const response = await authAxios(`${URL_API}/admin/order/advisors`, 'get');
		return dispatch({
			type: GET_ALL_ADVISORS_ADMIN,
			payload: response,
		});
	};
};

export const updateOrderAdvisors = data => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_ADVISORS });
		await authAxios(`${URL_API}/admin/update/order`, 'put', null, data);
		const response = await authAxios(`${URL_API}/admin/order/advisors`, 'get');
		return dispatch({
			type: GET_ALL_ADVISORS_ADMIN,
			payload: response,
		});
	};
};
