import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import {
	Box,
	Card,
	CardHeader,
	CardContent,
	Unstable_Grid2 as Grid,
	TextField,
	Divider,
	CardActions,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { dateReservas } from '../../utils/formatDate';
import { sendDataReserva } from '../../redux/actions/user/ficha';
import { provinces } from '../../lib/provinces';

export const TemplateFicha = ({ data, user, lote, toast }) => {
	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues: initialValues(data, user, lote),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async formValue => {
			try {
				setIsLoading(true);

				const userip = await axios.get('https://api.ipify.org/?format=json');

				const response = await sendDataReserva(formValue, data, lote, userip.data.ip);
				if (response === 'Error al descargar/enviar la reserva') throw new Error();
				setIsLoading(false);
				return toast.success('Reserva enviada a administración con éxito', { theme: 'colored' });
			} catch (error) {
				console.log(error);
				setIsLoading(false);
				return toast.error('Error, intentelo de nuevo.', { theme: 'colored' });
			}
		},
	});

	return (
		<div style={{ width: '70%' }}>
			<form onSubmit={formik.handleSubmit}>
				<Card>
					<CardHeader
						subheader='Datos personales firmante uno'
						title='Datos para ficha de reserva'
					/>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Nombre'
										name='nombreFirmante'
										value={formik.values.nombreFirmante}
										onChange={formik.handleChange}
										error={formik.errors.nombreFirmante}
									/>
								</Grid>

								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Apellido'
										name='apellidoFirmante'
										value={formik.values.apellidoFirmante}
										onChange={formik.handleChange}
										error={formik.errors.apellidoFirmante}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Tipo de doc'
										name='tipoDocFirmante'
										value={formik.values.tipoDocFirmante}
										onChange={formik.handleChange}
										error={formik.errors.tipoDocFirmante}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Numero doc'
										name='numeroDocFirmante'
										value={formik.values.numeroDocFirmante}
										onChange={formik.handleChange}
										error={formik.errors.numeroDocFirmante}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Fecha de nacimiento'
										name='fechaNacFirmante'
										type='date'
										value={formik.values.fechaNacFirmante}
										onChange={formik.handleChange}
										error={formik.errors.fechaNacFirmante}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Telefóno celular'
										name='numeroCelularFirmante'
										value={formik.values.numeroCelularFirmante}
										onChange={formik.handleChange}
										error={formik.errors.numeroCelularFirmante}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Celular 2'
										name='numeroCelular2Firmante'
										value={formik.values.numeroCelular2Firmante}
										onChange={formik.handleChange}
										error={formik.errors.numeroCelular2Firmante}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Dirección de email'
										name='emailFirmante'
										value={formik.values.emailFirmante}
										onChange={formik.handleChange}
										error={!!formik.errors.emailFirmante}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Ocupación'
										name='ocupacion'
										value={formik.values.ocupacion}
										onChange={formik.handleChange}
										error={!!formik.errors.ocupacion}
									/>
								</Grid>

								<Grid xs={12} md={9}>
									<TextField
										fullWidth
										label='Dirección'
										name='direccionFirmante'
										value={formik.values.direccionFirmante}
										onChange={formik.handleChange}
										error={!!formik.errors.direccionFirmante}
									/>
								</Grid>

								<Grid xs={12} md={3}>
									<TextField
										fullWidth
										label='Codigo postal'
										name='codigoPostalFirmante'
										value={formik.values.codigoPostalFirmante}
										onChange={formik.handleChange}
										error={!!formik.errors.codigoPostalFirmante}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Localidad'
										name='localidadFirmante'
										value={formik.values.localidadFirmante}
										onChange={formik.handleChange}
										error={formik.errors.localidadFirmante}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Provincia'
										name='provinciaFirmante'
										select
										SelectProps={{ native: true }}
										value={formik.values.provinciaFirmante}
										onChange={formik.handleChange}
										error={formik.errors.provinciaFirmante}
									>
										{provinces.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Datos personales firmante dos (opcional)' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Nombre'
										name='nombreFirmante2'
										value={formik.values.nombreFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.nombreFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Apellido'
										name='apellidoFirmante2'
										value={formik.values.apellidoFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.apellidoFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Tipo de doc'
										name='tipoDocFirmante2'
										value={formik.values.tipoDocFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.tipoDocFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Numero doc'
										name='numeroDocFirmante2'
										value={formik.values.numeroDocFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.numeroDocFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Fecha de nacimiento'
										name='fechaNacFirmante2'
										type='date'
										value={formik.values.fechaNacFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.fechaNacFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Telefóno celular'
										name='numeroCelularFirmante2'
										value={formik.values.numeroCelularFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.numeroCelularFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Celular 2'
										name='numeroCelular2Firmante2'
										value={formik.values.numeroCelular2Firmante2}
										onChange={formik.handleChange}
										error={formik.errors.numeroCelular2Firmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Dirección de email'
										name='emailFirmante2'
										value={formik.values.emailFirmante2}
										onChange={formik.handleChange}
										error={!!formik.errors.emailFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Ocupación'
										name='ocupacion2'
										value={formik.values.ocupacion2}
										onChange={formik.handleChange}
										error={!!formik.errors.ocupacion2}
									/>
								</Grid>

								<Grid xs={12} md={9}>
									<TextField
										fullWidth
										label='Dirección'
										name='direccionFirmante2'
										value={formik.values.direccionFirmante2}
										onChange={formik.handleChange}
										error={!!formik.errors.direccionFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={3}>
									<TextField
										fullWidth
										label='Codigo postal'
										name='codigoPostalFirmante2'
										value={formik.values.codigoPostalFirmante2}
										onChange={formik.handleChange}
										error={!!formik.errors.codigoPostalFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Localidad'
										name='localidadFirmante2'
										value={formik.values.localidadFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.localidadFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Provincia'
										name='provinciaFirmante2'
										select
										SelectProps={{ native: true }}
										value={formik.values.provinciaFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.provinciaFirmante2}
									>
										{provinces.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Lote' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={8}>
									<TextField
										fullWidth
										label='Loteo'
										name='nombreLote'
										value={formik.values.nombreLote}
										onChange={formik.handleChange}
										error={formik.errors.nombreLote}
									/>
								</Grid>
								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='N° de lote'
										name='numeroLote'
										value={formik.values.numeroLote}
										onChange={formik.handleChange}
										error={formik.errors.numeroLote}
									/>
								</Grid>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Dirección'
										name='direccionLote'
										value={formik.values.direccionLote}
										onChange={formik.handleChange}
										error={formik.errors.direccionLote}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Pago' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='Reserva'
										type='date'
										name='reservaFecha'
										value={formik.values.reservaFecha}
										onChange={formik.handleChange}
										error={formik.errors.reservaFecha}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Vencimiento'
										name='vencimientoFecha'
										type='date'
										value={formik.values.vencimientoFecha}
										onChange={e => {
											formik.handleChange(e);
										}}
										error={formik.errors.vencimientoFecha}
									/>
								</Grid>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='Monto reservado'
										name='montoReservado'
										value={formik.values.montoReservado}
										onChange={formik.handleChange}
										error={formik.errors.montoReservado}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Valor total'
										name='valorTotal'
										value={formik.values.valorTotal}
										onChange={formik.handleChange}
										error={formik.errors.valorTotal}
									/>
								</Grid>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Forma de pago'
										name='formaPago'
										value={formik.values.formaPago}
										onChange={formik.handleChange}
										error={formik.errors.formaPago}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Auto' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='¿Entrega vehículo?'
										name='entregaVehiculo'
										value={formik.values.entregaVehiculo}
										onChange={formik.handleChange}
										error={formik.errors.entregaVehiculo}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Dominio'
										name='dominioVehiculo'
										value={formik.values.dominioVehiculo}
										onChange={formik.handleChange}
										error={formik.errors.dominioVehiculo}
									/>
								</Grid>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='Modelo'
										name='modeloVehiculo'
										value={formik.values.modeloVehiculo}
										onChange={formik.handleChange}
										error={formik.errors.modeloVehiculo}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Año'
										name='anoVehiculo'
										value={formik.values.anoVehiculo}
										onChange={formik.handleChange}
										error={formik.errors.anoVehiculo}
									/>
								</Grid>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='¿Tiene prenda?'
										name='tienePrenda'
										value={formik.values.tienePrenda}
										onChange={formik.handleChange}
										error={formik.errors.tienePrenda}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Monto prenda'
										name='montoPrenda'
										value={formik.values.montoPrenda}
										onChange={formik.handleChange}
										error={formik.errors.montoPrenda}
									/>
								</Grid>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='¿Multas impagas?'
										name='multasImpagas'
										value={formik.values.multasImpagas}
										onChange={formik.handleChange}
										error={formik.errors.multasImpagas}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Monto adeudado'
										name='montoMultas'
										value={formik.values.montoMultas}
										onChange={formik.handleChange}
										error={formik.errors.montoMultas}
									/>
								</Grid>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='¿Debe patente?'
										name='patentesImpagas'
										value={formik.values.patentesImpagas}
										onChange={formik.handleChange}
										error={formik.errors.patentesImpagas}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Monto adeudado'
										name='montoPatentes'
										value={formik.values.montoPatentes}
										onChange={formik.handleChange}
										error={formik.errors.montoPatentes}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Observaciones finales' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Observaciones'
										name='observaciones'
										value={formik.values.observaciones}
										onChange={formik.handleChange}
										error={!!formik.errors.observaciones}
										multiline
										rows={4}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />
					<CardActions sx={{ justifyContent: 'flex-end' }}>
						<LoadingButton loading={isLoading} variant='contained' type='submit'>
							Enviar ficha
						</LoadingButton>
					</CardActions>
				</Card>
			</form>
		</div>
	);
};

function initialValues(info, user, lote) {
	const date = new Date();

	const fecha = dateReservas(date);
	const fechaVencimiento = dateReservas(date.setDate(date.getDate() + 3));

	return {
		asesor: user.fullName,
		asesorEmail: user.email,
		nombreFirmante: info.clientData.name,
		apellidoFirmante: info.clientData.lastName ? info.clientData.lastName : '',
		tipoDocFirmante:
			info.clientData.TypeDocId === 1
				? 'DNI'
				: info.clientData.TypeDocId === 2
				? 'Pasaporte'
				: 'LE',
		numeroDocFirmante: info.clientData.numberId ? info.clientData.numberId : '',
		fechaNacFirmante: '',
		numeroCelularFirmante: info.clientData.cellphone,
		numeroCelular2Firmante: '',
		emailFirmante: info.clientData.email ? info.clientData.email : '',
		direccionFirmante: info.clientData.address ? info.clientData.address : '',
		codigoPostalFirmante: '',
		localidadFirmante: info.clientData.locality ? info.clientData.locality : '',
		provinciaFirmante: info.clientData.province ? info.clientData.province : '',
		ocupacion: '',
		nombreFirmante2: '',
		apellidoFirmante2: '',
		tipoDocFirmante2: '',
		numeroDocFirmante2: '',
		fechaNacFirmante2: '',
		numeroCelularFirmante2: '',
		numeroCelular2Firmante2: '',
		emailFirmante2: '',
		ocupacion2: '',
		direccionFirmante2: '',
		codigoPostalFirmante2: '',
		localidadFirmante2: '',
		provinciaFirmante2: '',
		nombreLote: info.developmentData.name,
		numeroLote: lote,
		direccionLote: '',
		manzanaLote: '',
		reservaFecha: fecha,
		vencimientoFecha: fechaVencimiento,
		montoReservado: '',
		valorTotal: info.developmentData.value,
		formaPago: '',
		entregaVehiculo: 'No',
		dominioVehiculo: '',
		modeloVehiculo: '',
		anoVehiculo: '',
		tienePrenda: '',
		montoPrenda: '',
		multasImpagas: '',
		montoMultas: '',
		patentesImpagas: '',
		montoPatentes: '',
		observaciones: '',
	};
}

function validationSchema() {
	return Yup.object({
		asesor: Yup.string().required(true),
		asesorEmail: '',
		nombreFirmante: Yup.string().required(true),
		apellidoFirmante: Yup.string().required(true),
		tipoDocFirmante: Yup.string().required(true),
		numeroDocFirmante: Yup.number().required(true),
		fechaNacFirmante: Yup.string().required(true),
		numeroCelularFirmante: '',
		numeroCelular2Firmante: '',
		emailFirmante: Yup.string().email().required(true),
		ocupacion: Yup.string().required(true),
		direccionFirmante: '',
		codigoPostalFirmante: '',
		localidadFirmante: '',
		provinciaFirmante: '',
		nombreFirmante2: '',
		apellidoFirmante2: '',
		tipoDocFirmante2: '',
		numeroDocFirmante2: '',
		fechaNacFirmante2: '',
		numeroCelularFirmante2: '',
		numeroCelular2Firmante2: '',
		emailFirmante2: '',
		ocupacion2: '',
		direccionFirmante2: '',
		codigoPostalFirmante2: '',
		localidadFirmante2: '',
		provinciaFirmante2: '',
		nombreLote: '',
		numeroLote: '',
		direccionLote: '',
		manzanaLote: '',
		reservaFecha: '',
		vencimientoFecha: '',
		montoReservado: '',
		valorTotal: '',
		formaPago: '',
		entregaVehiculo: '',
		dominioVehiculo: '',
		modeloVehiculo: '',
		anoVehiculo: '',
		tienePrenda: '',
		montoPrenda: '',
		multasImpagas: '',
		montoMultas: '',
		patentesImpagas: '',
		montoPatentes: '',
		observaciones: '',
	});
}
