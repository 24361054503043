import React from 'react';
import Calendar from 'react-calendar';
import './FullScreenCalendar.scss';

export const FullScreenCalendar = () => {
	return (
		<div>
			<Calendar className='fullScreen-calendar' />
		</div>
	);
};
