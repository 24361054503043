import { GET_ALL_ZONES } from '../../actions/user/zones';

const initialState = {
	zones: [],
};

export const zones = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_ZONES:
			return {
				...state,
				zones: payload,
			};

		default:
			return state;
	}
};
