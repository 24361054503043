import React from 'react';
import ArrowDownIcon from '@heroicons/react/24/solid/ArrowDownIcon';
import ArrowUpIcon from '@heroicons/react/24/solid/ArrowUpIcon';
import CurrencyDollarIcon from '@heroicons/react/24/solid/CurrencyDollarIcon';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';

import { formatNumber } from '../../utils/formatNumberWithDots';

export const OverviewBudget = ({ sx, monthTarget }) => {
	return (
		<Card sx={sx}>
			<CardContent>
				<Stack alignItems='flex-start' direction='row' justifyContent='space-between' spacing={3}>
					<Stack spacing={1}>
						<Typography color='text.secondary' variant='overline'>
							Ganancias
						</Typography>
						<Typography variant='h4'>
							${monthTarget ? formatNumber(Number(monthTarget.actualMonth?.raised)) : 0}
						</Typography>
					</Stack>
					<Avatar
						sx={{
							backgroundColor: 'error.main',
							height: 56,
							width: 56,
						}}
					>
						<SvgIcon>
							<CurrencyDollarIcon />
						</SvgIcon>
					</Avatar>
				</Stack>
				{monthTarget && (
					<Stack alignItems='center' direction='row' spacing={2} sx={{ mt: 2 }}>
						<Stack alignItems='center' direction='row' spacing={0.5}>
							<SvgIcon color={Boolean(monthTarget.positive) ? 'success' : 'error'} fontSize='small'>
								{Boolean(monthTarget.positive) ? <ArrowUpIcon /> : <ArrowDownIcon />}
							</SvgIcon>
							<Typography
								color={Boolean(monthTarget.positive) ? 'success.main' : 'error.main'}
								variant='body2'
							>
								{monthTarget.difference}%
							</Typography>
						</Stack>
						<Typography color='text.secondary' variant='caption'>
							Desde el último mes
						</Typography>
					</Stack>
				)}
			</CardContent>
		</Card>
	);
};
