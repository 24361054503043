import React, { useState, useEffect } from 'react';
import {
	Box,
	Container,
	CircularProgress,
	Typography,
	Card,
	CardContent,
	Divider,
	CardHeader,
	Button,
	styled,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	SvgIcon,
} from '@mui/material';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useSelector } from 'react-redux';
import { Map } from '../components/Maps/Map';
import { DesarrolloPerfilLotes } from '../components/Desarrollos';
import { DesarrolloPerfilMap } from '../components/InteractiveMaps';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatNumberWithDots } from '../utils/formatNumberWithDots';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../components/ConfigDesarrollos/DescriptionDraft.scss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#1b263b',
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 17,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const TextFieldContact = styled(TextField)({
	'&.MuiTextField-root': {
		marginTop: '15px',
		'& input[type=number]': {
			MozAppearance: 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
	},
	'.MuiInputLabel-root': {
		fontSize: '17px',
	},
});

export const DesarrolloPerfil = () => {
	const data = useSelector(state => state.developmentsUser.developmentPerfil);
	const loading = useSelector(state => state.developmentsUser.loadingPerfil);
	const [desarrolloPlano, setDesarrolloPlano] = useState(null);

	const [loadingMap, setLoadingMap] = useState(true);

	const [docs, setDocs] = useState([]);
	const [coordinates, setCoordinates] = useState([]);

	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

	useEffect(() => {
		if (data?.description) {
			const savedContentState = convertFromRaw(JSON.parse(data.description));
			const savedEditorState = EditorState.createWithContent(savedContentState);
			setEditorState(savedEditorState);
		}
		if (data?.documentation) {
			const documents = JSON.parse(data.documentation);
			setDocs(documents);
		}
		if (data?.coordinates) {
			let newCoordinates = JSON.parse(data.coordinates);
			newCoordinates = Array(JSON.parse(newCoordinates));
			setCoordinates(newCoordinates[0]);
			setLoadingMap(false);
		}

		const checkImageExists = async () => {
			try {
				const response = await fetch(data.plane);
				if (response.ok) {
					setDesarrolloPlano(true);
				} else {
					setDesarrolloPlano(false);
				}
			} catch (error) {
				setDesarrolloPlano(false);
			}
		};

		checkImageExists();
	}, [data]);

	const handleGoBack = () => window.history.back();

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
				}}
			>
				<Container maxWidth='xl'>
					<Button
						onClick={() => handleGoBack()}
						variant='contained'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '15px',
							mb: '20px',
						}}
					>
						<SvgIcon>
							<ArrowBackIcon />
						</SvgIcon>
						Volver atrás
					</Button>
					{loading ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								height: '350px',
							}}
						>
							<CircularProgress />
							<Typography sx={{ marginTop: 5 }}>Esto puede tardar unos segundos...</Typography>
						</div>
					) : (
						<>
							<Card>
								<CardContent sx={{ padding: 0 }}>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										{data?.logo && <img src={data?.logo} alt='Logo' height={320} width={320} />}
									</Box>
								</CardContent>
								<Divider />
							</Card>
							<Card sx={{ mt: 3 }}>
								<CardHeader title='Descripción del desarrollo' />
								<CardContent>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<Editor
											wrapperClassName='wrapper-read'
											editorState={editorState}
											toolbarHidden
											readOnly
										/>
										<Divider />
									</Box>
								</CardContent>
								<Divider />
							</Card>

							<Card sx={{ mt: 3 }}>
								<CardHeader title='Localización' />
								<CardContent>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<Typography gutterBottom variant='body2' sx={{ textAlign: 'justify' }}>
											{data.locality}
										</Typography>
										<Typography color='text.secondary' variant='body2'>
											{data.address}
										</Typography>

										{loadingMap ? (
											<div
												style={{
													display: 'flex',
													width: '100%',
													height: '150px',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<CircularProgress />
											</div>
										) : (
											<div style={{ height: '450px' }}>
												<Map coordinates={coordinates} name={data.name} />
											</div>
										)}
										{/* <Button sx={{ mt: 2 }}>
											<a
												href={`https://www.google.com/maps/place/${coordinates[0]},${coordinates[1]}`}
												target='_blank'
												rel='noreferrer'
												style={{ textDecoration: 'none', color: 'inherit' }}
											>
												Abrir en google maps
											</a>
										</Button> */}
									</Box>
								</CardContent>
								<Divider />
							</Card>

							<Card sx={{ mt: 3 }}>
								<CardHeader title='Requerimientos' />
								<CardContent>
									<Box
										sx={{
											display: 'flex',
											flexWrap: 'wrap',
											gap: '5px',
										}}
									>
										{docs.map(e => (
											<Typography
												sx={{
													backgroundColor: '#edede9',
													width: 'auto',
													padding: 1.5,
													borderRadius: 2,
												}}
												key={e.id}
											>
												{e.description}
											</Typography>
										))}
									</Box>
								</CardContent>
								<Divider />
							</Card>

							<Card sx={{ mt: 3 }}>
								<CardHeader title='Valor y financiación' />

								<CardContent>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<TableContainer>
											<Table sx={{ minWidth: 700 }} aria-label='customized table'>
												<TableHead>
													<TableRow>
														<StyledTableCell sx={{ borderTopLeftRadius: '5px' }}>
															Moneda
														</StyledTableCell>
														<StyledTableCell>Valor</StyledTableCell>
														<StyledTableCell>Descuento</StyledTableCell>
														<StyledTableCell sx={{ borderTopRightRadius: '5px' }}>
															Seña
														</StyledTableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<StyledTableRow>
														<StyledTableCell sx={{ borderBottomLeftRadius: '5px' }}>
															{data.currency === '1'
																? 'USD'
																: data.currency === '2'
																? 'ARS'
																: 'EUR'}
														</StyledTableCell>
														<StyledTableCell>{formatNumberWithDots(data.value)}</StyledTableCell>
														<StyledTableCell>{data.discount}%</StyledTableCell>
														<StyledTableCell sx={{ borderBottomRightRadius: '5px' }}>
															{formatNumberWithDots(data.downPayment)}
														</StyledTableCell>
													</StyledTableRow>
												</TableBody>
											</Table>
										</TableContainer>
										<TextFieldContact
											fullWidth
											readOnly
											label='Costos extras'
											multiline
											rows={3}
											value={data.extraCosts}
										></TextFieldContact>
										<TextFieldContact
											fullWidth
											readOnly
											label='Financiación en pesos'
											multiline
											rows={3}
											value={data.financingPesos}
										></TextFieldContact>
										<TextFieldContact
											fullWidth
											readOnly
											label='Financiación en dolares'
											multiline
											rows={3}
											value={data.financingDollars}
										></TextFieldContact>
									</Box>
								</CardContent>
								<Divider />
							</Card>

							<Card sx={{ mt: 3 }}>
								<CardHeader title='Lotes' />
								<CardContent>
									{!desarrolloPlano ? (
										<DesarrolloPerfilLotes />
									) : (
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<TableContainer
												sx={{
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												<Table
													sx={{
														maxWidth: '50%',
													}}
												>
													<TableHead>
														<TableRow>
															<StyledTableCell align='center' sx={{ borderTopLeftRadius: '5px' }}>
																Lotes
															</StyledTableCell>
															<StyledTableCell align='center' sx={{ borderTopRightRadius: '5px' }}>
																Lotes disponibles
															</StyledTableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														<StyledTableRow>
															<StyledTableCell
																align='center'
																sx={{ borderBottomLeftRadius: '5px' }}
															>
																{data.totLot}
															</StyledTableCell>
															<StyledTableCell
																align='center'
																sx={{ borderBottomRightRadius: '5px' }}
															>
																{data.availableLot.length}
															</StyledTableCell>
														</StyledTableRow>
													</TableBody>
												</Table>
											</TableContainer>
											<div style={{ height: '100%' }}>
												<DesarrolloPerfilMap development={data.plane} lotes={data.lotes} />
											</div>
										</Box>
									)}
								</CardContent>
								<Divider />
							</Card>

							<br />
							{/* <Button
								variant='contained'
								onClick={() => setLots(true)}
								sx={{ marginBottom: '20px' }}
							>
								Ver loteos
							</Button> */}
						</>
					)}
				</Container>
				{/* {lots ? <DesarrolloPerfilLotes /> : null} */}
			</Box>
		</>
	);
};
