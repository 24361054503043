import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_ALL_DEVELOPMENTS_RESUME = 'GET_ALL_DEVELOPMENTS_RESUME';
export const GET_ALL_DEVELOPMENTS_USER = 'GET_ALL_DEVELOPMENTS_USER';
export const GET_DEVELOPMENT = 'GET_DEVELOPMENT';
export const CHANGE_LOADER_USER_DEVELOPMENTS = 'CHANGE_LOADER_USER_DEVELOPMENTS';
export const CHANGE_LOADER_DEVELOPMENT_PROFILE = 'CHANGE_LOADER_DEVELOPMENT_PROFILE';
export const GET_ALL_LOTS_DEVELOPMENT = 'GET_ALL_LOTS_DEVELOPMENT';

export const getAllDevelopmentResume = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_USER_DEVELOPMENTS });
		const response = await authAxios(`${URL_API}/client/developments`, 'get');
		return dispatch({
			type: GET_ALL_DEVELOPMENTS_USER,
			payload: response,
		});
	};
};

export const getDevelopment = id => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_DEVELOPMENT_PROFILE });
		const response = await authAxios(`${URL_API}/client/development/${id}`, 'get');
		return dispatch({
			type: GET_DEVELOPMENT,
			payload: response,
		});
	};
};

export const getAllLotsDevelopment = id => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/lots/development/${id}`, 'get');
		return dispatch({
			type: GET_ALL_LOTS_DEVELOPMENT,
			payload: response,
		});
	};
};

export const updateDataLot = async data => {
	await authAxios(`${URL_API}/admin/update/lot`, 'put', null, data);
};
