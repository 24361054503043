import React from 'react';

import { Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import { useAuth } from '../hooks/useAuth';

import { TargetRaisedMonth } from '../components/Datos';

export const Datos = () => {
	const { user } = useAuth();

	if (user.roleId === 3) return;
	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<TargetRaisedMonth />
			</Box>
			<ToastContainer />
		</>
	);
};

// import React, { useState } from 'react';
// import TextField from '@mui/material/TextField';
// import { styled } from '@mui/material/styles';
// import { useFormik } from 'formik';
// import {
// 	Box,
// 	Container,
// 	Stack,
// 	Card,
// 	Typography,
// 	CardContent,
// 	Divider,
// 	Table,
// 	TableBody,
// 	TableCell,
// 	TableHead,
// 	TableRow,
// 	CircularProgress,
// 	IconButton,
// 	Tooltip,
// 	SvgIcon,
// 	Button,
// 	MenuItem,
// 	Select,
// 	FormControl,
// } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useAuth } from '../hooks/useAuth';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import { useDispatch, useSelector } from 'react-redux';
// import { createNewTarget, getHistoryTarget, updateTarget } from '../redux/actions/admin/datos';

// const TextFieldContact = styled(TextField)({
// 	'&.MuiTextField-root': {
// 		margin: '5px',
// 	},
// });

// const SelectYear = styled(Select)({
// 	width: '90px',
// 	height: '30px',
// 	color: 'white',
// 	'&.MuiInputBase-root': {
// 		border: '',
// 	},
// });

// export const Datos = () => {
// 	const dispatch = useDispatch();
// 	const { user } = useAuth();

// 	const targets = useSelector(state => state.datosAdmin.targetMonths);
// 	const actualMonth = useSelector(state => state.datosAdmin.actualMonth);
// 	const isLoading = useSelector(state => state.datosAdmin.loading);

// 	const [year, setYear] = useState(1);
// 	const [loadingBtn, setLoadingBtn] = useState(false);

// 	const formik = useFormik({
// 		enableReinitialize: true,
// 		initialValues: initialValues(actualMonth),
// 		validateOnChange: false,
// 		onSubmit: async (formValue, { resetForm }) => {
// 			try {
// 				let now = new Date();
// 				let actualDate2 = new Date(actualMonth.createdAt);
// 				setLoadingBtn(true);
// 				if (
// 					actualDate2.getFullYear() === now.getFullYear() &&
// 					now.getMonth() + 1 === actualDate2.getMonth() + 1
// 				) {
// 					dispatch(updateTarget({ id: actualMonth.id, ...formValue }));
// 					toast.success(`Objetivo de ${formatMonth[date]} actualizado.`, {
// 						theme: 'colored',
// 					});
// 				} else {
// 					await dispatch(createNewTarget(formValue));
// 					toast.success(`Objetivo de ${formatMonth[date]} creado.`, { theme: 'colored' });
// 				}
// 				setLoadingBtn(false);
// 				resetForm();
// 			} catch (error) {
// 				console.error(error);
// 				toast.error('¡Ha habido un error, intentelo de nuevo!', { theme: 'colored' });
// 				setLoadingBtn(false);
// 				resetForm();
// 			}
// 		},
// 	});

// 	const formatMonth = {
// 		1: 'Enero',
// 		2: 'Febrero',
// 		3: 'Marzo',
// 		4: 'Abril',
// 		5: 'Mayo',
// 		6: 'Junio',
// 		7: 'Julio',
// 		8: 'Agosto',
// 		9: 'Septiembre',
// 		10: 'Octubre',
// 		11: 'Noviembre',
// 		12: 'Diciembre',
// 	};

// 	const date = new Date().getMonth() + 1;

// 	if (user.roleId === 3) return;
// 	return (
// 		<>
// 			<Box
// 				component='main'
// 				sx={{
// 					flexGrow: 1,
// 					py: 8,
// 				}}
// 			>
// 				<Container maxWidth='lg'>
// 					<Button
// 						onClick={() => window.history.back()}
// 						variant='contained'
// 						sx={{
// 							display: 'flex',
// 							justifyContent: 'space-between',
// 							gap: '15px',
// 							mb: '20px',
// 						}}
// 					>
// 						<SvgIcon>
// 							<ArrowBackIcon />
// 						</SvgIcon>
// 						Volver atrás
// 					</Button>
// 					<Stack direction='row' spacing={3}>
// 						<Card
// 							sx={{
// 								display: 'flex',
// 								flexDirection: 'column',
// 								height: '100%',
// 							}}
// 						>
// 							<Stack
// 								alignItems='center'
// 								direction='row'
// 								justifyContent='space-between'
// 								sx={{ p: 2 }}
// 							>
// 								<Typography color='text.secondary' display='inline' variant='h5'>
// 									Objetivo {formatMonth[date]}
// 								</Typography>
// 							</Stack>
// 							<Divider />

// 							<CardContent sx={{ paddingTop: '5px' }}>
// 								{isLoading ? (
// 									<div style={{ width: '245px', textAlign: 'center', marginTop: '40px' }}>
// 										<CircularProgress size={25} />
// 									</div>
// 								) : (
// 									<form
// 										onSubmit={formik.handleSubmit}
// 										style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
// 									>
// 										<TextFieldContact
// 											required
// 											label='Objetivo'
// 											variant='filled'
// 											name='target'
// 											type='number'
// 											sx={{ width: '100%' }}
// 											value={formik.values.target}
// 											onChange={formik.handleChange}
// 											// error={formik.errors.target}
// 										/>
// 										<TextFieldContact
// 											required
// 											label='Recaudado'
// 											variant='filled'
// 											type='number'
// 											name='reached'
// 											sx={{ width: '100%' }}
// 											value={formik.values.reached}
// 											onChange={formik.handleChange}
// 											// error={formik.errors.reached}
// 										/>
// 										<LoadingButton variant='contained' type='submit' loading={loadingBtn}>
// 											<Typography align='center' variant='caption'>
// 												Actualizar
// 											</Typography>
// 										</LoadingButton>
// 									</form>
// 								)}
// 							</CardContent>
// 							<Box sx={{ flexGrow: 1 }} />
// 						</Card>
// 						<Card>
// 							<Box minWidth={700}>
// 								<Box
// 									sx={{
// 										backgroundColor: '#1b2b4b',
// 										height: '40px',
// 										display: 'flex',
// 										justifyContent: 'space-between',
// 										alignItems: 'center',
// 										paddingLeft: '10px',
// 										paddingRight: '10px',
// 									}}
// 								>
// 									<Typography color='white' display='inline' variant='button'>
// 										Historial
// 									</Typography>
// 									<FormControl variant='outlined'>
// 										<SelectYear
// 											labelId='label-año-historial'
// 											id='label-año-historial-select'
// 											value={year}
// 											onChange={e => {
// 												setYear(e.target.value);
// 												dispatch(getHistoryTarget(e.target.value));
// 											}}
// 											label=''
// 										>
// 											<MenuItem disabled value={1}>
// 												Año
// 											</MenuItem>
// 											<MenuItem value={2023}>2023</MenuItem>
// 											<MenuItem value={2022}>2022</MenuItem>
// 											<MenuItem value={2021}>2021</MenuItem>
// 										</SelectYear>
// 									</FormControl>
// 								</Box>
// 								{isLoading ? (
// 									<div style={{ width: '100%', textAlign: 'center', marginTop: '40px' }}>
// 										<CircularProgress />
// 									</div>
// 								) : (
// 									<Table>
// 										<TableHead>
// 											<TableRow>
// 												<TableCell>Objetivo</TableCell>
// 												<TableCell>Recaudado</TableCell>
// 												<TableCell>Mes</TableCell>
// 												<TableCell></TableCell>
// 											</TableRow>
// 										</TableHead>

// 										<TableBody>
// 											{targets.map((e, i) => {
// 												let month = new Date(e.createdAt).getMonth() + 1;

// 												return (
// 													<TableRow hover key={i}>
// 														<TableCell>
// 															<Stack alignItems='center' direction='row' spacing={2}>
// 																<Typography variant='subtitle2'>
// 																	USD {Number(e.target).toLocaleString('es-ES')}
// 																</Typography>
// 															</Stack>
// 														</TableCell>
// 														<TableCell>
// 															<Stack alignItems='center' direction='row' spacing={2}>
// 																<Typography variant='subtitle2'>
// 																	USD {Number(e.raised).toLocaleString('es-ES')}
// 																</Typography>
// 															</Stack>
// 														</TableCell>
// 														<TableCell>
// 															<Stack spacing={2}>
// 																<Typography variant='subtitle2'>{formatMonth[month]}</Typography>
// 															</Stack>
// 														</TableCell>
// 														<TableCell>
// 															<Stack alignItems='end' spacing={2}>
// 																<Tooltip title='Eliminar'>
// 																	<IconButton onClick={() => console.log(e.id)}>
// 																		<DeleteIcon color='error' fontSize='small' />
// 																	</IconButton>
// 																</Tooltip>
// 															</Stack>
// 														</TableCell>
// 													</TableRow>
// 												);
// 											})}
// 										</TableBody>
// 									</Table>
// 								)}
// 							</Box>
// 						</Card>
// 					</Stack>
// 				</Container>
// 			</Box>
// 			<ToastContainer />
// 		</>
// 	);
// };

// function initialValues(actualMonth) {
// 	return {
// 		target: actualMonth ? (actualMonth.target ? actualMonth.target : 0) : 0,
// 		reached: actualMonth ? (actualMonth.raised ? actualMonth.raised : 0) : 0,
// 	};
// }
