import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AccordionDevelopment } from '../AssignLot';
import { CircularProgress, IconButton } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { getDevelopmentsAssignLot } from '../../redux/actions/user/lots';

import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

export const ModalAsignLot = ({ open, setOpen, dataClient, getLotClient }) => {
	const dispatch = useDispatch();

	const loadingDevelopments = useSelector(state => state.assignLot.loading);
	const developments = useSelector(state => state.assignLot.developments);

	const [expanded, setExpanded] = useState(false);

	const [inputValue, setInputValue] = useState(0);

	const handleChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		dispatch(getDevelopmentsAssignLot());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
				<DialogTitle
					sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
				>
					Asignar lotes
					<IconButton aria-label='Cerrar' onClick={handleClose}>
						<CloseIcon color='primary' />
					</IconButton>
				</DialogTitle>
				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						width: '100% !important',
						height: '500px',
						pt: '15px !important',
					}}
				>
					{loadingDevelopments ? (
						<div
							style={{
								display: 'flex',
								gap: 35,
								flexDirection: 'column',
								height: '300px',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CircularProgress />
							<Typography variant='caption'>Esto puede tardar unos segundos...</Typography>
						</div>
					) : (
						<div>
							{developments.map(e => (
								<AccordionDevelopment
									dataClient={dataClient}
									developmentId={e.id}
									handleClose={handleClose}
									key={e.id}
									handleChange={handleChange}
									name={e.name}
									availableLot={e.availableLot}
									expanded={expanded}
									setInputValue={setInputValue}
									inputValue={inputValue}
									getLotClient={getLotClient}
								/>
							))}
						</div>
					)}
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
			<ToastContainer />
		</div>
	);
};
