import { GET_ALL_USERS, CHANGE_LOADER, GET_DATA_USER } from '../../actions/admin/users';

const initialState = {
	loading: false,
	users: [],
	userDetail: [],
};

export const allUsers = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_USERS:
			return {
				...state,
				users: payload,
				loading: false,
			};
		case CHANGE_LOADER:
			return {
				...state,
				loading: true,
			};
		case GET_DATA_USER:
			return {
				...state,
				userDetail: payload,
				loading: false,
			};
		default:
			return state;
	}
};
