import axios from 'axios';
import { authAxios } from '../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export async function validateUser(email, password) {
	const response = await axios.post(`${URL_API}/users/login`, { password, email });
	return response.data;
}

export async function getMe() {
	const response = await authAxios(`${URL_API}/users/me`, 'get');
	return response;
}

export async function changeMePassword(data) {
	const response = await authAxios(`${URL_API}/users/change/password`, 'put', null, data);
	return response;
}
