import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GroupsIcon from '@mui/icons-material/Groups';
import DevicesIcon from '@mui/icons-material/Devices';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	SvgIcon,
	Typography,
	useTheme,
	Unstable_Grid2 as Grid,
	CircularProgress,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import ApexChart from 'react-apexcharts';

const Chart = styled(ApexChart)``;

const useChartOptions = labels => {
	const theme = useTheme();

	return {
		chart: {
			background: 'transparent',
		},
		colors: [
			theme.palette.primary.main,
			theme.palette.success.main,
			theme.palette.warning.main,
			'#e63946',
			'#bdb2ff',
			'#e5e5e5',
		],
		dataLabels: {
			enabled: false,
		},
		labels,
		legend: {
			show: false,
		},
		plotOptions: {
			pie: {
				expandOnClick: false,
			},
		},
		states: {
			active: {
				filter: {
					type: 'none',
				},
			},
			hover: {
				filter: {
					type: 'none',
				},
			},
		},
		stroke: {
			width: 0,
		},
		theme: {
			mode: theme.palette.mode,
		},
		tooltip: {
			fillSeriesColor: false,
		},
	};
};

const iconMap = {
	Facebook: (
		<SvgIcon>
			<FacebookIcon />
		</SvgIcon>
	),
	Whatsapp: (
		<SvgIcon>
			<WhatsAppIcon />
		</SvgIcon>
	),
	Instagram: (
		<SvgIcon>
			<InstagramIcon />
		</SvgIcon>
	),
	Organico: (
		<SvgIcon>
			<GroupsIcon />
		</SvgIcon>
	),
	Web: (
		<SvgIcon>
			<DevicesIcon />
		</SvgIcon>
	),
	'Sin especificar': (
		<SvgIcon>
			<DoNotDisturbIcon />
		</SvgIcon>
	),
};

export const OverviewOriginClients = ({ sx, loadingOrigins, data }) => {
	const labels = ['Whatsapp', 'Facebook', 'Organico', 'Instagram', 'Sin especificar', 'Web'];

	const chartOptions = useChartOptions(labels);

	const matches = useMediaQuery('(max-width:1366px)');

	return (
		<Card sx={sx}>
			<CardHeader title='Datos' />
			<CardContent>
				{loadingOrigins ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '300px',
						}}
					>
						<CircularProgress />
					</div>
				) : (
					<>
						<Chart
							height={300}
							options={chartOptions}
							series={[
								data[0].whatsapp,
								data[1].facebook,
								data[2].organic,
								data[3].instagram,
								data[4].noEspecificado,
								data[5].web,
							]}
							type='donut'
							width='100%'
						/>
						<Grid container>
							{data.map((item, index) => {
								if (index === 6) return null;
								const label = labels[index];

								return (
									<Grid xl={4} sm={6} xs={4} key={label}>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												marginTop: '15px',
											}}
										>
											{iconMap[label]}
											<Typography sx={{ my: 1, fontSize: '16px' }} variant='h6'>
												{label}
											</Typography>
											<Typography color='text.secondary' variant='subtitle2'>
												{item.percentage}%
											</Typography>
										</Box>
									</Grid>
								);
							})}
						</Grid>
						{matches ? (
							<div
								style={{
									position: 'relative',
									bottom: '470px',
									left: '38%',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									width: '100px',
								}}
							>
								<p style={{ padding: 0, margin: 0, fontSize: '14px' }}>Total</p>
								<p style={{ padding: 0, margin: 0, fontSize: '20px' }}>{data[6].total}</p>
							</div>
						) : (
							<div
								style={{
									position: 'relative',
									bottom: '370px',
									left: '38%',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									width: '100px',
								}}
							>
								<p style={{ padding: 0, margin: 0, fontSize: '14px' }}>Total</p>
								<p style={{ padding: 0, margin: 0, fontSize: '20px' }}>{data[6].total}</p>
							</div>
						)}
					</>
				)}
			</CardContent>
		</Card>
	);
};
