import { useState } from 'react';
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
// import { UsuariosTable } from 'src/sections/usuarios/usuarios-table';
// import { ModalCreateUser } from 'src/components/ModalCreateUser';
// import { getAllUsers, getTotUsers } from 'src/services/admin.services';
import { useAuth } from '../hooks/useAuth';

import { ListUsers } from '../components/Usuarios';
import { ModalCreateUser } from '../components/Modals';

export const Usuarios = () => {
	const { user } = useAuth();

	const [open, setOpen] = useState(false);

	if (user.roleId === 3) return;
	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='xl'>
					<Stack spacing={3}>
						<Stack direction='row' justifyContent='space-between' spacing={4}>
							<Stack spacing={1}>
								<Typography variant='h4'>Lista de usuarios</Typography>
								<Stack alignItems='center' direction='row' spacing={1}>
									<Button
										color='inherit'
										disabled
										startIcon={
											<SvgIcon fontSize='small'>
												<ArrowUpOnSquareIcon />
											</SvgIcon>
										}
									>
										Importar
									</Button>
									<Button
										color='inherit'
										disabled
										startIcon={
											<SvgIcon fontSize='small'>
												<ArrowDownOnSquareIcon />
											</SvgIcon>
										}
									>
										Exportar
									</Button>
								</Stack>
							</Stack>
							<div>
								<Button
									startIcon={
										<SvgIcon fontSize='small'>
											<PlusIcon />
										</SvgIcon>
									}
									variant='contained'
									onClick={() => setOpen(true)}
								>
									Crear usuario
								</Button>
							</div>
						</Stack>
						<ListUsers />
						<ModalCreateUser open={open} setOpen={setOpen} />
					</Stack>
				</Container>
			</Box>
		</>
	);
};
