import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import {
	Box,
	Container,
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	CircularProgress,
	Button,
	SvgIcon,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { WarningDialog } from '../components/Dialogs';

import { useSelector } from 'react-redux';
import { UserProfile, UserProfileDetails } from '../components/UsuarioPerfil';
import { useParams } from 'react-router-dom';

export const UsuarioPerfil = () => {
	const { user } = useAuth();

	const { id } = useParams();

	const isLoading = useSelector(state => state.allUsers.loading);
	const userData = useSelector(state => state.allUsers.userDetail);

	const [isDirty, setIsDirty] = useState(false);
	const [warning, setWarning] = useState(false);

	const handleGoBack = () => {
		if (isDirty) setWarning(true);
		else window.history.back();
	};

	const windowHistoryBack = () => window.history.back();
	if (user.roleId > 2 && user.id !== Number(id)) return null;

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='lg'>
					<Button
						onClick={() => handleGoBack()}
						variant='contained'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '15px',
							mb: '20px',
						}}
					>
						<SvgIcon>
							<ArrowBackIcon />
						</SvgIcon>
						Volver
					</Button>
					{isLoading ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								height: '150px',
								alignItems: 'center',
							}}
						>
							<CircularProgress />
						</div>
					) : (
						<Stack spacing={3}>
							<div>
								<Typography variant='h4'>Empleado</Typography>
							</div>
							<div>
								<Grid container spacing={3}>
									<Grid xs={12} md={6} lg={4}>
										<UserProfile user={userData} />
									</Grid>
									<Grid xs={12} md={6} lg={8}>
										<UserProfileDetails user={userData} isDirty={setIsDirty} />
									</Grid>
								</Grid>
							</div>
						</Stack>
					)}
				</Container>
			</Box>
			<WarningDialog open={warning} setOpen={setWarning} goBack={windowHistoryBack} />
		</>
	);
};
