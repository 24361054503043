import jwtDecode from 'jwt-decode';

export const setToken = token => {
	return localStorage.setItem('token', token);
};

export const getToken = () => {
	return localStorage.getItem('token');
};

export const removeToken = () => {
	localStorage.removeItem('token');
	const fechaActual = new Date();

	const fechaHoy = `${fechaActual.getFullYear()}-${(fechaActual.getMonth() + 1)
		.toString()
		.padStart(2, '0')}-${fechaActual.getDate().toString().padStart(2, '0')}`;

	const keyLocalStorage = `daily_${fechaHoy}`;
	localStorage.removeItem(keyLocalStorage);
	return;
};

export const hasExpiredToken = token => {
	const tokenDecoded = jwtDecode(token);
	const result = tokenDecoded.exp * 1000 < Date.now();
	return result;
};
