import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';

export const AccountPopover = ({ anchorEl, onClose, open }) => {
	const { logout, user } = useAuth();

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: 'left',
				vertical: 'bottom',
			}}
			onClose={onClose}
			open={open}
			PaperProps={{ sx: { width: 200 } }}
		>
			<Box
				sx={{
					py: 1.5,
					px: 2,
				}}
			>
				<Typography variant='overline'>
					{user.roleId === 1 ? 'Administrador' : user.roleId === 2 ? 'Moderador' : 'Usuario'}
				</Typography>
				{/* <Typography color='text.secondary' variant='overline'>
					{user.roleId === 1 ? 'Administrador' : user.roleId === 2 ? 'Moderador' : 'Usuario'}
				</Typography> */}
				<Typography color='text.secondary' variant='body2'>
					{user.fullName}
				</Typography>
			</Box>
			<Divider />
			<MenuList
				disablePadding
				dense
				sx={{
					p: '8px',
					'& > *': {
						borderRadius: 1,
					},
				}}
			>
				<Link to={`/perfil`} style={{ textDecoration: 'none', color: 'inherit' }}>
					<MenuItem sx={{ display: 'flex', gap: '10px' }}>
						<SettingsIcon fontSize='12' />
						Configuraciones
					</MenuItem>
				</Link>
				<MenuItem onClick={logout} sx={{ display: 'flex', gap: '10px', color: 'red' }}>
					<LogoutIcon fontSize='12' />
					Cerrar sesión
				</MenuItem>
			</MenuList>
		</Popover>
	);
};
