export const provinces = [
	{
		value: 'Sin especificar',
		label: 'Sin especificar',
	},
	{
		value: 'Buenos Aires',
		label: 'Buenos Aires',
	},
	{
		value: 'Catamarca',
		label: 'Catamarca',
	},
	{
		value: 'Chaco',
		label: 'Chaco',
	},
	{
		value: 'Chubut',
		label: 'Chubut',
	},
	{
		value: 'Entre Rios',
		label: 'Entre Ríos',
	},
	{
		value: 'Rio Negro',
		label: 'Río negro',
	},
	{
		value: 'Salta',
		label: 'Salta',
	},
	{
		value: 'Santa Cruz',
		label: 'Santa Cruz',
	},
	{
		value: 'Santa Fe',
		label: 'Santa Fe',
	},
	{
		value: 'Tucuman',
		label: 'Tucumán',
	},
	{
		value: 'Cordoba',
		label: 'Córdoba',
	},
	{
		value: 'Corrientes',
		label: 'Corrientes',
	},
	{
		value: 'Formosa',
		label: 'Formosa',
	},
	{
		value: 'Jujuy',
		label: 'Jujuy',
	},
	{
		value: 'La Rioja',
		label: 'La Rioja',
	},
	{
		value: 'La Pampa',
		label: 'La Pampa',
	},
	{
		value: 'Mendoza',
		label: 'Mendoza',
	},
	{
		value: 'Misiones',
		label: 'Misiones',
	},
	{
		value: 'Neuquen',
		label: 'Neuquén',
	},
	{
		value: 'San Juan',
		label: 'San Juan',
	},
	{
		value: 'San Luis',
		label: 'San Luis',
	},
	{
		value: 'Santiago del Estero',
		label: 'Santiago del Estero',
	},
	{
		value: 'Tierra del Fuego',
		label: 'Tierro del Fuego',
	},
];
