import React from 'react';
import { FullScreenCalendar } from '../components/Calendario';

export const Calendario = () => {
	return (
		<div>
			<FullScreenCalendar />
		</div>
	);
};
