import { Box, ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";

export const SideNavItem = ({ active = false, disabled, icon, path, title }) => {
    return (
        <li>
            <Link to={path} style={{ textDecoration: "none", color: "inherit" }}>
                <ButtonBase
                    sx={{
                        alignItems: "center",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        pl: "16px",
                        pr: "16px",
                        py: "6px",
                        textAlign: "left",
                        width: "100%",
                        ...(active && {
                            backgroundColor: "rgba(255, 255, 255, 0.04)",
                        }),
                        "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.04)",
                        },
                    }}
                >
                    {icon && (
                        <Box
                            component='span'
                            sx={{
                                alignItems: "center",
                                color: "neutral.400",
                                display: "inline-flex",
                                justifyContent: "center",
                                mr: 2,
                                ...(active && {
                                    color: "#006ba6",
                                }),
                            }}
                        >
                            {icon}
                        </Box>
                    )}
                    <Box
                        component='span'
                        sx={{
                            color: "neutral.400",
                            flexGrow: 1,
                            fontFamily: (theme) => theme.typography.fontFamily,
                            fontSize: 14,
                            fontWeight: 600,
                            lineHeight: "24px",
                            whiteSpace: "nowrap",
                            ...(active && {
                                color: "common.white",
                            }),
                            ...(disabled && {
                                color: "neutral.500",
                            }),
                        }}
                    >
                        {title}
                    </Box>
                </ButtonBase>
            </Link>
        </li>
    );
};
