import { CHANGE_LOADER_TARGET, GET_HISTORY_TARGET } from '../../actions/admin/datos';

const initialState = {
	targetMonths: [],
	loading: false,
	actualMonth: 0,
};

export const datosAdmin = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_HISTORY_TARGET:
			return {
				...state,
				targetMonths: payload?.restYear ? payload.restYear.reverse() : [],
				actualMonth: payload?.actualMonth ? payload.actualMonth : [],
				loading: false,
			};

		case CHANGE_LOADER_TARGET:
			return {
				...state,
				loading: true,
			};

		default:
			return state;
	}
};
