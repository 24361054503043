import { useLocation } from 'react-router-dom';
import { Box, Divider, Drawer, Stack, Typography, useMediaQuery } from '@mui/material';
import { Scrollbar } from './scrollbar';
import { items, itemsAdmin, itemsMod } from './config';
import { SideNavItem } from './sideNavItem';

import packageJson from '../../../package.json';

import { logoGrupo_white } from '../../assets/logos';
import style from './sideNav.module.scss';

export const SideNav = ({ user }) => {
	const location = useLocation();
	const matches = useMediaQuery('(max-width:1700px)');

	const content = (
		<Scrollbar
			sx={{
				height: '100%',
				'& .simplebar-content': {
					height: '100%',
				},
				'& .simplebar-scrollbar:before': {
					background: 'neutral.400',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<Box sx={{ p: 3 }}>
					<Box
						component='a'
						href='/'
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<img
							className={style.logo}
							priority='true'
							src={logoGrupo_white}
							alt='Logo DelSud'
							height='232'
							width='232'
						/>
					</Box>
				</Box>
				<Divider sx={{ borderColor: 'neutral.700' }} />
				<Box
					component='nav'
					sx={{
						flexGrow: 1,
						px: 2,
						py: 3,
					}}
				>
					<Stack
						component='ul'
						spacing={0.5}
						sx={{
							listStyle: 'none',
							p: 0,
							m: 0,
						}}
					>
						{items.map(item => {
							const active = item.path ? location.pathname === item.path : false;

							return (
								<SideNavItem
									active={active}
									disabled={item.disabled}
									external={item.external}
									icon={item.icon}
									key={item.title}
									path={item.path}
									title={item.title}
								/>
							);
						})}
						<div style={{ marginTop: '25px' }}>
							<Divider sx={{ borderColor: 'neutral.700', mb: '15px' }} />
							{user.roleId === 1 ? (
								itemsAdmin.map(item => {
									const active = item.path ? location.pathname === item.path : false;

									return (
										<SideNavItem
											active={active}
											disabled={item.disabled}
											external={item.external}
											icon={item.icon}
											key={item.title}
											path={item.path}
											title={item.title}
										/>
									);
								})
							) : user.roleId === 2 ? (
								itemsMod.map(item => {
									const active = item.path ? location.pathname === item.path : false;

									return (
										<SideNavItem
											active={active}
											disabled={item.disabled}
											external={item.external}
											icon={item.icon}
											key={item.title}
											path={item.path}
											title={item.title}
										/>
									);
								})
							) : (
								<></>
							)}
						</div>
					</Stack>
				</Box>
				<Typography variant='caption' color='grey' align='right' sx={{ pr: 1 }}>
					versión {packageJson.version}
				</Typography>
			</Box>
		</Scrollbar>
	);

	if (matches) {
		return (
			<Drawer
				anchor='left'
				open
				PaperProps={{
					sx: {
						backgroundColor: 'neutral.800',
						color: 'common.white',
						width: 220,
					},
				}}
				variant='permanent'
			>
				{content}
			</Drawer>
		);
	}

	return (
		<Drawer
			anchor='left'
			open
			PaperProps={{
				sx: {
					backgroundColor: 'neutral.800',
					color: 'common.white',
					width: 280,
				},
			}}
			variant='permanent'
		>
			{content}
		</Drawer>
	);
};
