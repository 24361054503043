import React from 'react';
import { CircularProgress } from '@mui/material';

const centerDiv = {
	height: '100vh',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
};

export const AppLoader = () => {
	return (
		<div style={centerDiv}>
			Cargando...
			<CircularProgress />
		</div>
	);
};
