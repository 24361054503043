import React, { useState } from 'react';
import {
	Box,
	Card,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	SvgIcon,
	CircularProgress,
	Button,
} from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import { Scrollbar } from '../Scrollbar';
import { formatDate } from '../../utils/formatDate';

import { useDispatch, useSelector } from 'react-redux';
import { getHistoryClient } from '../../redux/actions/client';

export const ClientProfileHistory = ({ id }) => {
	const dispatch = useDispatch();

	const history = useSelector(state => state.clientDetail.history);

	const [isLoading, setIsLoading] = useState(true);
	const [load, setLoad] = useState(true);

	const handleLoad = async () => {
		setLoad(false);
		setIsLoading(true);
		await dispatch(getHistoryClient(id));
		setIsLoading(false);
	};

	return (
		<>
			{load ? (
				<Button
					onClick={() => handleLoad()}
					variant='contained'
					endIcon={
						<SvgIcon>
							<ZoomInIcon />
						</SvgIcon>
					}
				>
					Ver historial
				</Button>
			) : isLoading ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
					<CircularProgress />
				</div>
			) : (
				<Card sx={{ mt: 5 }}>
					<Scrollbar>
						<Box sx={{ minWidth: 800 }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align='left'>Descripcion</TableCell>
										<TableCell align='right'>Fecha</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{history.map(e => {
										return (
											<TableRow key={e.id}>
												<TableCell align='left' size='string'>
													{e.description}
												</TableCell>
												<TableCell align='right' sx={{ minWidth: '180px' }}>
													{e.createdAt ? formatDate(e.createdAt) : 'ERROR'}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</Box>
					</Scrollbar>
				</Card>
			)}
		</>
	);
};
