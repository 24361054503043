import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	DialogTitle,
	Slider,
	Box,
	styled,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { SelectZones } from './SelectZones';
import { useSelector, useDispatch } from 'react-redux';
import { downloadDataList, downloadAllData } from '../../redux/actions/admin/clients';

import { Scrollbar } from '../Scrollbar';

const SliderStyled = styled(Slider)(({ theme }) => ({
	'& .MuiSlider-mark': {
		height: '10px',
		width: '10px',
		borderRadius: '50%',
		color: 'red',
		backgroundColor: theme.palette.primary.main,
		opacity: '1.0',
	},
}));

const marks = [
	{
		value: 0,
		label: '1.000',
		cantidad: 1000,
	},
	{
		value: 20,
		label: '10.000',
		cantidad: 10000,
	},
	{
		value: 40,
		label: '20.000',
		cantidad: 20000,
	},
	{
		value: 60,
		label: '30.000',
		cantidad: 30000,
	},
	{
		value: 80,
		label: '40.000',
		cantidad: 40000,
	},
	{
		value: 100,
		label: 'TODOS',
		cantidad: 100000,
	},
];

const originData = [
	{
		id: 'whatsapp',
		name: 'Whatsapp',
	},
	{
		id: 'facebook',
		name: 'Facebook',
	},
	{
		id: 'organic',
		name: 'Organico',
	},
	{
		id: 'instagram',
		name: 'Instagram',
	},
	{
		id: 'web',
		name: 'Formulario web',
	},
	{
		id: 'noEspecificado',
		name: 'Sin especificar',
	},
];

function valuetext(value) {
	return `${value}`;
}

function valueLabelFormat(value) {
	return marks.findIndex(mark => mark.value === value) + 1;
}

export const ModalExportLClients = ({ open, setOpen }) => {
	const dispatch = useDispatch();

	const asesores = useSelector(state => state.allClients.advisors);
	const zonas = useSelector(state => state.zones.zones);

	const [isLoading, setIsLoading] = useState(false);
	const [zones, setZones] = useState([]);
	const [cant, setCant] = useState(1000);
	const [advisors, setAdvisors] = useState([]);
	const [origins, setOrigins] = useState([]);

	const handleClose = () => {
		setOpen(false);
		setIsLoading(false);
	};

	const handlePropSubmit = async () => {
		setIsLoading(true);
		await dispatch(
			downloadDataList({ cantidad: cant, asesores: advisors, origen: origins, zonas: zones })
		);
		setIsLoading(false);
		setZones([]);
		setCant(1000);
		setAdvisors([]);
		setOrigins([]);
		setOpen(false);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		await downloadAllData();
		setIsLoading(false);
		setOpen(false);
	};

	useEffect(() => {
		setCant(1000);
	}, []);

	return (
		<Scrollbar>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth={true}
				maxWidth={'sm'}
				sx={{ '& .MuiDialog-paper': { backgroundColor: '#F3F3F3' } }}
			>
				<DialogTitle
					sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
				>
					Elegir datos para descargar
					<IconButton aria-label='Cerrar' onClick={handleClose}>
						<CloseIcon color='primary' />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box sx={{ mb: 5, pr: 6 }}>
						<Typography variant='subtitle1'>Cantidad de datos</Typography>
						<Typography variant='caption' sx={{ fontStyle: 'italic', color: 'grey' }}>
							Opcional
						</Typography>
						<Box sx={{ pl: 6, mt: 3, pr: 3 }}>
							<SliderStyled
								aria-label='Restricted values'
								defaultValue={1}
								onChange={e => setCant(e.target.cantidad)}
								valueLabelFormat={valueLabelFormat}
								getAriaValueText={valuetext}
								step={null}
								valueLabelDisplay='off'
								marks={marks}
							/>
						</Box>
					</Box>
					<Box sx={{ mb: 6 }}>
						<Typography variant='subtitle1'>Zonas</Typography>
						<SelectZones zones={zonas} selected={zones} setSelected={setZones} />
					</Box>
					<Box sx={{ mb: 6 }}>
						<Typography variant='subtitle1'>Asesores</Typography>
						<SelectZones zones={asesores} selected={advisors} setSelected={setAdvisors} />
					</Box>
					<Box sx={{ mb: 6 }}>
						<Typography variant='subtitle1'>Datos</Typography>
						<SelectZones zones={originData} selected={origins} setSelected={setOrigins} />
					</Box>
				</DialogContent>
				<DialogActions>
					<LoadingButton loading={isLoading} variant='contained' onClick={handlePropSubmit}>
						Descargar
					</LoadingButton>
					<LoadingButton loading={isLoading} variant='text' onClick={handleSubmit}>
						Descargar todo
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</Scrollbar>
	);
};
