import {
	GET_ALL_DEVELOPMENTS_CONFIG,
	CHANGE_LOADER_CONFIG,
} from '../../actions/admin/developments';

const initialState = {
	developments: [],
	loading: false,
};

export const developmentsConfig = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_DEVELOPMENTS_CONFIG:
			return {
				...state,
				developments: Object.keys(payload).length === 0 ? [] : payload,
				loading: false,
			};
		case CHANGE_LOADER_CONFIG:
			return {
				...state,
				loading: true,
			};

		default:
			return state;
	}
};
