import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_AVAILABLE_LOTS = 'GET_AVAILABLE_LOTS';
export const CHANGE_LOADER_LOTS = 'CHANGE_LOADER_LOTS';
export const GET_RESUME_DEVELOPMENTS_LOTS = 'GET_RESUME_DEVELOPMENTS';
export const ASSIGN_LOT_TO_CLIENT = 'ASSIGN_LOT_TO_CLIENT';

export const getDevelopmentsAssignLot = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_LOTS });
		const response = await authAxios(`${URL_API}/client/developments/resume`, 'get');
		return dispatch({
			type: GET_RESUME_DEVELOPMENTS_LOTS,
			payload: response,
		});
	};
};

export const assignLotToClient = data => {
	return async function (dispatch) {
		dispatch({ type: ASSIGN_LOT_TO_CLIENT });
		await authAxios(`${URL_API}/client/lots/assign`, 'post', null, data);
	};
};
