import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableFooter,
	TablePagination,
	TableSortLabel,
	Typography,
	CircularProgress,
	Tooltip,
	Checkbox,
	useMediaQuery,
} from '@mui/material';
import { formatDate } from '../../utils/formatDate';
import { Scrollbar } from '../Scrollbar/Scrollbar';
import {
	changeOrderUpdated,
	changeOrderCreated,
	changeRowsPerPage,
	changePage,
} from '../../redux/actions/admin/clients';
import { useDispatch, useSelector } from 'react-redux';

export const ClientsList = ({
	itemClients = [],
	selectAll,
	setSelectAll,
	handleUnselect,
	handleSelect,
	selected,
	setSelected,
}) => {
	const dispatch = useDispatch();
	const smallScreen = useMediaQuery('(max-width:1230px)');

	const [updatedAt, setUpdatedAt] = useState(false); // Filtra por orden según última actualización
	const [createdAt, setCreatedAt] = useState(false); // Filtra por orden según fecha de registro

	const order = useSelector(state => state.allClients.order);
	const rowsPerPage = useSelector(state => state.allClients.rowsPerPage);
	const page = useSelector(state => state.allClients.page);
	const countClients = useSelector(state => state.allClients.countClients);
	const isLoading = useSelector(state => state.allClients.loading);

	const handleUpdatedAt = () => {
		setUpdatedAt(!updatedAt);
		setCreatedAt(false);
		let newOrder = order === 'desc' ? 'asc' : 'desc';
		dispatch(changeOrderUpdated(newOrder));
	};

	const handleCreatedAt = () => {
		setCreatedAt(!createdAt);
		setUpdatedAt(false);
		let newOrder = order === 'desc' ? 'asc' : 'desc';
		dispatch(changeOrderCreated(newOrder));
	};

	const handleChangeRowsPerPage = e => {
		dispatch(changeRowsPerPage(e));
	};

	const handleChangePage = (event, newPage) => {
		dispatch(changePage(newPage));
		window.scrollTo({ behavior: 'smooth', top: 'top' });
	};

	const handleSelectAll = () => {
		setSelectAll(true);
		let selectedAll = itemClients.map(e => e.id);
		setSelected(selectedAll);
	};

	const handleUnSelectAll = () => {
		setSelectAll(false);
		setSelected([]);
	};

	return (
		<>
			{isLoading ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
					<CircularProgress />
				</div>
			) : itemClients.length === 0 ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
					<Typography variant='body1'>
						No se han encontrado resultados según los parametros de búsqueda.
					</Typography>
				</div>
			) : (
				<Card>
					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>
											<Checkbox
												checked={selectAll}
												onChange={event => {
													if (event.target.checked) {
														handleSelectAll();
													} else {
														handleUnSelectAll();
													}
												}}
											/>
										</TableCell>
										<TableCell align='left' width={170}>
											Nombre y apellido
										</TableCell>
										<TableCell align='left'>Telefono</TableCell>
										<TableCell align='left'>Asesor</TableCell>
										<TableCell align='left'>Última nota</TableCell>
										<TableCell align='right' width={220}>
											<TableSortLabel
												active={updatedAt}
												direction={order}
												onClick={handleUpdatedAt}
											>
												Última actualización
											</TableSortLabel>
										</TableCell>
										{!smallScreen ? (
											<TableCell align='right' width={190}>
												<TableSortLabel
													active={createdAt}
													direction={order}
													onClick={handleCreatedAt}
												>
													Fecha de registro
												</TableSortLabel>
											</TableCell>
										) : (
											<></>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{itemClients.map(e => {
										if (e.Contact === null) return null;
										else
											return (
												<TableRow hover key={e.id}>
													<TableCell padding='checkbox'>
														<Checkbox
															checked={selected.includes(e.id)}
															onChange={event => {
																if (event.target.checked) {
																	handleSelect(e.id);
																} else {
																	handleUnselect(e.id);
																}
															}}
														/>
													</TableCell>
													<TableCell align='left'>
														<Link
															to={`/contacto/${e.id}`}
															style={{ textDecoration: 'none', color: 'inherit' }}
														>
															<Typography variant='subtitle2' sx={{ cursor: 'pointer' }}>
																{e.name} {e.lastName ? e.lastName : null}
															</Typography>
														</Link>
													</TableCell>
													<TableCell align='left'>
														<Tooltip title='Ir a whatsapp' arrow>
															<a
																style={{ textDecoration: 'none' }}
																target='_blank'
																href={`https://wa.me/${e.cellphone}`}
																rel='noopener noreferrer'
															>
																{e.cellphone}
															</a>
														</Tooltip>
													</TableCell>
													<TableCell>
														{e.User?.fullName ? e.User?.fullName : 'Sin asesor'}
													</TableCell>

													<TableCell sx={{ maxWidth: '500px !important' }}>{e.lastNote}</TableCell>

													<TableCell align='right' sx={{ width: smallScreen ? 150 : null }}>
														{e.updatedAt ? formatDate(e.updatedAt) : 'ERROR'}
													</TableCell>

													{!smallScreen ? (
														<TableCell align='right'>
															{e.createdAt ? formatDate(e.createdAt) : 'ERROR'}
														</TableCell>
													) : (
														<></>
													)}
												</TableRow>
											);
									})}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TablePagination
											page={page}
											count={countClients}
											rowsPerPage={rowsPerPage}
											onPageChange={handleChangePage}
											rowsPerPageOptions={[25, 50, 100]}
											labelRowsPerPage='Mostrar por pagina: '
											onRowsPerPageChange={e => handleChangeRowsPerPage(e.target.value)}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</Scrollbar>
				</Card>
			)}
		</>
	);
};
