import React, { useState } from 'react';
import { useFormik } from 'formik';
import { ListSearchbar } from './ListSearchbar';
import { ToastContainer, toast } from 'react-toastify';
import {
	Card,
	InputAdornment,
	OutlinedInput,
	SvgIcon,
	Select,
	MenuItem,
	IconButton,
	Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';

import 'react-toastify/dist/ReactToastify.css';
import ReplayIcon from '@mui/icons-material/Replay';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';

import { useDispatch } from 'react-redux';
import { resetFilters, search } from '../../redux/actions/user/oportunidades';

export const ListSearch = ({ totalStates, zones }) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const handleResetFilters = () => {
		formik.resetForm();
		dispatch(resetFilters());
	};

	const formik = useFormik({
		initialValues: initialValues(),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async formValue => {
			try {
				setIsLoading(true);
				if (formValue.zone !== 0 && formValue.search.length > 1) {
					await dispatch(search(`zone=${formValue.zone}&cellphone=${formValue.search}`));
					setIsLoading(false);
					return;
				}
				if (formValue.zone !== 0) {
					await dispatch(search(`zone=${formValue.zone}`));
					setIsLoading(false);
					return;
				}
				if (formValue.search.length > 1) {
					await dispatch(search(`cellphone=${formValue.search}`));
					setIsLoading(false);
					return;
				} else {
					setIsLoading(false);
					return toast.error('Ingrese un filtro.', { theme: 'colored' });
				}
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		},
	});

	return (
		<>
			<ListSearchbar totalStates={totalStates} />
			<Card sx={{ p: 2, mt: '5px !important' }}>
				<form
					onSubmit={formik.handleSubmit}
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<span>
						<OutlinedInput
							fullWidth
							placeholder='Buscar...'
							name='search'
							value={formik.values.search.trim()}
							onChange={formik.handleChange}
							error={!!formik.errors.search}
							startAdornment={
								<InputAdornment position='start'>
									<SvgIcon color='action' fontSize='small'>
										<MagnifyingGlassIcon />
									</SvgIcon>
								</InputAdornment>
							}
							sx={{ width: 270 }}
						/>
						<Select
							value={formik.values.zone}
							onChange={formik.handleChange}
							input={<OutlinedInput />}
							sx={{ width: 250, maxWidth: 250, ml: 1, height: '92%' }}
							name='zone'
							placeholder='Zona...'
						>
							<MenuItem disabled value={0}>
								<em>Zonas...</em>
							</MenuItem>
							{zones.map(e => (
								<MenuItem key={e.id} value={e.id}>
									{e.description}
								</MenuItem>
							))}
						</Select>
					</span>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Tooltip title='Reiniciar'>
							<IconButton
								aria-label='Resetear lista'
								sx={{ ml: '10px' }}
								onClick={handleResetFilters}
							>
								<ReplayIcon />
							</IconButton>
						</Tooltip>
						<LoadingButton
							type='submit'
							aria-label='Buscar'
							loading={isLoading}
							sx={{ ml: '10px' }}
							variant='contained'
						>
							Buscar
						</LoadingButton>
					</div>
				</form>
			</Card>
			<ToastContainer />
		</>
	);
};

function validationSchema() {
	return Yup.object({
		search: Yup.string(),
		zone: Yup.number(),
	});
}

function initialValues() {
	return {
		search: '',
		zone: 0,
	};
}
