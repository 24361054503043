import {
	GET_ALL_ME_CLIENTS,
	GET_TOTAL_ME_CLIENTS,
	CHANGE_ORDER_CLIENTS_UPDATED,
	CHANGE_ORDER_CLIENTS_CREATED,
	CHANGE_ROWS_PER_PAGE,
	CHANGE_PAGE,
	CHANGE_STATE,
	RESET_FILTERS,
	ADD_SEARCH_QUERY,
	CHANGE_LOADER,
} from '../../actions/user/oportunidades';

const initialState = {
	allClients: [],
	page: 0,
	status: 1,
	total: [],
	order: 'desc',
	typeOrder: 'updatedAt',
	rowsPerPage: 25,
	querys: '',
	countClients: 0,
	loading: false,
};

export const clientsAdvisor = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_ME_CLIENTS:
			return {
				...state,
				allClients: payload,
				loading: false,
			};
		case GET_TOTAL_ME_CLIENTS:
			return {
				...state,
				total: payload,
				countClients: payload.sinContactar,
			};
		case CHANGE_ORDER_CLIENTS_UPDATED:
			return {
				...state,
				order: payload,
				typeOrder: 'updatedAt',
			};
		case CHANGE_ORDER_CLIENTS_CREATED:
			return {
				...state,
				order: payload,
				typeOrder: 'createdAt',
			};
		case CHANGE_ROWS_PER_PAGE:
			return {
				...state,
				rowsPerPage: payload,
			};
		case CHANGE_PAGE:
			return {
				...state,
				page: payload,
			};
		case CHANGE_STATE:
			return {
				...state,
				status: payload,
				countClients:
					payload === 1
						? state.total.sinContactar
						: payload === 2
						? state.total.telefonico
						: payload === 3
						? state.total.presencial
						: payload === 4
						? state.total.alta
						: state.total.baja,
			};
		case RESET_FILTERS:
			return {
				...state,
				page: 0,
				status: 1,
				order: 'desc',
				rowsPerPage: 25,
				querys: '',
				countClients: state.total.sinContactar,
			};
		case ADD_SEARCH_QUERY:
			return {
				...state,
				querys: payload,
			};
		case CHANGE_LOADER:
			return {
				...state,
				loading: true,
			};

		default:
			return state;
	}
};
