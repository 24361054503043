import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	CircularProgress,
	Button,
	SvgIcon,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	ContactProfile,
	ContactProfileDetails,
	ClientProfileHistory,
} from '../components/ContactoPerfil';
import { WarningDialog } from '../components/Dialogs';
import { ModalAsignLot, ModalAsignVisit } from '../components/Modals';
import { useAuth } from '../hooks/useAuth';

import { useSelector } from 'react-redux';

export const ContactoPerfil = () => {
	const [isDirty, setIsDirty] = useState(false);
	const [warning, setWarning] = useState(false);
	const [asignLot, setAsignLot] = useState(false);
	const [asignVisit, setAsignVisit] = useState(false);

	const { user } = useAuth();
	const isLoading = useSelector(state => state.clientDetail.loading);
	const dataClient = useSelector(state => state.clientDetail.data);

	useEffect(() => {
		function handleBeforeUnload(event) {
			if (isDirty) {
				event.preventDefault();
				event.returnValue = '';
				setWarning(true);
			}
		}

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isDirty]);

	const handleGoBack = () => {
		if (isDirty) setWarning(true);
		else window.history.back();
	};

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='lg'>
					<Button
						onClick={() => handleGoBack()}
						variant='contained'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '15px',
							mb: '20px',
						}}
					>
						<SvgIcon>
							<ArrowBackIcon />
						</SvgIcon>
						Volver atrás
					</Button>
					{isLoading ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								height: '150px',
								alignItems: 'center',
							}}
						>
							<CircularProgress />
						</div>
					) : dataClient.User.id !== user.id && user.roleId !== 1 ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '250px',
							}}
						>
							<Typography variant='h6'>
								Este contacto pertenece a: {dataClient.User.fullName}
							</Typography>
						</div>
					) : (
						<Stack spacing={3}>
							<div>
								<Typography variant='h4'>Contacto</Typography>
							</div>
							<div>
								<Grid container spacing={3}>
									<Grid xs={12} md={6} lg={4}>
										<ContactProfile dataClient={dataClient} />
									</Grid>
									<Grid xs={12} md={6} lg={8}>
										<ContactProfileDetails
											user={dataClient}
											isDirty={setIsDirty}
											isLoading={isLoading}
											setAsignLot={setAsignLot}
											setAsignVisit={setAsignVisit}
										/>
									</Grid>
								</Grid>
								<ClientProfileHistory id={dataClient.id} />
							</div>
						</Stack>
					)}
				</Container>
			</Box>
			<WarningDialog open={warning} setOpen={setWarning} />
			{/* <ModalAsignVisit open={asignVisit} setOpen={setAsignVisit} /> */}
			<ModalAsignLot dataClient={dataClient} open={asignLot} setOpen={setAsignLot} />
		</>
	);
};
