import { styled } from '@mui/material/styles';
import { SideNav } from './sideNav';
import { TopNav } from './topNav';
// import { usePathname } from "next/navigation";
import { useAuth } from '../../hooks/useAuth';
import { useNavigate, Outlet } from 'react-router-dom';
// import { useRouter } from "next/router";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: '1 1 auto',
	maxWidth: '100%',
	[theme.breakpoints.up('xl')]: {
		paddingLeft: SIDE_NAV_WIDTH,
	},
	[theme.breakpoints.up('lg')]: {
		paddingLeft: 210,
	},
}));

const LayoutContainer = styled('div')({
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'column',
	width: '100%',
});

export const LayoutDashboard = () => {
	const navigate = useNavigate();

	const { user } = useAuth();

	if (!user) {
		navigate('/login');
		return null;
	}

	return (
		<>
			<TopNav />
			<SideNav user={user} />
			<LayoutRoot>
				<LayoutContainer>
					<Outlet />
				</LayoutContainer>
			</LayoutRoot>
		</>
	);
};
