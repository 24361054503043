import React, { useState } from 'react';
import style from './PopupLote.module.scss';

export default function PopupLote({ lote, deleteLote, changeNumberLote }) {
	const [numLote, setNumLote] = useState(lote);

	const changeNumber = e => {
		setNumLote(e);
	};

	const saveChanges = () => {
		changeNumberLote(numLote, lote);
	};

	return (
		<div className={style.container}>
			<div>
				<span className={style.container__btnClose} onClick={() => deleteLote(lote)}>
					Eliminar
				</span>
			</div>
			<input value={numLote} onChange={e => changeNumber(e.target.value)} />
			<span className={style.container__btnClose} onClick={() => saveChanges()}>
				Guardar
			</span>
		</div>
	);
}
