import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_ALL_DEVELOPMENTS_CONFIG = 'GET_ALL_DEVELOPMENTS_CONFIG';
export const CHANGE_LOADER_CONFIG = 'CHANGE_LOADER_CONFIG';
export const DELETE_DEVELOPMENT = 'DELETE_DEVELOPMENT';

export const getAllDevelpomentsConfig = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_CONFIG });
		const response = await authAxios(`${URL_API}/client/developments`, 'get');
		return dispatch({
			type: GET_ALL_DEVELOPMENTS_CONFIG,
			payload: response,
		});
	};
};

export const createDevelopment = (headers, data) => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_CONFIG });
		await authAxios(`${URL_API}/admin/development/lot`, 'axios', headers, data);
		const response = await authAxios(`${URL_API}/client/developments`, 'get');
		return dispatch({
			type: GET_ALL_DEVELOPMENTS_CONFIG,
			payload: response,
		});
	};
};

export const deleteDevelopment = id => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_CONFIG });
		await authAxios(`${URL_API}/admin/development/${id}`, 'delete');
		const response = await authAxios(`${URL_API}/client/developments`, 'get');
		return dispatch({
			type: GET_ALL_DEVELOPMENTS_CONFIG,
			payload: response,
		});
	};
};
