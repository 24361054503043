import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, ImageOverlay, useMapEvents, Polygon, Popup } from 'react-leaflet';
import L, { CRS } from 'leaflet';
import ChangeDimensions from './ChangeDimensions';
import PopupLote from './PopupLote';

import { calculateBounds } from '../../utils/calculateBounds';

const redOptions = { color: 'green', strokeWidth: '0px' };

export const CreateInteractiveMap = ({ imageFile, lotes, setLotes }) => {
	const mapRef = useRef(null);
	const [imageBounds, setImageBounds] = useState(null);
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [widthMeters, setWidthMeters] = useState(20);
	const [polygons, setPolygons] = useState([]);
	const [lote, setLote] = useState(1);
	const [tempLote, setTempLote] = useState([]);
	const [lastLayerPoint, setLastLayerPoint] = useState(0);
	const [speed, setSpeed] = useState({ x: 0.2, y: 0.2 });

	// Formatear angulos poligono
	const [positive, setPositive] = useState(true);

	// Dimensiones container padre
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [forceKeyUpdate, setForceKeyUpdate] = useState(0);
	const [center, setCenter] = useState([0, 0]);

	const deleteLote = e => {
		const polygonDeleted = polygons.filter(x => x.lote !== e);
		setPolygons(polygonDeleted);
		setLotes(polygonDeleted);
	};

	const MapEvents = () => {
		useMapEvents({
			click: e => {
				setLastLayerPoint(e.layerPoint);
				setTempLote(calculateBounds(e.layerPoint, widthMeters, 120));
			},
			keypress: e => {
				if (e.originalEvent.key === '.') {
					setPositive(!positive);
				}
				if (e.originalEvent.code === 'Digit1') {
					setSpeed({ x: speed.x + 0.2, y: speed.y + 0.2 });
				}
				if (e.originalEvent.code === 'Digit1') {
					setSpeed({ x: speed.x + 0.2, y: speed.y + 0.2 });
				}

				if (e.originalEvent.code === 'Digit2') {
					setSpeed({ x: 0.2, y: 0.2 });
				}

				if (e.originalEvent.key === 'Enter') {
					if (lastLayerPoint === 0) return;

					setLote(Number(lote) + 1);
					setPolygons([...polygons, { lote: lote, coordinates: [...tempLote] }]);

					setTempLote([
						[tempLote[0][0], tempLote[0][1]],
						[tempLote[1][0], tempLote[1][1]],
						[tempLote[2][0], tempLote[2][1]],
						[tempLote[3][0], tempLote[3][1]],
					]);
					setLotes([...lotes, { lote: lote, layerPoint: tempLote }]);
				}

				if (e.originalEvent.key === 'd') {
					if (lastLayerPoint === 0) return;

					setWidthMeters(widthMeters + 0.5);

					if (positive) {
						setTempLote([
							[tempLote[0][0], tempLote[0][1]],
							[tempLote[1][0] + 0.5, tempLote[1][1]],
							[tempLote[2][0] + 0.5, tempLote[2][1]],
							[tempLote[3][0], tempLote[3][1]],
						]);
					} else {
						setTempLote([
							[tempLote[0][0], tempLote[0][1]],
							[tempLote[1][0] - 0.5, tempLote[1][1]],
							[tempLote[2][0] - 0.5, tempLote[2][1]],
							[tempLote[3][0], tempLote[3][1]],
						]);
					}
				}

				if (e.originalEvent.key === 'a') {
					if (lastLayerPoint === 0) return;

					if (positive) {
						setTempLote([
							[tempLote[0][0] - 0.5, tempLote[0][1]],
							[tempLote[1][0], tempLote[1][1]],
							[tempLote[2][0], tempLote[2][1]],
							[tempLote[3][0] - 0.5, tempLote[3][1]],
						]);
					} else {
						setTempLote([
							[tempLote[0][0] + 0.5, tempLote[0][1]],
							[tempLote[1][0], tempLote[1][1]],
							[tempLote[2][0], tempLote[2][1]],
							[tempLote[3][0] + 0.5, tempLote[3][1]],
						]);
					}
				}

				if (e.originalEvent.key === 'w') {
					if (lastLayerPoint === 0) return;

					if (positive) {
						setTempLote([
							[tempLote[0][0], tempLote[0][1] + 0.5],
							[tempLote[1][0], tempLote[1][1]],
							[tempLote[2][0], tempLote[2][1]],
							[tempLote[3][0], tempLote[3][1]],
						]);
					} else {
						setTempLote([
							[tempLote[0][0], tempLote[0][1] - 0.57],
							[tempLote[1][0], tempLote[1][1]],
							[tempLote[2][0], tempLote[2][1]],
							[tempLote[3][0], tempLote[3][1]],
						]);
					}
				}

				if (e.originalEvent.key === 's') {
					if (lastLayerPoint === 0) return;

					if (positive) {
						setTempLote([
							[tempLote[0][0], tempLote[0][1]],
							[tempLote[1][0], tempLote[1][1]],
							[tempLote[2][0], tempLote[2][1]],
							[tempLote[3][0], tempLote[3][1] - 0.5],
						]);
					} else {
						setTempLote([
							[tempLote[0][0], tempLote[0][1]],
							[tempLote[1][0], tempLote[1][1]],
							[tempLote[2][0], tempLote[2][1]],
							[tempLote[3][0], tempLote[3][1] + 0.5],
						]);
					}
				}

				if (e.originalEvent.key === 'f') {
					if (lastLayerPoint === 0) return;

					if (positive) {
						setTempLote([
							[tempLote[0][0], tempLote[0][1]],
							[tempLote[1][0], tempLote[1][1]],
							[tempLote[2][0], tempLote[2][1] - 0.5],
							[tempLote[3][0], tempLote[3][1] - 0.5],
						]);
					} else {
						setTempLote([
							[tempLote[0][0], tempLote[0][1]],
							[tempLote[1][0], tempLote[1][1]],
							[tempLote[2][0], tempLote[2][1] + 0.5],
							[tempLote[3][0], tempLote[3][1] + 0.5],
						]);
					}
				}

				if (e.originalEvent.key === 'q') {
					if (lastLayerPoint === 0) return;

					let verticeX = tempLote[0];
					if (positive) verticeX = [verticeX[0] + 1, verticeX[1]];
					else verticeX = [verticeX[0] - 1, verticeX[1]];
					setTempLote([verticeX, tempLote[1], tempLote[2], tempLote[3]]);
				}

				if (e.originalEvent.key === 'e') {
					if (lastLayerPoint === 0) return;

					let verticeX = tempLote[1];
					if (positive) verticeX = [verticeX[0] + 1, verticeX[1]];
					else verticeX = [verticeX[0] - 1, verticeX[1]];
					setTempLote([tempLote[0], verticeX, tempLote[2], tempLote[3]]);
				}

				if (e.originalEvent.key === '8') {
					if (lastLayerPoint === 0) return;

					setLastLayerPoint({ ...lastLayerPoint, y: lastLayerPoint.y - speed.y });
					setTempLote([
						[tempLote[0][0], tempLote[0][1] - speed.y],
						[tempLote[1][0], tempLote[1][1] - speed.y],
						[tempLote[2][0], tempLote[2][1] - speed.y],
						[tempLote[3][0], tempLote[3][1] - speed.y],
					]);
				}

				if (e.originalEvent.key === '2') {
					if (lastLayerPoint === 0) return;

					setLastLayerPoint({ ...lastLayerPoint, y: lastLayerPoint.y + speed.y });
					setTempLote([
						[tempLote[0][0], tempLote[0][1] + speed.y],
						[tempLote[1][0], tempLote[1][1] + speed.y],
						[tempLote[2][0], tempLote[2][1] + speed.y],
						[tempLote[3][0], tempLote[3][1] + speed.y],
					]);
				}

				if (e.originalEvent.key === '6') {
					if (lastLayerPoint === 0) return;

					setLastLayerPoint({ ...lastLayerPoint, x: lastLayerPoint.x + speed.x });

					setTempLote([
						[tempLote[0][0] + speed.x, tempLote[0][1]],
						[tempLote[1][0] + speed.x, tempLote[1][1]],
						[tempLote[2][0] + speed.x, tempLote[2][1]],
						[tempLote[3][0] + speed.x, tempLote[3][1]],
					]);
				}

				if (e.originalEvent.key === '4') {
					if (lastLayerPoint === 0) return;

					setLastLayerPoint({ ...lastLayerPoint, x: lastLayerPoint.x - speed.x });

					setTempLote([
						[tempLote[0][0] - speed.x, tempLote[0][1]],
						[tempLote[1][0] - speed.x, tempLote[1][1]],
						[tempLote[2][0] - speed.x, tempLote[2][1]],
						[tempLote[3][0] - speed.x, tempLote[3][1]],
					]);
				}
			},
			mouseout: () => {
				setLastLayerPoint(0);
				setTempLote([]);
			},
		});
		return null;
	};

	const changeNumberLote = (newNum, prevNum) => {
		let polygonDeleted = polygons.filter(x => Number(x.lote) !== Number(newNum));

		const polygonUpdated = polygonDeleted.map(e => {
			if (Number(e.lote) === Number(prevNum)) return { ...e, lote: Number(newNum) };
			else return e;
		});

		setPolygons(polygonUpdated);
		setLotes(polygonUpdated);
	};

	useEffect(() => {
		const image = new Image();
		image.src = URL.createObjectURL(imageFile);

		image.onload = function () {
			const imageWidth = image.width;
			const imageHeight = image.height;

			const southWest = mapRef.current.unproject([0, imageHeight], mapRef.current.getMaxZoom());
			const northEast = mapRef.current.unproject([imageWidth, 0], mapRef.current.getMaxZoom());

			const centerLat = (southWest.lat + northEast.lat) / 2;
			const centerLng = (southWest.lng + northEast.lng) / 2;

			setImageBounds(L.latLngBounds(southWest, northEast));
			setCenter([centerLat, centerLng]);

			setBackgroundImage(image);
			setForceKeyUpdate(prevKey => prevKey + 1);
		};

		return () => {
			image.onload = null;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const image = new Image();
		image.src = URL.createObjectURL(imageFile);

		image.onload = function () {
			const imageWidth = image.width;
			const imageHeight = image.height;

			const southWest = mapRef.current.unproject([0, imageHeight], mapRef.current.getMaxZoom());
			const northEast = mapRef.current.unproject([imageWidth, 0], mapRef.current.getMaxZoom());

			const centerLat = (southWest.lat + northEast.lat) / 2;
			const centerLng = (southWest.lng + northEast.lng) / 2;

			setImageBounds(L.latLngBounds(southWest, northEast));
			setCenter([centerLat, centerLng]);

			setImageBounds(L.latLngBounds(southWest, northEast));
			setBackgroundImage(image);
			setForceKeyUpdate(prevKey => prevKey + 1);
		};

		return () => {
			image.onload = null;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageFile]);

	useEffect(() => {
		if (backgroundImage === null) return;
		const imageWidth = backgroundImage.width;
		const imageHeight = backgroundImage.height;

		setWidth(imageWidth);
		setHeight(imageHeight);

		setForceKeyUpdate(prevKey => prevKey + 1);
	}, [backgroundImage]);

	return (
		<>
			<ChangeDimensions lote={lote} setLote={setLote} />
			<span style={{ display: 'flex', justifyContent: 'center' }}>
				<MapContainer
					key={forceKeyUpdate}
					ref={mapRef}
					center={center}
					zoom={25}
					scrollWheelZoom={false}
					dragging={false}
					doubleClickZoom={false}
					zoomControl={false}
					tap={false}
					keyboardPanDelta={false}
					crs={CRS.Simple}
					style={{
						width: `${width}px`,
						height: `${height}px`,
						border: '1px solid #1b263b',
						borderTop: 'none',
						marginTop: '0px',
						borderRadius: '7px',
						borderTopLeftRadius: '0px',
						borderTopRightRadius: '0px',
						userSelect: 'none',
					}}
				>
					<TileLayer url='https://tile.openstreetmap.org/{z}/{x}/{y}.png' />
					<MapEvents />
					{imageBounds && <ImageOverlay url={backgroundImage.src} bounds={imageBounds} />}
					{polygons.map(e => {
						return (
							<Polygon
								stroke={0}
								key={e.lote}
								positions={e.coordinates.map(([x, y]) => mapRef.current.unproject([x, y]))}
								pathOptions={redOptions}
							>
								<Popup>
									<PopupLote
										lote={e.lote}
										deleteLote={deleteLote}
										changeNumberLote={changeNumberLote}
									/>
								</Popup>
							</Polygon>
						);
					})}
					<Polygon
						positions={tempLote.map(([x, y]) => mapRef.current.unproject([x, y]))}
						stroke={0}
						pathOptions={redOptions}
					/>
				</MapContainer>
			</span>
		</>
	);
};
