import CurrencyDollarIcon from '@heroicons/react/24/solid/CurrencyDollarIcon';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';

import { formatNumber } from '../../utils/formatNumberWithDots';

export const OverviewTotalProfit = ({ sx, target }) => {
	return (
		<Card sx={sx}>
			<CardContent>
				<Stack alignItems='flex-start' direction='row' justifyContent='space-between' spacing={3}>
					<Stack spacing={1}>
						<Typography color='text.secondary' variant='overline'>
							Objetivo
						</Typography>
						<Typography variant='h4'>
							${target ? formatNumber(Number(target.actualMonth?.target)) : 0}
						</Typography>
					</Stack>
					<Avatar
						sx={{
							backgroundColor: 'primary.main',
							height: 56,
							width: 56,
						}}
					>
						<SvgIcon>
							<CurrencyDollarIcon />
						</SvgIcon>
					</Avatar>
				</Stack>
			</CardContent>
		</Card>
	);
};
