import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { Box, Container, Stack, Unstable_Grid2 as Grid, Button, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { UserProfile, UserProfileDetails } from '../components/PerfilPersonal';

export const PerfilPersonal = () => {
	const { user } = useAuth();

	const handleGoBack = () => {
		window.history.back();
	};

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='lg'>
					<Button
						onClick={() => handleGoBack()}
						variant='contained'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '15px',
							mb: '20px',
						}}
					>
						<SvgIcon>
							<ArrowBackIcon />
						</SvgIcon>
						Volver
					</Button>

					<Stack spacing={3}>
						<div>
							<Grid container spacing={3}>
								<Grid xs={12} md={6} lg={4}>
									<UserProfile user={user} />
								</Grid>
								<Grid xs={12} md={6} lg={8}>
									<UserProfileDetails user={user} />
								</Grid>
							</Grid>
						</div>
					</Stack>
				</Container>
			</Box>
		</>
	);
};
