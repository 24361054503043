import React from 'react';
import { Box, SvgIcon, CircularProgress, Button, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../hooks/useAuth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { TemplateFicha } from '../components/Ficha';

import { useSelector } from 'react-redux';

export const Ficha = () => {
	// const router = useRouter();
	const params = useParams();
	const { user } = useAuth();
	const loading = useSelector(state => state.fichaData.loading);
	const data = useSelector(state => state.fichaData.data);

	return (
		<>
			<Container maxWidth='xl'>
				<Box
					sx={{
						width: '100%',
						height: '100vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						p: 3,
					}}
				>
					<Button
						onClick={() => window.history.back()}
						variant='contained'
						sx={{
							gap: '15px',
							mb: '20px',
							width: '70%',
						}}
					>
						<SvgIcon>
							<ArrowBackIcon />
						</SvgIcon>
						Volver
					</Button>
					{loading ? (
						<div
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'center',
								marginTop: '45px',
							}}
						>
							<CircularProgress />
						</div>
					) : (
						<TemplateFicha data={data} user={user} toast={toast} lote={params.lote} />
					)}
				</Box>
			</Container>
			<ToastContainer />
		</>
	);
};
