import * as React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { LoadingButton } from '@mui/lab';

export const WarningDialog = ({
	loading = false,
	open,
	setOpen,
	type = 'simpleWarning',
	innerText = 'Todavía no has guardado los últimos cambios que hiciste. ¿Estás seguro de que quieres continuar?',
	title = 'Cambios sin guardar',
	goBack = () => window.history.back(),
}) => {
	const handleClose = e => {
		setOpen(e);
	};

	let bgColor = '';
	if (type === 'simpleWarning') bgColor = 'warning.main';
	else if (type === 'importantWarning') bgColor = 'error.main';

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle
					id='alert-dialog-title'
					sx={{
						backgroundColor: bgColor,
						color: 'white',
						mb: '15px',
						display: 'flex',
						gap: '15px',
					}}
				>
					<WarningIcon />
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>{innerText}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<LoadingButton loading={loading} onClick={() => goBack()}>
						Continuar
					</LoadingButton>
					<Button onClick={() => handleClose(false)} autoFocus variant='contained'>
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
