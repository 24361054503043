import React, { useState } from 'react';
import {
	Box,
	Grid,
	List,
	Card,
	CardHeader,
	ListItem,
	ListItemText,
	ListItemIcon,
	Checkbox,
	Button,
	Divider,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { assignLotToClient } from '../../redux/actions/user/lots';
import { getLotsClient } from '../../redux/actions/client';

function not(a, b) {
	return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
	return [...a, ...not(b, a)];
}

export default function TransferList({ availableLot, handleClose, developmentId, dataClient }) {
	const dispatch = useDispatch();

	const availableLotSorted = availableLot.sort((a, b) => a - b);

	const [checked, setChecked] = useState([]);
	const [left, setLeft] = useState(availableLotSorted);
	const [right, setRight] = useState([]);

	const [loading, setLoading] = useState(false);

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const handleToggle = value => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const numberOfChecked = items => intersection(checked, items).length;

	const handleToggleAll = items => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items));
		} else {
			setChecked(union(checked, items));
		}
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleSubmit = async lotes => {
		try {
			setLoading(true);
			await dispatch(assignLotToClient({ userId: dataClient.id, developmentId, lots: lotes }));
			if (right.length !== 1) {
				toast.success('Lotes reservados', { theme: 'colored' });
			} else toast.success('Lote reservado', { theme: 'colored' });
			await dispatch(getLotsClient(dataClient));
			setLoading(false);

			handleClose();
		} catch (error) {
			return toast.error('Hubo un problema, intentalo de nuevo.', { theme: 'colored' });
		}
	};

	const customList = (title, items) => (
		<Card>
			<CardHeader
				sx={{ px: 2, py: 1 }}
				avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={numberOfChecked(items) === items.length && items.length !== 0}
						indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
						disabled={items.length === 0}
						inputProps={{
							'aria-label': 'all items selected',
						}}
					/>
				}
				title={title}
				subheader={`${numberOfChecked(items)}/${items.length} seleccionados`}
			/>
			<Divider />
			<List
				sx={{
					width: 300,
					height: 230,
					bgcolor: 'background.paper',
					overflow: 'auto',
				}}
				dense
				component='div'
				role='list'
			>
				{items.map(value => {
					const labelId = `transfer-list-all-item-${value}-label`;

					return (
						<ListItem key={value} role='listitem' button onClick={handleToggle(value)}>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={`Lote número ${value}`} />
						</ListItem>
					);
				})}
			</List>
		</Card>
	);

	return (
		<>
			<Grid container spacing={2} justifyContent='center' alignItems='center'>
				<Grid item>{customList('Lotes disponibles', left)}</Grid>
				<Grid item>
					<Grid container direction='column' alignItems='center'>
						<Button
							sx={{ my: 0.5 }}
							variant='outlined'
							size='small'
							onClick={handleCheckedRight}
							disabled={leftChecked.length === 0}
							aria-label='move selected right'
						>
							&gt;
						</Button>
						<Button
							sx={{ my: 0.5 }}
							variant='outlined'
							size='small'
							onClick={handleCheckedLeft}
							disabled={rightChecked.length === 0}
							aria-label='move selected left'
						>
							&lt;
						</Button>
					</Grid>
				</Grid>
				<Grid item>{customList('Lotes seleccionados', right)}</Grid>
			</Grid>
			<Box sx={{ display: 'flex', justifyContent: 'end' }}>
				<LoadingButton
					loading={loading}
					variant='contained'
					type='submit'
					sx={{ mt: 4, mr: 6 }}
					disabled={right.length === 0}
					onClick={() => handleSubmit(right)}
				>
					Asignar
				</LoadingButton>
			</Box>
		</>
	);
}
