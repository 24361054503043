import BellIcon from '@heroicons/react/24/solid/BellIcon';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Badge, Box, IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { usePopover } from '../../hooks/usePopover';
import { AccountPopover } from './accountPopover';
import { NotificationPopover } from './notificationPopover';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

export const TopNav = () => {
	const accountPopover = usePopover();
	const notificationPopover = usePopover();

	return (
		<>
			<Box
				component='header'
				sx={{
					backdropFilter: 'blur(6px)',
					backgroundColor: theme => alpha(theme.palette.background.default, 0.8),
					position: 'sticky',
					left: {
						xl: `${SIDE_NAV_WIDTH}px`,
						lg: `220px`,
					},
					top: 0,
					width: {
						xl: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
						lg: `calc(100% - 220px)`,
					},
					zIndex: theme => theme.zIndex.appBar,
				}}
			>
				<Stack
					alignItems='center'
					direction='row'
					justifyContent='space-between'
					spacing={2}
					sx={{
						minHeight: TOP_NAV_HEIGHT,
						px: 2,
					}}
				>
					<Stack alignItems='center' direction='row' spacing={2}></Stack>
					<Stack alignItems='center' direction='row' spacing={2}>
						{/* <Tooltip title='Notificaciones'>
							<IconButton
								onClick={notificationPopover.handleOpen}
								ref={notificationPopover.anchorRef}
							>
								<Badge badgeContent={4} color='success' variant='dot'>
									<SvgIcon fontSize='small'>
										<BellIcon />
									</SvgIcon>
								</Badge>
							</IconButton>
						</Tooltip> */}
						<Avatar
							onClick={accountPopover.handleOpen}
							ref={accountPopover.anchorRef}
							sx={{
								cursor: 'pointer',
								height: 40,
								width: 40,
							}}
						>
							<PersonIcon />
						</Avatar>
					</Stack>
				</Stack>
			</Box>
			<AccountPopover
				anchorEl={accountPopover.anchorRef.current}
				open={accountPopover.open}
				onClose={accountPopover.handleClose}
			/>
			{/* <NotificationPopover anchorEl={notificationPopover.anchorRef.current} open={notificationPopover.open} onClose={notificationPopover.handleClose} /> */}
		</>
	);
};
