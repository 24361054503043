import { GET_ALL_ADVISORS_ADMIN, CHANGE_LOADER_ADVISORS } from '../../actions/admin/advisors';

const initialState = {
	advisors: [],
	loading: false,
};

export const orderAdvisors = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_ADVISORS_ADMIN:
			return {
				...state,
				advisors: payload,
				loading: false,
			};

		case CHANGE_LOADER_ADVISORS:
			return {
				...state,
				loading: true,
			};

		default:
			return state;
	}
};
