import * as React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { deleteDevelopment } from '../../redux/actions/admin/developments';

export const WarningDeleteDevelopment = ({
	loading = false,
	open,
	setOpen,
	type = 'simpleWarning',
	innerText = '¿Estás seguro de que quieres eliminar este desarrollo?',
	title = 'Eliminar desarrollo',
	id,
}) => {
	const handleClose = e => {
		setOpen(e);
	};
	const dispatch = useDispatch();

	let bgColor = '';
	if (type === 'simpleWarning') bgColor = 'warning.main';
	else if (type === 'importantWarning') bgColor = 'error.main';

	const developmentDelete = () => {
		dispatch(deleteDevelopment(id));
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle
				id='alert-dialog-title'
				sx={{
					backgroundColor: bgColor,
					color: 'white',
					mb: '15px',
					display: 'flex',
					gap: '15px',
				}}
			>
				<WarningIcon />
				{title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>{innerText}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<LoadingButton loading={loading} onClick={() => developmentDelete()}>
					Continuar
				</LoadingButton>
				<Button onClick={() => handleClose(false)} autoFocus variant='contained'>
					Cancelar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
