import React from 'react';
import { Box, Card, Table, TableCell, TableHead, TableRow, CircularProgress } from '@mui/material';
import { Scrollbar } from '../Scrollbar';
import { ItemListDesarrollo } from './ItemListDesarrollo';

export const DesarrollosList = ({ items = [], loading }) => {
	return (
		<>
			{loading ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
					<CircularProgress />
				</div>
			) : (
				<Card>
					<Scrollbar>
						<Box>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell></TableCell>
										<TableCell>Desarrollo</TableCell>
										<TableCell>Valor</TableCell>
										<TableCell>Lotes</TableCell>
										<TableCell>Lotes disponibles</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								{items.map(e => {
									return <ItemListDesarrollo e={e} key={e.id} />;
								})}
							</Table>
						</Box>
					</Scrollbar>
				</Card>
			)}
		</>
	);
};
