import React, { useState, useRef, useEffect } from 'react';
import { OutlinedInput } from '@mui/material';
import { updateDataLot } from '../../redux/actions/user/developments';

export const ElementMeasures = ({ measure, data }) => {
	const [toggle, setToggle] = useState(false);
	const [value, setValue] = useState(measure);
	const inputRef = useRef(null);

	const handleChange = e => {
		setValue(e.target.value);
	};

	const handleBlur = () => {
		const lotUpdate = {
			...data,
			measures: value,
		};
		updateDataLot(lotUpdate);
		setToggle(false);
	};

	useEffect(() => {
		const handleClickOutside = e => {
			if (inputRef.current && !inputRef.current.contains(e.target)) {
				setToggle(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<>
			{toggle ? (
				<OutlinedInput
					type='text'
					value={value}
					onChange={handleChange}
					onBlur={handleBlur}
					ref={inputRef}
				/>
			) : (
				<p onDoubleClick={() => setToggle(true)}>{value}</p>
			)}
		</>
	);
};
