import { Box, Container, Stack, Card, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import ViewQuiltOutlined from '@mui/icons-material/ViewQuiltOutlined';
import TextureOutlinedIcon from '@mui/icons-material/TextureOutlined';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
// import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
// import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
// import ShowerIcon from '@mui/icons-material/Shower';
// import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useAuth } from '../hooks/useAuth';
import { Link } from 'react-router-dom';

export const Configuraciones = () => {
	const { user } = useAuth();

	if (user.roleId === 3) return;
	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='xl'>
					<Grid container spacing={3} gap={4}>
						<Grid
							xs={12}
							md={2}
							lg={1.5}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						>
							<Link to='/config/desarrollos'>
								<Card
									sx={{
										height: '100%',
										bgcolor: '#f36a6d',
										borderRadius: '4px',
										cursor: 'pointer',
										'&.MuiCard-root:hover': {
											boxShadow:
												'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;',
										},
									}}
								>
									<ViewQuiltOutlined sx={{ fontSize: '100px' }} htmlColor='white' />
								</Card>
							</Link>
							<Stack spacing={1} sx={{ mt: 1 }}>
								<Typography variant='body2'>Desarrollos</Typography>
							</Stack>
						</Grid>

						<Grid
							xs={12}
							md={2}
							lg={1.5}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						>
							<Link to='/zonas'>
								<Card
									sx={{
										height: '100%',
										bgcolor: '#52b788',
										borderRadius: '4px',
										cursor: 'pointer',
										'&.MuiCard-root:hover': {
											boxShadow:
												'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;',
										},
									}}
								>
									<TextureOutlinedIcon sx={{ fontSize: '100px' }} htmlColor='white' />
								</Card>
							</Link>
							<Stack spacing={1} sx={{ mt: 1 }}>
								<Typography variant='body2'>Zonas</Typography>
							</Stack>
						</Grid>

						<Grid
							xs={12}
							md={2}
							lg={1.5}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						>
							<Link to='/datos'>
								<Card
									sx={{
										height: '100%',
										bgcolor: '#457b9d',
										borderRadius: '4px',
										cursor: 'pointer',
										'&.MuiCard-root:hover': {
											boxShadow:
												'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;',
										},
									}}
								>
									<EqualizerIcon sx={{ fontSize: '100px' }} htmlColor='white' />
								</Card>
							</Link>
							<Stack spacing={1} sx={{ mt: 1 }}>
								<Typography variant='body2'>Datos</Typography>
							</Stack>
						</Grid>
						{/*
						 */}

						{/* <Grid
							xs={12}
							md={2}
							lg={1.5}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						>
							<Card
								sx={{
									height: '100%',
									bgcolor: '#f4a261',
									borderRadius: '4px',
									cursor: 'pointer',
									'&.MuiCard-root:hover': {
										boxShadow:
											'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;',
									},
								}}
							>
								<VolunteerActivismOutlinedIcon sx={{ fontSize: '100px' }} htmlColor='white' />
							</Card>
							<Stack spacing={1} sx={{ mt: 1 }}>
								<Typography variant='body2'>Referencias</Typography>
							</Stack>
						</Grid> */}

						{/* <Grid
							xs={12}
							md={2}
							lg={1.5}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						>
							<Link to='/asesores'>
								<Card
									sx={{
										height: '100%',
										bgcolor: '#5e548e',
										borderRadius: '4px',
										cursor: 'pointer',
										'&.MuiCard-root:hover': {
											boxShadow:
												'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;',
										},
									}}
								>
									<Diversity3Icon sx={{ fontSize: '100px' }} htmlColor='white' />
								</Card>
							</Link>
							<Stack spacing={1} sx={{ mt: 1 }}>
								<Typography variant='body2'>Lista asesores</Typography>
							</Stack>
						</Grid> */}

						<Grid
							xs={12}
							md={2}
							lg={1.5}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						>
							<Link to='/documentacion'>
								<Card
									sx={{
										height: '100%',
										bgcolor: '#56cfe1',
										borderRadius: '4px',
										cursor: 'pointer',
										'&.MuiCard-root:hover': {
											boxShadow:
												'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;',
										},
									}}
								>
									<AssignmentIcon sx={{ fontSize: '100px' }} htmlColor='white' />
								</Card>
							</Link>
							<Stack spacing={1} sx={{ mt: 1 }}>
								<Typography variant='body2'>Documentación</Typography>
							</Stack>
						</Grid>

						{/* <Grid
							xs={12}
							md={2}
							lg={1.5}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						>
							<Link to='/servicios'>
								<Card
									sx={{
										height: '100%',
										bgcolor: '#124e78',
										borderRadius: '4px',
										cursor: 'pointer',
										'&.MuiCard-root:hover': {
											boxShadow:
												'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;',
										},
									}}
								>
									<ShowerIcon sx={{ fontSize: '100px' }} htmlColor='white' />
								</Card>
							</Link>
							<Stack spacing={1} sx={{ mt: 1 }}>
								<Typography variant='body2'>Servicios</Typography>
							</Stack>
						</Grid> */}
					</Grid>
				</Container>
			</Box>
		</>
	);
};
