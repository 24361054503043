import React, { useState } from 'react';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	FormControl,
	Select,
	MenuItem,
	Unstable_Grid2 as Grid,
	Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../hooks/useAuth';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import { updateUser, deleteUser } from '../../redux/actions/admin/users';

const area = [
	{
		value: 1,
		label: 'Comercial',
	},
	{
		value: 2,
		label: 'Administración',
	},
	{
		value: 3,
		label: 'Contable',
	},
	{
		value: 4,
		label: 'Community Manager',
	},
	{
		value: 5,
		label: 'Marketing',
	},
];

const businessUnity = [
	{
		value: 1,
		label: 'Elemental',
	},
	{
		value: 2,
		label: 'Desarrollo',
	},
	{
		value: 3,
		label: 'Grupo Delsud',
	},
];

export const UserProfileDetails = ({ user, isDirty }) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [touch, setTouch] = useState(false);

	const userLogged = useAuth().user.id;

	const formik = useFormik({
		initialValues: initialValues(user),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async formValue => {
			try {
				if (touch === false) return toast.error('No has modificado nada!', { theme: 'colored' });
				if (formValue.password !== formValue.newPassword) {
					return toast.error('Las contraseñas no coinciden!', { theme: 'colored' });
				}
				let userUpdated = {
					fullName: formValue.fullName,
					roles: formValue.roles,
					email: formValue.email,
					area: formValue.area,
					system: formValue.system,
					password: formValue.password,
				};
				console.log(userUpdated);
				if (formik.isSubmitting) setIsLoading(true);
				dispatch(updateUser(user.id, userUpdated));
				setIsLoading(false);
				isDirty(false);
				setTouch(false);
				return toast.success('Usuario editado con éxito', { theme: 'colored' });
			} catch (error) {
				console.error(error);
				return toast.error('Ha habido un error, intentelo de nuevo.', { theme: 'colored' });
			}
		},
	});

	const handleDeleteUser = id => {
		dispatch(deleteUser(id));
		window.history.back();
	};

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<Card>
					<CardHeader
						subheader='Esta información puede ser editada'
						title='Perfil usuario'
						action={
							<FormControl sx={{ m: 1, minWidth: 250 }} size='small'>
								<Select
									labelId='demo-select-small'
									id='demo-select-small'
									name='roles'
									value={formik.values.roles}
									onChange={e => {
										formik.handleChange(e);
										isDirty(true);
										setTouch(true);
									}}
									error={formik.errors.roles}
									placeholder='Estado...'
									inputProps={{ 'aria-label': 'Without label' }}
									sx={{ 'div:nth-of-type(1)': { display: 'flex !important' } }}
								>
									<MenuItem value={1}>Administrador</MenuItem>
									<MenuItem value={2}>Moderador</MenuItem>
									<MenuItem value={3}>Usuario</MenuItem>
								</Select>
							</FormControl>
						}
					/>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Nombre'
										name='fullName'
										value={formik.values.fullName}
										onChange={e => {
											formik.handleChange(e);
											isDirty(true);
											setTouch(true);
										}}
										error={formik.errors.fullName}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Dirección de email'
										name='email'
										value={formik.values.email ? formik.values.email : ''}
										onChange={e => {
											formik.handleChange(e);
											isDirty(true);
											setTouch(true);
										}}
										error={formik.errors.email}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Nueva contraseña'
										name='password'
										value={formik.values.password}
										type='password'
										onChange={e => {
											formik.handleChange(e);
											isDirty(true);
											setTouch(true);
										}}
										error={formik.errors.password}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										type='password'
										label='Repetir nueva contraseña'
										name='newPassword'
										value={formik.values.newPassword}
										onChange={e => {
											formik.handleChange(e);
											isDirty(true);
											setTouch(true);
										}}
										error={formik.errors.newPassword}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Area'
										name='area'
										select
										SelectProps={{ native: true }}
										value={formik.values.area}
										onChange={e => {
											formik.handleChange(e);
											isDirty(true);
											setTouch(true);
										}}
										error={formik.errors.area}
									>
										{area.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Unidad de negocio'
										name='system'
										select
										SelectProps={{ native: true }}
										value={formik.values.system}
										onChange={e => {
											formik.handleChange(e);
											isDirty(true);
											setTouch(true);
										}}
										error={formik.errors.system}
									>
										{businessUnity.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />
					<CardActions sx={{ justifyContent: 'flex-end' }}>
						<LoadingButton variant='contained' type='submit' loading={isLoading}>
							Guardar
						</LoadingButton>
						<Button
							variant='contained'
							color='error'
							onClick={() => handleDeleteUser(user.id)}
							disabled={userLogged === user.id}
						>
							Eliminar
						</Button>
					</CardActions>
				</Card>
			</form>
			<ToastContainer />
		</>
	);
};

function initialValues(user) {
	return {
		fullName: user.fullName,
		roles: user.RoleId,
		email: user.email,
		area: user.AreaRolId,
		system: user.SystemId,
		password: '',
		newPassword: '',
	};
}

function validationSchema() {
	return Yup.object({
		fullName: Yup.string().required(true),
		roles: Yup.string().required(true),
		email: Yup.string().email(true).required(true),
		area: Yup.string().required(true),
		system: Yup.string().required(true),
		password: Yup.string(),
		newPassword: Yup.string(),
	});
}
