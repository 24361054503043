import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_HISTORY_TARGET = 'GET_HISTORY_TARGET';
export const CHANGE_LOADER_TARGET = 'CHANGE_LOADER_TARGET';

export const getHistoryTarget = year => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_TARGET });
		const response = await authAxios(`${URL_API}/admin/targets/month/${year}`, 'get');
		return dispatch({
			type: GET_HISTORY_TARGET,
			payload: response,
		});
	};
};

export const updateTarget = data => {
	return async function (dispatch) {
		const actualYear = new Date().getFullYear();
		await authAxios(`${URL_API}/admin/targets/month`, 'put', null, data);
		dispatch({ type: CHANGE_LOADER_TARGET });
		const response = await authAxios(`${URL_API}/admin/targets/month/${actualYear}`, 'get');
		return dispatch({
			type: GET_HISTORY_TARGET,
			payload: response,
		});
	};
};

export const createNewTarget = data => {
	return async function (dispatch) {
		const actualYear = new Date().getFullYear();
		await authAxios(`${URL_API}/admin/target/month`, 'post', null, data);
		dispatch({ type: CHANGE_LOADER_TARGET });
		const response = await authAxios(`${URL_API}/admin/targets/month/${actualYear}`, 'get');
		return dispatch({
			type: GET_HISTORY_TARGET,
			payload: response,
		});
	};
};
