import { Stack, IconButton, Tooltip } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PlusOneIcon from '@mui/icons-material/PlusOne';

export default function index({ cancelBooking, element, updateState }) {
	return (
		<Stack direction='row' spacing={1} justifyContent='center'>
			<Tooltip title='Extender reserva' placement='top'>
				<IconButton
					onClick={() => updateState(element, 'Reservado')}
					aria-label='Extender reserva'
					color='warning'
				>
					<PlusOneIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title='Cancelar reserva' placement='top'>
				<IconButton
					onClick={() => cancelBooking(element)}
					aria-label='Cancelar reserva'
					color='error'
				>
					<UnpublishedIcon />
				</IconButton>
			</Tooltip>
			<Tooltip
				onClick={() => updateState(element, 'Vendido')}
				title='Confirmar compra'
				placement='top'
			>
				<IconButton aria-label='Confirmar compra' color='success'>
					<VerifiedIcon />
				</IconButton>
			</Tooltip>
		</Stack>
	);
}
