import { GET_ALL_ZONES_ADMIN, CHANGE_LOADER_ZONE } from '../../actions/admin/zones';

const initialState = {
	zones: [],
	loading: false,
};

export const zonesAdmin = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_ZONES_ADMIN:
			return {
				...state,
				zones: payload,
				loading: false,
			};
		case CHANGE_LOADER_ZONE:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
};
