import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const CREATE_ZONE = 'CREATE_ZONE';
export const DELETE_ZONE = 'DELETE_ZONE';
export const GET_ALL_ZONES_ADMIN = 'GET_ALL_ZONES_ADMIN';
export const CHANGE_LOADER_ZONE = 'CHANGE_LOADER_ZONE';

export const getAllZones = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_ZONE });
		const response = await authAxios(`${URL_API}/client/zones`, 'get');
		return dispatch({
			type: GET_ALL_ZONES_ADMIN,
			payload: response,
		});
	};
};

export const createZone = data => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_ZONE });
		await authAxios(`${URL_API}/admin/zone`, 'post', null, data);
		const response = await authAxios(`${URL_API}/client/zones`, 'get');
		return dispatch({
			type: GET_ALL_ZONES_ADMIN,
			payload: response,
		});
	};
};

export const deleteZone = id => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_ZONE });
		await authAxios(`${URL_API}/admin/zone/${id}`, 'delete');
		const response = await authAxios(`${URL_API}/client/zones`, 'get');
		return dispatch({
			type: GET_ALL_ZONES_ADMIN,
			payload: response,
		});
	};
};
