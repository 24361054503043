import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_DATA_LOT = 'GET_DATA_LOT';
export const CHANGE_LOADER_FICHA = 'CHANGE_LOADER_FICHA';

export const getAllDataLot = (id, desarrollo) => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_FICHA });
		const response = await authAxios(`${URL_API}/client/reservar/lote/${id}/${desarrollo}`, 'get');
		return dispatch({
			type: GET_DATA_LOT,
			payload: response,
		});
	};
};

export const sendDataReserva = async (data, dataClient, lote, userIp = 0) => {
	try {
		const updateProfileClient = {
			name: data.nombreFirmante,
			lastName: data.apellidoFirmante,
			email: data.emailFirmante,
			cellphone: data.numeroCelularFirmante,
			address: data.direccionFirmante,
			numberId: data.numeroDocFirmante,
			locality: data.localidadFirmante,
			province: data.provinciaFirmante,
			birthdate: data.fechaNacFirmante,
			state: 4,
			description: `Reserva de lote: ${lote} del desarrollo ${dataClient.developmentData.name}`,
		};

		await authAxios(
			`${URL_API}/client/updateClient/${dataClient.clientData.id}`,
			'put',
			null,
			updateProfileClient
		);

		const response = await fetch(`${URL_API}/client/enviar/reserva`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify([data, dataClient.clientData, dataClient.developmentData, userIp]),
		});

		if (response.ok) {
			const blob = await response.blob();
			const url = URL.createObjectURL(blob);

			const a = document.createElement('a');
			a.href = url;
			a.download = `reserva-${data.nombreFirmante}-${data.apellidoFirmante}.pdf`;
			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			URL.revokeObjectURL(url);
			return response;
		} else {
			return 'Error al descargar/enviar la reserva';
		}
	} catch (error) {
		return 'Error al descargar/enviar la reserva';
	}
};
