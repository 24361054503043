import React from 'react';
import chevron from '../../assets/chevron.svg';
import style from './PopupLoteDesarrollo.module.scss';
import { Link } from 'react-router-dom';

export const PopupLoteDesarrollo = ({ contact }) => {
	return (
		<div className={style.container_popup_custom}>
			<div className={style.state_lot}>
				<div
					className={style.circle}
					style={{
						backgroundColor:
							contact.selled === 'Disponible'
								? '#11D30080'
								: contact.selled === 'Vendido'
								? '#E8000080'
								: '#FEDB2280',
					}}
				></div>
				{contact.selled}
			</div>
			<div className={style.container_client}>
				{contact.ContactId === null ? null : (
					<Link
						to={`/contacto/${contact.ContactId}`}
						style={{
							display: 'flex',
							width: '100%',
							color: 'inherit',
							textDecoration: 'none',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<p>{contact['Contact.name'] + ' ' + contact['Contact.lastName']}</p>
						<img src={chevron} alt='Ir al perfil del cliente' />
					</Link>
				)}
			</div>
			<div className={style.separador}></div>
			{contact.measures}
		</div>
	);
};
