import React, { useState } from 'react';
import {
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux';
import { changeStatus } from '../../redux/actions/admin/clients';

const options = [
	{
		value: 1,
		label: 'Sin contactar',
	},
	{
		value: 2,
		label: 'Asesoramiento telefónico',
	},
	{
		value: 3,
		label: 'Asesoramiento presencial',
	},
	{
		value: 4,
		label: 'Alta',
	},
	{
		value: 5,
		label: 'Baja',
	},
];

export const ChangeStatus = ({
	openChangeStatus,
	setOpenChangeStatus,
	selected,
	setSelected,
	setSelectAll,
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const handleCancel = () => {
		setLoading(false);
		setOpenChangeStatus(false);
	};

	const formik = useFormik({
		initialValues: { state: 1 },
		validationSchema: false,
		validateOnChange: false,
		onSubmit: async formValue => {
			try {
				let result = {
					state: Number(formValue.state),
					ids: selected,
				};
				if (formik.isSubmitting) setLoading(true);
				await dispatch(changeStatus(result));
				toast.success(`Se ha cambiado el estado de ${selected.length} clientes.`, {
					theme: 'colored',
				});
				setSelected([]);
				setSelectAll(false);
				handleCancel();
			} catch (error) {
				toast.error(`Error, intentelo de nuevo!`, { theme: 'colored' });
			}
		},
	});

	return (
		<Dialog
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
			maxWidth='xs'
			open={openChangeStatus}
		>
			<DialogTitle>Cambiar estado</DialogTitle>
			<form onSubmit={formik.handleSubmit}>
				<DialogContent dividers>
					<RadioGroup
						aria-label='Estado'
						name='state'
						value={formik.values.state}
						onChange={formik.handleChange}
					>
						{options.map(option => (
							<FormControlLabel
								value={option.value}
								key={option.value}
								control={<Radio />}
								label={option.label}
							/>
						))}
					</RadioGroup>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCancel}>
						Cancelar
					</Button>
					<LoadingButton variant='contained' type='submit' loading={loading}>
						Cambiar
					</LoadingButton>
				</DialogActions>
			</form>
		</Dialog>
	);
};
