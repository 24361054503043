import React, { useState } from 'react';
import {
	Stack,
	TableCell,
	TableRow,
	Typography,
	IconButton,
	Tooltip,
	TableBody,
} from '@mui/material';
import { avatarDesarrollo } from '../../assets/avatars';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { WarningDeleteDevelopment } from '../Dialogs';
import { Link } from 'react-router-dom';

export const ItemListDesarrollo = ({ e }) => {
	const [openWarningDelete, setOpenWarningDelete] = useState(false);

	return (
		<>
			<TableBody>
				<TableRow hover>
					<TableCell>
						<>
							{e.logo ? (
								<img src={`${e.logo}`} alt='Logo desarrollo' height={80} width={80} />
							) : (
								<img src={avatarDesarrollo} alt='Logo desarrollo' height={40} width={40} />
							)}
						</>
					</TableCell>
					<TableCell>
						<Stack alignItems='center' direction='row' spacing={2}>
							<Typography variant='subtitle2'>{e.name}</Typography>
						</Stack>
					</TableCell>
					<TableCell>
						{e.currency === 1
							? 'USD ' + e.value
							: e.currency === 2
							? '$ ' + e.value
							: '€ ' + e.value}
					</TableCell>
					<TableCell>{e.totLot}</TableCell>
					<TableCell>{e.availableLot.length}</TableCell>

					<TableCell sx={{ textAlign: 'end' }}>
						<Tooltip title='Detalles'>
							<Link to={`/desarrollo/${e.id}`}>
								<IconButton>
									<SearchIcon fontSize='small' color='primary' />
								</IconButton>
							</Link>
						</Tooltip>
						<Tooltip title='Editar'>
							<IconButton>
								<ModeEditIcon fontSize='small' color='success' />
							</IconButton>
						</Tooltip>
						<Tooltip title='Borrar'>
							<IconButton onClick={() => setOpenWarningDelete(true)}>
								<DeleteIcon fontSize='small' sx={{ color: 'red' }} />
							</IconButton>
						</Tooltip>
					</TableCell>
				</TableRow>
			</TableBody>
			<WarningDeleteDevelopment open={openWarningDelete} setOpen={setOpenWarningDelete} id={e.id} />
		</>
	);
};
