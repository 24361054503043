export const origins = [
	{
		value: undefined,
		label: 'Sin especificar',
	},
	{
		value: 'facebook',
		label: 'Facebook',
	},
	{
		value: 'instagram',
		label: 'Instagram',
	},
	{
		value: 'whatsapp',
		label: 'Whatsapp',
	},
	{
		value: 'web',
		label: 'Formulario Web',
	},
	{
		value: 'organic',
		label: 'Organico',
	},
];
