import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';

export const LocationDesarrolloMap = ({ setCoordinates }) => {
	const [mark, setMark] = useState([-34.921554830808255, -417.95460820198065]);

	useEffect(() => {
		setCoordinates(mark);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const MapEvents = () => {
		useMapEvents({
			click: e => {
				setMark(e.latlng);
				setCoordinates(e.latlng);
			},
		});
	};

	return (
		<div>
			<MapContainer
				center={[-34.921554830808255, -417.95460820198065]}
				zoom={13}
				style={{
					width: '99%',
					height: '500px',
					margin: '10px',
					borderRadius: '7px',
					userSelect: 'none',
				}}
			>
				<MapEvents />
				<TileLayer url='https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibGF1dGFyb2QiLCJhIjoiY2xndXdpdnMyMGsyMTNmbnVjdXdmNmJ5ayJ9.qGFpVJUPZM3EbLEeJitP4A' />

				<Marker position={mark}></Marker>
			</MapContainer>
		</div>
	);
};
