import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './DescriptionDraft.scss';

export const DescriptionDraft = ({ editorState, handleEditorChange }) => {
	return (
		<div>
			<Editor
				wrapperClassName='wrapper-class'
				editorClassName='editor-class'
				toolbarClassName='toolbar-class'
				editorState={editorState}
				onEditorStateChange={handleEditorChange}
				placeholder='Descripción...'
			/>
		</div>
	);
};
