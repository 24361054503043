import React, { useState } from 'react';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import WestIcon from '@mui/icons-material/West';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import { Box, Container, Stack, SvgIcon, Button } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../hooks/useAuth';
import { DesarrollosList, CreateDesarrollo } from '../components/ConfigDesarrollos';

import { useSelector } from 'react-redux';

export const ConfigDesarrollos = () => {
	const { user } = useAuth();

	const loading = useSelector(state => state.developmentsConfig.loading);
	const developments = useSelector(state => state.developmentsConfig.developments);

	const [create, setCreate] = useState(true);

	if (user.roleId === 3) return;
	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='xl'>
					<Stack spacing={3}>
						<Stack direction='row' justifyContent='space-between' spacing={4}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
								}}
							>
								<Button
									startIcon={
										<SvgIcon fontSize='small'>
											<WestIcon />
										</SvgIcon>
									}
									variant='contained'
									onClick={() => window.history.back()}
								>
									Volver atrás
								</Button>
								<span>
									{create ? (
										<Button
											startIcon={
												<SvgIcon fontSize='small'>
													<PlusIcon />
												</SvgIcon>
											}
											variant='contained'
											onClick={() => setCreate(!create)}
										>
											Crear desarrollo
										</Button>
									) : (
										<Button
											startIcon={
												<SvgIcon fontSize='small'>
													<CalendarViewMonthIcon />
												</SvgIcon>
											}
											variant='contained'
											onClick={() => setCreate(!create)}
										>
											Lista de desarrollos
										</Button>
									)}
								</span>
							</div>
						</Stack>
						{create ? (
							<DesarrollosList items={developments} loading={loading} />
						) : (
							<CreateDesarrollo goBack={setCreate} />
						)}
					</Stack>
				</Container>
			</Box>
		</>
	);
};
