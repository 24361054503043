import ArrowDownIcon from '@heroicons/react/24/solid/ArrowDownIcon';
import ArrowUpIcon from '@heroicons/react/24/solid/ArrowUpIcon';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';

export const OverviewTotalClients = ({ sx, newClients }) => {
	return (
		<Card sx={sx}>
			<CardContent>
				<Stack alignItems='flex-start' direction='row' justifyContent='space-between' spacing={3}>
					<Stack spacing={1}>
						<Typography color='text.secondary' variant='overline'>
							Contactos nuevos
						</Typography>
						<Typography variant='h4'>{newClients.thisMonth}</Typography>
					</Stack>
					<Avatar
						sx={{
							backgroundColor: 'success.main',
							height: 56,
							width: 56,
						}}
					>
						<SvgIcon>
							<UsersIcon />
						</SvgIcon>
					</Avatar>
				</Stack>
				{newClients && (
					<Stack alignItems='center' direction='row' spacing={2} sx={{ mt: 2 }}>
						<Stack alignItems='center' direction='row' spacing={0.5}>
							<SvgIcon color={Boolean(newClients.positive) ? 'success' : 'error'} fontSize='small'>
								{Boolean(newClients.positive) ? <ArrowUpIcon /> : <ArrowDownIcon />}
							</SvgIcon>
							<Typography
								color={Boolean(newClients.positive) ? 'success.main' : 'error.main'}
								variant='body2'
							>
								{newClients.newClients}%
							</Typography>
						</Stack>
						<Typography color='text.secondary' variant='caption'>
							Desde el último mes
						</Typography>
					</Stack>
				)}
			</CardContent>
		</Card>
	);
};
