import { useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TransferList from './TransferList';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
	})
);

const AccordionSummary = styled(props => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
		justifyContent: 'space-between',
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const AccordionDevelopment = ({
	expanded,
	name,
	availableLot,
	handleChange,
	handleClose,
	userId,
	developmentId,
	dataClient,
}) => {
	const [open, setOpen] = useState(false);
	if (availableLot.length === 0) return;

	return (
		<Accordion expanded={expanded === name} onChange={handleChange(name)}>
			<AccordionSummary
				aria-controls='panel1d-content'
				id='panel1d-header'
				onClick={() => setOpen(true)}
			>
				<Typography>{name}</Typography>
				<Typography variant='caption'>Disponibles: {availableLot.length}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{open ? (
					<TransferList
						dataClient={dataClient}
						handleClose={handleClose}
						availableLot={availableLot}
						userId={userId}
						developmentId={developmentId}
					/>
				) : (
					<></>
				)}
			</AccordionDetails>
		</Accordion>
	);
};
