import { useState, useEffect } from 'react';

export const useDaily = () => {
	const [daily, setDaily] = useState(false);

	const fechaActual = new Date();

	const fechaHoy = `${fechaActual.getFullYear()}-${(fechaActual.getMonth() + 1)
		.toString()
		.padStart(2, '0')}-${fechaActual.getDate().toString().padStart(2, '0')}`;

	const keyLocalStorage = `daily_${fechaHoy}`;

	useEffect(() => {
		if (!localStorage.getItem(keyLocalStorage)) {
			setDaily(true);
		}
	}, [keyLocalStorage]);

	const handleClose = () => {
		setDaily(false);
		localStorage.setItem(keyLocalStorage, 'true');
	};

	return [daily, handleClose];
};
