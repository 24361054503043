import { useState, useEffect } from 'react';
import {
	Dialog,
	DialogContent,
	IconButton,
	DialogTitle,
	Box,
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableFooter,
	Paper,
	CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Scrollbar } from '../Scrollbar';
import { deleteBooking, updateBookingState } from '../../redux/actions/user/home';
import UpdateStateReserva from '../UpdateStateReserva';

export const ModalUpdateReserva = ({ open, setOpen, reservas }) => {
	const [reservasVencidas, setReservasVencidas] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const handleClose = () => {
		if (reservasVencidas.length !== 0) return;
		setOpen(false);
	};

	useEffect(() => {
		setReservasVencidas(reservas);
		setIsLoading(false);
	}, [reservas]);

	const updateState = (e, selled) => {
		const result = reservasVencidas.filter(a => a.id !== e.id);
		setReservasVencidas(result);
		updateBookingState(e, selled);
	};

	const cancelBooking = e => {
		const result = reservasVencidas.filter(a => a.id !== e.id);
		setReservasVencidas(result);
		deleteBooking(e);
	};

	return (
		<Scrollbar>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth={true}
				maxWidth={'md'}
				sx={{ '& .MuiDialog-paper': { backgroundColor: '#F3F3F3' } }}
			>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<IconButton aria-label='Cerrar' onClick={handleClose}>
						<CloseIcon color='primary' />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box sx={{ mb: 5 }}>
						<Box>
							<Scrollbar>
								{isLoading ? (
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<CircularProgress />
									</div>
								) : (
									<TableContainer component={Paper}>
										<Table stickyHeader>
											{reservasVencidas.length === 0 ? (
												<caption>Sin reservas vencidas</caption>
											) : (
												<caption></caption>
											)}

											<TableHead>
												<TableRow>
													<TableCell align='center' colSpan={5}>
														Reservas vencidas
													</TableCell>
												</TableRow>

												<TableRow>
													<TableCell align='center' width={170}>
														Nombre y apellido
													</TableCell>
													<TableCell align='center'>Desarrollo</TableCell>
													<TableCell align='center'>Número de lote</TableCell>
													<TableCell align='center'>Actualizar estado</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{reservasVencidas.map(e => {
													return (
														<TableRow hover key={e.id}>
															<TableCell align='left'>
																<Typography variant='subtitle2'>
																	{e['Contact.name']}{' '}
																	{e['Contact.lastName'] ? e['Contact.lastName'] : null}
																</Typography>
															</TableCell>

															<TableCell align='center'>
																{e['LotDetail.Development.name']}
															</TableCell>

															<TableCell align='center'>{e['LotDetail.numberLot']}</TableCell>

															<TableCell align='center'>
																<UpdateStateReserva
																	element={e}
																	cancelBooking={cancelBooking}
																	updateState={updateState}
																/>
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
											<TableFooter></TableFooter>
										</Table>
									</TableContainer>
								)}
							</Scrollbar>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</Scrollbar>
	);
};
