import React, { useState, useEffect } from 'react';
import {
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@mui/material';
// import CachedIcon from '@mui/icons-material/Cached';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux';
import {
	changeAdvisor,
	// getNextAdvisor,
	// changeNextAdvisor,
} from '../../redux/actions/admin/clients';

export const ChangeAdvisor = ({
	openChangeAdvisor,
	setOpenChangeAdvisor,
	selected,
	advisors,
	setSelected,
	setSelectAll,
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	// const nextAdvisor = useSelector(state => state.allClients.nextAdvisor);

	// useEffect(() => {
	// 	dispatch(getNextAdvisor());
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// const handleChangeNextAdvisor = () => {
	// 	try {
	// 		// const data = { clients: selected, nextAdvisor: nextAdvisor.id };
	// 		// dispatch(changeNextAdvisor(data));
	// 		toast.success(`Se han cambiado ${selected.length} clientes de asesor.`, {
	// 			theme: 'colored',
	// 		});
	// 		setSelected([]);
	// 		setSelectAll(false);
	// 		handleCancel();
	// 	} catch (error) {
	// 		toast.error(`Error, intentelo de nuevo!`, { theme: 'colored' });
	// 		console.error(error);
	// 	}
	// };

	const handleCancel = () => {
		setLoading(false);
		setOpenChangeAdvisor(false);
	};

	const formik = useFormik({
		initialValues: { idAdvisor: 1 },
		validationSchema: false,
		validateOnChange: false,
		onSubmit: async formValue => {
			try {
				let result = {
					idAdvisor: Number(formValue.idAdvisor),
					ids: selected,
				};
				if (formik.isSubmitting) setLoading(true);
				await dispatch(changeAdvisor(result));
				toast.success(`Se han cambiado ${selected.length} clientes de asesor.`, {
					theme: 'colored',
				});
				setSelected([]);
				setSelectAll(false);
				setLoading(false);
				handleCancel();
			} catch (error) {
				toast.error(`Error, intentelo de nuevo!`, { theme: 'colored' });
			}
		},
	});

	return (
		<Dialog
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '550px' } }}
			maxWidth='xs'
			open={openChangeAdvisor}
		>
			<DialogTitle sx={{ paddingTop: '5px', paddingBottom: '5px' }}>Cambiar asesor</DialogTitle>
			<div style={{ display: 'flex', alignItems: 'baseline' }}>
				{/* <DialogContent
					sx={{
						fontSize: '12px',
						paddingTop: '5px',
						marginBottom: '5px',
						overflow: 'hidden',
					}}
				>
					Siguiente asesor: {nextAdvisor ? nextAdvisor.fullName : 'ERROR'}
					<Button
						startIcon={<CachedIcon />}
						variant='text'
						size='small'
						sx={{ padding: '1px', marginLeft: '10px', fontSize: '12px' }}
						onClick={() => handleChangeNextAdvisor()}
					>
						Asignar
					</Button>
				</DialogContent> */}
			</div>
			<form onSubmit={formik.handleSubmit}>
				<DialogContent dividers>
					<RadioGroup
						aria-label='Asesor'
						name='idAdvisor'
						value={formik.values.idAdvisor}
						onChange={formik.handleChange}
					>
						{advisors.map(advisor => (
							<FormControlLabel
								value={advisor.id}
								key={advisor.id}
								control={<Radio />}
								label={advisor.fullName}
							/>
						))}
					</RadioGroup>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCancel}>
						Cancelar
					</Button>
					<LoadingButton variant='contained' type='submit' loading={loading}>
						Cambiar
					</LoadingButton>
				</DialogActions>
			</form>
		</Dialog>
	);
};
