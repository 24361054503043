import React from 'react';
import { Box, Typography } from '@mui/material';

export const SelectZones = ({ zones, selected, setSelected }) => {
	const handleSelected = e => {
		if (selected.includes('Todos')) return setSelected([]);
		if (selected.includes(e)) setSelected(selected.filter(x => x !== e));
		else setSelected([...selected, e]);
		return;
	};

	const handleSelectAll = e => {
		if (selected.includes(e)) setSelected([]);
		else setSelected([e]);
		return;
	};

	return (
		<div>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					gap: '10px',
					width: '100%',
				}}
			>
				<Typography
					onClick={() => handleSelectAll('Todos')}
					variant='body2'
					sx={{
						width: 'auto',
						height: '40px',
						padding: '18px',
						backgroundColor: selected.includes('Todos') ? '#FFF' : '#CDCDCD',
						filter: selected.includes('Todos')
							? 'drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.05))'
							: null,
						borderRadius: '8px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
						color: selected.includes('Todos') ? null : '#7E7E7E',
						userSelect: 'none',
					}}
				>
					Todos
				</Typography>
				{zones.map(e => (
					<Typography
						onClick={() => handleSelected(e.id)}
						key={e.id}
						variant='body2'
						sx={{
							width: 'auto',
							height: '40px',
							padding: '18px',
							backgroundColor: selected.includes(e.id) ? '#FFF' : '#CDCDCD',
							filter: selected.includes(e.id)
								? 'drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.05))'
								: null,
							borderRadius: '8px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							cursor: 'pointer',
							userSelect: 'none',
							color: selected.includes(e.id) ? null : '#7E7E7E',
						}}
					>
						{e.name ? e.name : e.fullName ? e.fullName : e.description ? e.description : 'Error'}
					</Typography>
				))}
			</Box>
		</div>
	);
};
