export function formatNumberWithDots(number) {
	const numberString = number.toString();
	const parts = numberString.split('.');
	const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	const formattedNumber = parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
	return formattedNumber;
}

export function formatNumber(numero) {
	const num = Number(numero);

	if (isNaN(num)) return 0;
	if (numero === 0) return 0;
	if (numero === undefined) return 0;

	const suffixes = ['', 'k', 'M', 'B', 'T'];
	const magnitude = Math.floor(Math.log10(num) / 3);

	const scaledNum = num / Math.pow(10, magnitude * 3);
	const formattedNum = scaledNum.toFixed(0);

	return formattedNum + suffixes[magnitude];
}
