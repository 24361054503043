import { Avatar, Box, Card, CardContent, Divider, Typography } from '@mui/material';

export const UserProfile = ({ user }) => {
	return (
		<Card>
			<CardContent>
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Avatar
						sx={{
							height: 80,
							mb: 2,
							width: 80,
						}}
					/>
					<Typography gutterBottom variant='h5'>
						{user.fullName}
					</Typography>
					<Typography color='text.secondary' variant='body2'>
						{user.email}
					</Typography>
				</Box>
			</CardContent>
			<Divider />
		</Card>
	);
};
