import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import { Box, Button, Container, SvgIcon, Typography } from '@mui/material';

export const NoMatch = () => (
	<>
		<Box
			component='main'
			sx={{
				alignItems: 'center',
				display: 'flex',
				flexGrow: 1,
				minHeight: '100vh',
			}}
		>
			<Container maxWidth='md'>
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Box
						sx={{
							mb: 3,
							textAlign: 'center',
						}}
					></Box>
					<Typography align='center' sx={{ mb: 3 }} variant='h3'>
						404: Página en desarrollo.
					</Typography>
					<Typography align='center' color='text.secondary' variant='body1'>
						Proximamente...
					</Typography>
					<Button
						startIcon={
							<SvgIcon fontSize='small'>
								<ArrowLeftIcon />
							</SvgIcon>
						}
						sx={{ mt: 3 }}
						variant='contained'
						onClick={() => window.history.back()}
					>
						Volver atrás
					</Button>
				</Box>
			</Container>
		</Box>
	</>
);
