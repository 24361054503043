import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

// Acciones para usuarios (no clientes)
export const CREATE_USER = 'CREATE_USER';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_DATA_USER = 'GET_DATA_USER';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_LOADER = 'CHANGE_LOADER';
export const UPDATE_USER = 'UPDATE_USER';

// Acciones especificas para asesores
export const GET_ALL_ADVISORS = 'GET_ALL_ADVISORS';
export const UPDATE_ADVISOR = 'UPDATE_ADVISOR';

export const getAllUsers = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER });
		const response = await authAxios(`${URL_API}/admin/users`, 'get');
		return dispatch({
			type: GET_ALL_USERS,
			payload: response,
		});
	};
};

export const createUser = data => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER });
		await authAxios(`${URL_API}/admin/users`, 'post', null, data);
		const response = await authAxios(`${URL_API}/admin/users`, 'get');
		return dispatch({
			type: GET_ALL_USERS,
			payload: response,
		});
	};
};

export const getDataUser = id => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER });
		const response = await authAxios(`${URL_API}/admin/user/${id}`, 'get');
		return dispatch({
			type: GET_DATA_USER,
			payload: response,
		});
	};
};

export const updateUser = (id, data) => {
	return async function (dispatch) {
		await authAxios(`${URL_API}/admin/user/${id}`, 'put', null, data);
		return dispatch({ type: UPDATE_USER });
	};
};

export const deleteUser = id => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER });
		await authAxios(`${URL_API}/admin/user/${id}`, 'delete');
		dispatch({ type: DELETE_USER });
		const response = await authAxios(`${URL_API}/admin/users`, 'get');
		return dispatch({
			type: GET_ALL_USERS,
			payload: response,
		});
	};
};
