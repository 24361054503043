import {
	GET_ALL_DEVELOPMENTS_USER,
	CHANGE_LOADER_USER_DEVELOPMENTS,
	CHANGE_LOADER_DEVELOPMENT_PROFILE,
	GET_DEVELOPMENT,
	GET_ALL_LOTS_DEVELOPMENT,
} from '../../actions/user/developments';

const initialState = {
	developments: [],
	loading: false,
	loadingPerfil: false,
	developmentPerfil: [],
	lots: [],
};

export const developmentsUser = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_DEVELOPMENTS_USER:
			return {
				...state,
				developments: payload,
				// developments: Object.keys(payload).length === 0 ? [] : payload,
				loading: false,
			};
		case CHANGE_LOADER_USER_DEVELOPMENTS:
			return {
				...state,
				loading: true,
			};
		case CHANGE_LOADER_DEVELOPMENT_PROFILE:
			return {
				...state,
				loadingPerfil: true,
			};
		case GET_DEVELOPMENT:
			return {
				...state,
				developmentPerfil: payload,
				loadingPerfil: false,
			};
		case GET_ALL_LOTS_DEVELOPMENT:
			let res = payload.sort((a, b) => a.numberLot - b.numberLot);
			return {
				...state,
				lots: res,
			};

		default:
			return state;
	}
};
