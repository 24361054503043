import React, { useState } from 'react';
import {
	Card,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableFooter,
	TablePagination,
	TableSortLabel,
	Typography,
	CircularProgress,
	useMediaQuery,
} from '@mui/material';
import { Scrollbar } from '../Scrollbar/Scrollbar';
import {
	changeOrderUpdated,
	changeOrderCreatedAt,
	changeRowsPerPage,
	changePage,
} from '../../redux/actions/user/oportunidades';
import { useDispatch, useSelector } from 'react-redux';
import { ClientItem } from './ClientItem';

export const ListClients = ({ items = [] }) => {
	const dispatch = useDispatch();
	const smallScreen = useMediaQuery('(max-width:1230px)');

	const [createdAt, setCreatedAt] = useState(false);
	const [updatedAt, setUpdatedAt] = useState(false);

	const order = useSelector(state => state.clientsAdvisor.order);
	const rowsPerPage = useSelector(state => state.clientsAdvisor.rowsPerPage);
	const page = useSelector(state => state.clientsAdvisor.page);
	const countClients = useSelector(state => state.clientsAdvisor.countClients);
	const isLoading = useSelector(state => state.clientsAdvisor.loading);

	// const handleOrder = () => {
	// 	setSort(!sort);
	// 	let newOrder = order === 'desc' ? 'asc' : 'desc';
	// 	dispatch(changeOrder(newOrder));
	// };

	const handleOrderUpdatedAt = () => {
		setUpdatedAt(!updatedAt);
		setCreatedAt(false);
		let newOrder = order === 'desc' ? 'asc' : 'desc';
		dispatch(changeOrderUpdated(newOrder));
	};

	const handleOrderCreatedAt = () => {
		setCreatedAt(!createdAt);
		setUpdatedAt(false);
		let newOrder = order === 'desc' ? 'asc' : 'desc';
		dispatch(changeOrderCreatedAt(newOrder));
	};

	const handleChangeRowsPerPage = e => {
		dispatch(changeRowsPerPage(e));
	};

	const handleChangePage = (event, newPage) => {
		dispatch(changePage(newPage));
		window.scrollTo({ behavior: 'smooth', top: 'top' });
	};

	return (
		<>
			{isLoading ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
					<CircularProgress />
				</div>
			) : items.length === 0 ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
					<Typography variant='body1'>
						No se han encontrado resultados según los parametros de búsqueda.
					</Typography>
				</div>
			) : (
				<Card>
					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell align='left'></TableCell>
										<TableCell align='left'>Nombre y apellido</TableCell>
										<TableCell align='left'>Telefono</TableCell>
										<TableCell align='left'>Último registro</TableCell>
										<TableCell align='right' width={220}>
											<TableSortLabel
												active={updatedAt}
												direction={order}
												onClick={handleOrderUpdatedAt}
											>
												Última actualización
											</TableSortLabel>
										</TableCell>
										{!smallScreen ? (
											<TableCell align='right' width={190}>
												<TableSortLabel
													active={createdAt}
													direction={order}
													onClick={handleOrderCreatedAt}
												>
													Fecha de registro
												</TableSortLabel>
											</TableCell>
										) : (
											<></>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{items.map(e => {
										if (e.Contact === null) return null;
										else return <ClientItem e={e} key={e.id} />;
									})}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TablePagination
											page={page}
											count={countClients}
											rowsPerPage={rowsPerPage}
											onPageChange={handleChangePage}
											rowsPerPageOptions={[25, 50, 100]}
											labelRowsPerPage='Mostrar por pagina: '
											onRowsPerPageChange={e => handleChangeRowsPerPage(e.target.value)}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</Scrollbar>
				</Card>
			)}
		</>
	);
};
