import {
	GET_CLIENT_DATA,
	CHANGE_LOADER,
	GET_HISTORY_CLIENT,
	GET_LOTS_CLIENT,
} from '../actions/client';

const initialState = {
	loading: false,
	data: [],
	history: [],
	lots: [],
};

export const clientDetail = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_CLIENT_DATA:
			return {
				...state,
				data: payload,
				loading: false,
			};
		case CHANGE_LOADER:
			return {
				...state,
				loading: true,
			};
		case GET_HISTORY_CLIENT:
			return {
				...state,
				history: payload,
			};
		case GET_LOTS_CLIENT:
			return {
				...state,
				lots: payload,
			};
		default:
			return state;
	}
};
