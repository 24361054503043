import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_DATA_ORIGINS = 'GET_DATA_ORIGINS';
export const CHANGE_LOADER_ORIGINS = 'CHANGE_LOADER_ORIGINS';
export const GET_CLIENTS_REACHED = 'GET_CLIENTS_REACHED';
export const GET_MONTH_TARGET = 'GET_MONTH_TARGET';
export const GET_NEW_CLIENTS = 'GET_NEW_CLIENTS';
export const GET_CHART_MONTHS = 'GET_CHART_MONTHS';
export const GET_RESERVAS_VENCIDAS = 'GET_RESERVAS_VENCIDAS';

export const getOriginData = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_ORIGINS });
		const response = await authAxios(`${URL_API}/client/origin/data/social/networks`, 'get');
		return dispatch({
			type: GET_DATA_ORIGINS,
			payload: response,
		});
	};
};

export const getClientsReached = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/clients/reached`, 'get');
		return dispatch({
			type: GET_CLIENTS_REACHED,
			payload: response,
		});
	};
};

export const getMonthTarget = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/target/month`, 'get');
		return dispatch({
			type: GET_MONTH_TARGET,
			payload: response,
		});
	};
};

export const getNewClients = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/new/clients`, 'get');
		return dispatch({
			type: GET_NEW_CLIENTS,
			payload: response,
		});
	};
};

export const getChartMonth = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/chart/years`, 'get');
		return dispatch({
			type: GET_CHART_MONTHS,
			payload: response,
		});
	};
};

export const getReservasVencimiento = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/reservas/vencidas`, 'get');
		return dispatch({
			type: GET_RESERVAS_VENCIDAS,
			payload: response,
		});
	};
};

export const deleteBooking = async e => {
	await authAxios(
		`${URL_API}/client/reservas/vencidas/${e.id}/${e.LotDetailId}/${e['LotDetail.Development.id']}/${e['LotDetail.numberLot']}`,
		'delete'
	);
};

export const updateBookingState = async (e, selled) => {
	const data = { ...e, selled };
	await authAxios(`${URL_API}/client/reservas/vencidas/${e.id}`, 'put', null, data);
};
