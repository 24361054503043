import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Box, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import { validateUser } from '../redux/actions/login';

export const Login = () => {
	const navigate = useNavigate();
	const [method, setMethod] = useState('email');
	const [loading, setLoading] = useState(false);

	const { login, user } = useAuth();

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			submit: null,
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email('Porfavor introduzca un email válido')
				.max(255)
				.required('Porfavor introduzca un email válido.'),
			password: Yup.string().max(255).required('Porfavor introduzca una contraseña válida.'),
		}),
		onSubmit: async (values, helpers) => {
			try {
				setLoading(true);
				let response = await validateUser(values.email, values.password);
				await login(response);
				setLoading(false);
				navigate('/');
			} catch (err) {
				setLoading(false);
				helpers.setStatus({ success: false });
				helpers.setErrors({ submit: 'Contraseña o email incorrecto.' });
				helpers.setSubmitting(false);
			}
		},
	});

	const handleMethodChange = useCallback((event, value) => {
		setMethod(value);
	}, []);

	if (user) {
		navigate('/');
		return null;
	}

	return (
		<Box
			sx={{
				backgroundColor: 'background.paper',
				flex: '1 1 auto',
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<Box
				sx={{
					maxWidth: 550,
					px: 3,
					py: '100px',
					width: '100%',
				}}
			>
				<div>
					<Stack spacing={1} sx={{ mb: 3 }}>
						<Typography variant='h4'>Ingreso</Typography>
					</Stack>
					<Tabs onChange={handleMethodChange} sx={{ mb: 3 }} value={method}>
						<Tab label='Email' value='email' />
					</Tabs>
					{method === 'email' && (
						<form noValidate onSubmit={formik.handleSubmit}>
							<Stack spacing={3}>
								<TextField
									error={!!(formik.touched.email && formik.errors.email)}
									fullWidth
									helperText={formik.touched.email && formik.errors.email}
									label='Dirección email'
									name='email'
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									type='email'
									value={formik.values.email}
								/>
								<TextField
									error={!!(formik.touched.password && formik.errors.password)}
									fullWidth
									helperText={formik.touched.password && formik.errors.password}
									label='Contraseña'
									name='password'
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									type='password'
									value={formik.values.password}
								/>
							</Stack>
							{formik.errors.submit && (
								<Typography color='error' sx={{ mt: 3 }} variant='body2'>
									{formik.errors.submit}
								</Typography>
							)}
							<LoadingButton
								loading={loading}
								fullWidth
								size='large'
								sx={{ mt: 3 }}
								type='submit'
								variant='contained'
							>
								Ingresar
							</LoadingButton>
						</form>
					)}
				</div>
			</Box>
		</Box>
	);
};
